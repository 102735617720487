export const ModalAppAd = 'ModalAppAd'
export const ModalAppOutdated = 'ModalAppOutdated'
export const ModalCommunityCreationRateLimit = 'ModalCommunityCreationRateLimit'
export const ModalConnectedPostDeletion = 'ModalConnectedPostDeletion'
export const ModalDebugIap = 'ModalDebugIap'
export const ModalExplorePlusBenefits = 'ModalExplorePlusBenefits'
export const ModalFeatureAnnouncementTelloTokensMeetVisibilityBoosts = 'ModalFeatureAnnouncementTelloTokensMeetVisibilityBoosts' // prettier-ignore
export const ModalFeatureAnnouncementTellSharing = 'ModalFeatureAnnouncementTellSharing' // prettier-ignore
export const ModalHapticFeedbackTesting = 'ModalHapticFeedbackTesting'
export const ModalFeatureAnnouncementTellsPreferences = 'ModalFeatureAnnouncementTellsPreferences' // prettier-ignore
export const ModalMainLanguageSelection = 'ModalMainLanguageSelection'
export const ModalMultishare = 'ModalMultishare'
export const ModalMultisharePrompt = 'ModalMultisharePrompt'
export const ModalPurchasePremium = 'ModalPurchasePremium'
export const ModalPurchaseSuccess = 'ModalPurchaseSuccess'
export const ModalSenderHintNotAvailable = 'ModalSenderHintNotAvailable'
export const ModalSendTellsToFriend = 'ModalSendTellsToFriend'
export const ModalSentTell = 'ModalSentTell'
export const ModalSharePost = 'ModalSharePost'
export const ModalShareProfilePopup = 'ModalShareProfilePopupV2'
export const ModalStatusEmoji = 'ModalStatusEmoji'
export const ModalSubscriptionWillEnd = 'ModalSubscriptionWillEnd'
export const ModalTellPromptPreview = 'ModalTellPromptPreview'
export const ModalTellPromptReferralTag = 'ModalTellPromptReferralTag'
export const ModalTimings = 'ModalTimings'
export const ModalUnlockHint = 'ModalUnlockHint'
export const ModalUpsellPremium = 'ModalUpsellPremium'
export const ModalVisibilityBoost = 'ModalVisibilityBoost'
export const ModalVisibilityBoostLocationPermission = 'ModalVisibilityBoostLocationPermission' // prettier-ignore
export const ModalVisibilityBoostPacks = 'ModalVisibilityBoostPacks'
export const ModalVisibilityBoostResults = 'ModalVisibilityBoostResults'
export const ModalWalletBalance = 'ModalWalletBalance'
export const ModalWhoSentThis = 'ModalWhoSentThis'
