import React from 'react'
import { theme } from '../styles/theme'
import { Text } from './Text'

export const Badge = ({
  children,
  color = theme.colors.text,
  style,
  ...props
}) =>
  children ? (
    <Text
      bold
      center
      type="h1"
      color={color}
      {...props}
      style={[
        {
          backgroundColor: theme.colors.white,
          borderRadius: 25,
          paddingHorizontal: 24,
        },
        style,
      ]}>
      {children}
    </Text>
  ) : null
