import * as t from './types'

export const refreshPermissions = () => ({
  type: t.REFRESH_PERMISSIONS,
  meta: {
    offline: {
      effect: { path: 'admin/permissions' },
      commit: { type: t.REFRESH_PERMISSIONS_SUCCESS },
      rollback: { type: t.REFRESH_PERMISSIONS_ERROR },
    },
  },
})

export const toggleSidemenu = (payload) => ({
  type: t.TOGGLE_SIDEMENU,
  payload,
})

export const setCustomApiHost = (payload) => ({
  type: t.SET_CUSTOM_API_HOST,
  payload,
})

export const setIsDarkModeEnabled = (payload) => ({
  type: t.SET_IS_DARK_MODE_ENABLED,
  payload,
})
