import {
  groupModes,
  headerModes,
} from '@tellonym/core/artificialTells/constants'
import { Breadcrumb, Grid, Segmented, Tooltip, Typography } from 'antd'
import moment from 'dayjs'
import React from 'react'
import * as ReactRedux from 'react-redux'
import { getPermissions } from '../../app/selectors'
import { _, helpers } from '../../common'
import { Box, Col, Row } from '../../common/components/Box'
import { history } from '../../common/history'
import { setGroupMode, setHeaderMode } from '../actionsV2'
import { getGroupMode, getHeaderMode } from '../selectorsV2'
import { ArtificialTellGroupTypeTag } from './ArtificialTellGroupTypeTag'
import { ArtificialTellStatusTag } from './ArtificialTellStatusTag'
import { ButtonBack } from './ButtonBack'
import { SelectorsLanguage } from './SelectorsLanguage'

export const Breadcrumbs = ({ group, topic, language, subitems }) => {
  return (
    <Box flexDirection="row" transparent alignItems="center">
      <ButtonBack
        onPress={() => {
          history.goBack()
        }}
        style={{ marginRight: 12, width: 30, height: 30 }}
      />

      <Breadcrumb>
        <Breadcrumb.Item key="Topics">
          <a
            href={`/artificialtells_v2/${language}/topics/list`}
            onClick={(e) => {
              e.stopPropagation()
              e.preventDefault()

              _.openRoute(`/artificialtells_v2/${language}/topics/list`, e)
            }}>
            Topics
          </a>
        </Breadcrumb.Item>

        {topic ? (
          <Breadcrumb.Item key="TopicId">
            {subitems?.length ? (
              // This means the topic item is not the last so we make it clickable
              <a
                href={`/artificialtells_v2/${language}/topic/${topic.id}`}
                onClick={(e) => {
                  e.stopPropagation()
                  e.preventDefault()

                  _.openRoute(
                    `/artificialtells_v2/${language}/topic/${topic.id}`,
                    e
                  )
                }}>
                {topic.name}
              </a>
            ) : (
              topic.name
            )}
          </Breadcrumb.Item>
        ) : (
          <>
            <Breadcrumb.Item key="TopicId">
              <a
                href={`/artificialtells_v2/${language}/topic/${group.topic?.id}`}
                onClick={(e) => {
                  e.stopPropagation()
                  e.preventDefault()

                  _.openRoute(
                    `/artificialtells_v2/${language}/topic/${group.topic?.id}`,
                    e
                  )
                }}>
                {group.topic?.name}
              </a>
            </Breadcrumb.Item>
            <Breadcrumb.Item
              key="GroupId"
              className={`${group.name}_${language}`}>
              {group.id}
            </Breadcrumb.Item>
          </>
        )}

        {subitems?.map((item) => (
          <Breadcrumb.Item key={item}>{item}</Breadcrumb.Item>
        ))}
      </Breadcrumb>
    </Box>
  )
}

const modesLabelMap = {
  [headerModes.CONTENT]: 'Content',
  [headerModes.EDIT]: 'Edit',
  [headerModes.STATS]: 'Stats',
  [headerModes.TRANSLATE]: 'Translate',
}

/**
 * either topic or group is defined
 */
export const BreadcrumbHeader = ({ modes, group, topic, language }) => {
  const dispatch = ReactRedux.useDispatch()

  const permissions = ReactRedux.useSelector(getPermissions)
  const isTranslator = helpers.checkIsTranslator(permissions)

  const screens = Grid.useBreakpoint()

  const groupMode = ReactRedux.useSelector(getGroupMode)
  const headerMode = ReactRedux.useSelector(getHeaderMode)

  React.useEffect(() => {
    if (isTranslator && !permissions.hasTranslatorAccess) {
      dispatch(setHeaderMode(headerModes.TRANSLATE))
    }
  }, [isTranslator, permissions.hasTranslatorAccess, dispatch])

  const onChangeHeaderMode = (value) => {
    if (
      [headerModes.EDIT, headerModes.STATS, headerModes.TRANSLATE].includes(
        value
      )
    ) {
      dispatch(setGroupMode(value))
    }

    dispatch(setHeaderMode(value))
  }

  const onChangeGroupMode = (value) => {
    dispatch(setGroupMode(value))
  }

  return (
    <Row gutter={[12, 12]}>
      <Col xs={24} xl={9}>
        <Box flexDirection="row" transparent alignItems="center">
          <Breadcrumbs group={group} topic={topic} language={language} />

          <Box marginLeft={12} transparent>
            <Tooltip
              title={
                topic
                  ? undefined
                  : [
                      `Active ${group.activeVarianceTotals?.total ?? 0}`,
                      `Male ${group.activeVarianceTotals?.male ?? 0}`,
                      `Female ${group.activeVarianceTotals?.female ?? 0}`,
                      `Both ${group.activeVarianceTotals?.both ?? 0}`,
                    ].join(' - ')
              }>
              <ArtificialTellStatusTag
                status={group?.status || topic?.status}
              />
            </Tooltip>
          </Box>

          {typeof group?.type !== 'undefined' && (
            <Box marginLeft={12} transparent>
              <ArtificialTellGroupTypeTag groupType={group.type} />
            </Box>
          )}
        </Box>
      </Col>

      <Col xs={24} md={8} xl={6}>
        <Box
          transparent
          alignItems={screens.md ? 'flex-end' : 'center'}
          flexDirection="row"
          gap={12}>
          <Segmented
            value={Boolean(topic) ? headerMode : groupMode}
            disabled={isTranslator}
            options={
              modes
                ? modes.map((mode) => ({
                    label: modesLabelMap[mode],
                    value: mode,
                  }))
                : [
                    { label: 'Edit', value: headerModes.EDIT },
                    { label: 'Stats', value: headerModes.STATS },
                    {
                      label: 'Translate',
                      value: headerModes.TRANSLATE,
                    },
                  ]
            }
            onChange={onChangeHeaderMode}
            size="small"
          />

          {Boolean(topic) && headerMode === headerModes.CONTENT && (
            <Box transparent flexDirection="row" gap={4}>
              <Typography.Text type="secondary">Group mode:</Typography.Text>
              <Segmented
                value={groupMode}
                disabled={isTranslator}
                options={[
                  { label: 'Edit', value: groupModes.EDIT },
                  { label: 'Stats', value: groupModes.STATS },
                ]}
                onChange={onChangeGroupMode}
                size="small"
              />
            </Box>
          )}
        </Box>
      </Col>

      <Col xs={24} md={16} xl={9}>
        <Box transparent alignItems={screens.md ? 'flex-end' : 'center'}>
          <SelectorsLanguage
            languageStatusList={group?.languages ?? topic?.languages}
          />

          {group?.lastUpdatedAt || topic?.lastUpdatedAt ? (
            <Box transparent alignItems="flex-end">
              <Typography.Text type="secondary">{`last edited ${moment(
                group?.lastUpdatedAt ?? topic?.lastUpdatedAt
              ).format('DD.MM HH:mm[h]')}`}</Typography.Text>
            </Box>
          ) : null}
        </Box>
      </Col>
    </Row>
  )
}
