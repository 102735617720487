import { grey } from '@ant-design/colors'
import { CheckOutlined } from '@ant-design/icons'
import { colors } from '@tellonym/core/common/colorSystem'
import { TODO_ENTITY_TYPE, TODO_STATUS } from '@tellonym/enums/lib/Tell'
import { Button, Card, Empty, List, Segmented, Select, Typography } from 'antd'
import dayjs from 'dayjs'
import React from 'react'
import * as ReactRedux from 'react-redux'
import { useLocation } from 'react-router-dom'
import { getPermissions } from '../../app/selectors'
import {
  Box,
  FlatList,
  ScrollView,
  _,
  helpers,
  hooks,
  styleSheets,
  theme,
} from '../../common'
import { languageEnumAsString } from '../../common/helpers'
import { completeTodo, refreshTodos } from '../actionsV2'
import { useTodosQuery } from '../queries'

export const TodoItem = ({
  item,
  hasReferenceName,
  onPressComplete: onPressCompleteCallback,
  width = 280,
  isDisabled,
}) => {
  const dispatch = ReactRedux.useDispatch()
  const location = useLocation()

  const getThemedColor = hooks.useThemedColor()

  const isTodoPage = location.pathname?.includes('/todos')

  const isGroup = item?.type === TODO_ENTITY_TYPE.GROUP

  const langString = languageEnumAsString[item?.language]

  const onPressComplete = () => {
    dispatch(completeTodo({ todoId: item?.id }))
    onPressCompleteCallback?.()
  }

  const onPressItem = (e) => {
    if (isTodoPage) {
      _.openRoute(
        `/artificialtells_v2/${langString}/${isGroup ? 'group' : 'topic'}/${
          item.referenceId
        }`,
        e
      )
    }
  }

  return (
    <Card
      onClick={isDisabled ? undefined : onPressItem}
      bodyStyle={{ padding: 12 }}
      style={{
        cursor: isTodoPage ? 'pointer' : undefined,
        width,
        backgroundColor: getThemedColor(
          theme.colors.antdBackgroundElevated,
          colors.black[5]
        ),
      }}>
      {item?.content ? (
        <>
          <Box flexDirection="row" alignItems="center" transparent>
            <Box flex="1" marginRight={10} transparent>
              <Box
                transparent
                flexDirection="row"
                justifyContent="space-between">
                <Box transparent flexDirection="row">
                  <Typography.Text style={{ paddingRight: 5 }}>
                    {isGroup ? 'Group:' : 'Topic:'}
                  </Typography.Text>
                  <Typography.Link
                    href={`/artificialtells_v2/${langString}/${
                      isGroup ? 'group' : 'topic'
                    }/${item.referenceId}`}>
                    {item.referenceId}
                  </Typography.Link>
                  <Typography.Text
                    style={{
                      color: grey[1],
                      paddingLeft: 5,
                    }}>{`- ${langString}`}</Typography.Text>
                </Box>
                <Typography.Text style={{ fontSize: 12, marginLeft: 8 }}>
                  {`Due ${dayjs(item.dueDate).fromNow()}`}
                </Typography.Text>
              </Box>
              <Box transparent flexDirection="row">
                <Box transparent flex={1}>
                  <ScrollView style={{ maxHeight: 120 }}>
                    {hasReferenceName && (
                      <Typography.Text
                        style={{ color: grey[1], marginBottom: 4 }}>
                        {item.referenceName}
                      </Typography.Text>
                    )}
                    <Typography.Text>{item.content}</Typography.Text>
                  </ScrollView>
                </Box>

                {item.status === TODO_STATUS.OPEN && (
                  <Button
                    onClick={onPressComplete}
                    icon={<CheckOutlined />}
                    shape="circle"
                    style={{ alignSelf: 'flex-end', marginLeft: 8 }}
                  />
                )}
              </Box>
            </Box>
          </Box>
          <Box
            flexDirection="row"
            justifyContent="space-between"
            marginTop={8}
            transparent>
            <Typography.Text style={{ fontSize: 12 }}>
              {item.status === TODO_STATUS.DONE
                ? `Completed by: ${item.completedByUsername}`
                : ''}
            </Typography.Text>
          </Box>
        </>
      ) : (
        <Empty
          image={Empty.PRESENTED_IMAGE_SIMPLE}
          description="No open todo"
          style={{ marginTop: 0, marginBottom: 0 }}
        />
      )}
    </Card>
  )
}

const statusOptions = [
  { label: 'Open', value: TODO_STATUS.OPEN },
  { label: 'Done', value: TODO_STATUS.DONE },
]

const Header = ({
  isTranslator,
  onChangeLanguage,
  onChangeStatus,
  language,
  languageOptions,
  status,
  onChangeCompletedByFilter,
  completedByFilter,
  userOptions,
}) => {
  return (
    <Box
      flexDirection="row"
      alignItems="center"
      style={{ marginTop: 24, marginBottom: 24 }}>
      <Box flexDirection="row" alignItems="center">
        <Typography.Text style={{ paddingRight: 5 }}>Status:</Typography.Text>
        <Segmented
          options={statusOptions}
          value={status}
          onChange={onChangeStatus}
        />
      </Box>

      {languageOptions?.length > 2 && (
        <Box
          flexDirection="row"
          alignItems="center"
          style={styleSheets.margin.left[24]}>
          <Typography.Text style={{ paddingRight: 5 }}>
            Language:
          </Typography.Text>
          <Select
            options={languageOptions}
            onChange={onChangeLanguage}
            value={language}
          />
        </Box>
      )}

      {status === TODO_STATUS.DONE && !isTranslator && (
        <Box
          flexDirection="row"
          alignItems="center"
          style={styleSheets.margin.left[24]}>
          <Typography.Text style={{ paddingRight: 5 }}>
            Completed By:
          </Typography.Text>
          <Select
            options={userOptions}
            onChange={onChangeCompletedByFilter}
            value={completedByFilter}
            style={{ width: 120 }}
          />
        </Box>
      )}
    </Box>
  )
}

const TodoListItem = (props) => {
  return (
    <List.Item style={{ paddingTop: 0, display: 'flex' }}>
      <TodoItem {...props} />
    </List.Item>
  )
}

export const PageArtificialTellsTodos = () => {
  const dispatch = ReactRedux.useDispatch()

  const [status, setStatus] = React.useState(TODO_STATUS.OPEN)
  const [completedCount, setCompletedCount] = React.useState(0)
  const [language, setLanguage] = React.useState('all')
  const [completedByFilter, setCompletedByFilter] = React.useState('all')

  const [languageOptions, setLanguageOptions] = React.useState([
    { label: 'All', value: 'all' },
  ])

  const [userOptions, setUserOptions] = React.useState([
    { label: 'All', value: 'all' },
  ])

  const permissions = ReactRedux.useSelector(getPermissions)
  const isTranslator = helpers.checkIsTranslator(permissions)

  const { data, possibleParams, isLoading, fetchNextPage, refetch } =
    useTodosQuery({
      language: language === 'all' ? undefined : language,
      status,
      completedBy:
        status !== TODO_STATUS.DONE || completedByFilter === 'all'
          ? undefined
          : completedByFilter,
    })

  const actions = React.useMemo(
    () => ({
      fetch: (payload) => {
        fetchNextPage({
          pageParam: payload,
          cancelRefetch: false,
        })
      },
      refresh: (payload) => {
        refetch(payload)
      },
    }),
    [fetchNextPage, refetch]
  )

  React.useEffect(() => {
    if (typeof possibleParams?.completedBy !== 'undefined') {
      const options = possibleParams.completedBy.reduce(
        (acc, user) => {
          const { username, id } = user

          return [...acc, { label: username, value: id }]
        },
        [{ label: 'All', value: 'all' }]
      )

      setUserOptions(options)
    }
  }, [possibleParams?.completedBy])

  React.useEffect(() => {
    if (typeof possibleParams?.language !== 'undefined') {
      const langs = possibleParams.language.reduce(
        (acc, curr) => {
          return [
            ...acc,
            {
              label: languageEnumAsString[curr],
              value: curr,
            },
          ]
        },
        [{ label: 'All', value: 'all' }]
      )

      setLanguageOptions(langs)
    }
  }, [possibleParams?.language])

  const onChangeCompletedByFilter = React.useCallback(
    (value) => {
      setCompletedByFilter(value)
    },
    [setCompletedByFilter]
  )

  const onChangeLanguage = React.useCallback(
    (value) => {
      setLanguage(value)
    },
    [setLanguage]
  )

  const onChangeStatus = React.useCallback(
    (value) => {
      setStatus(value)
    },
    [setStatus]
  )

  const onPressComplete = React.useCallback(() => {
    setCompletedCount((c) => c + 1)
  }, [setCompletedCount])

  React.useEffect(() => {
    const l = language === 'all' ? undefined : language

    const payload = {
      language: l,
      status,
      completedBy: completedByFilter === 'all' ? undefined : completedByFilter,
    }

    dispatch(refreshTodos(payload))
  }, [dispatch, language, status, completedCount, completedByFilter])

  return (
    <FlatList
      items={data}
      component={TodoListItem}
      actions={actions}
      isRefreshing={isLoading}
      contentContainerStyle={{ alignItems: 'center' }}
      extraProps={{ hasReferenceName: true, width: 600, onPressComplete }}
      renderHeader={() => (
        <Header
          completedByFilter={completedByFilter}
          isTranslator={isTranslator}
          onChangeLanguage={onChangeLanguage}
          onChangeStatus={onChangeStatus}
          onChangeCompletedByFilter={onChangeCompletedByFilter}
          language={language}
          languageOptions={languageOptions}
          status={status}
          userOptions={userOptions}
        />
      )}
    />
  )
}
