import { grey } from '@ant-design/colors'
import { Button, Input, Modal, Typography } from 'antd'
import React from 'react'
import * as ReactRedux from 'react-redux'
import { Box, hooks } from '../../common'
import { createTopic } from '../actionsV2'

const config = {
  submitAction: createTopic,
  defaultState: {
    name: '',
    initialGroupName: '',
    initialGroupIdToMove: undefined,
  },
}

export const ButtonModalCreateTopic = ({
  children = 'Create Topic',
  size = 'small',
  ...buttonProps
}) => {
  const dispatch = ReactRedux.useDispatch()
  const inputRef = React.useRef(null)
  const [isModalVisible, setIsModalVisible] = React.useState(false)
  const [state, setState] = React.useState(config.defaultState)

  const toggleModal = () => {
    setIsModalVisible((isModalVisible) => !isModalVisible)
  }

  const onSubmit = () => {
    if (state.name.length === 0) {
      return
    }

    toggleModal()

    dispatch(
      config.submitAction({
        name: state.name,
        initialGroupName: state.initialGroupName || undefined,
        initialGroupIdToMove:
          typeof state.initialGroupIdToMove !== 'undefined'
            ? parseInt(state.initialGroupIdToMove, 10)
            : undefined,
      })
    )

    setState((state) => ({
      ...state,
      ...config.defaultState,
    }))
  }

  hooks.useKeyboardShortcutToSubmit({
    inputRef,
    onSubmit,
    hasActionKey: true,
  })

  React.useEffect(() => {
    if (isModalVisible && inputRef.current) {
      inputRef.current.focus()
    }
  }, [isModalVisible])

  return (
    <>
      <Button onClick={toggleModal} size={size} {...buttonProps}>
        {children}
      </Button>
      <Modal
        open={isModalVisible}
        onOk={onSubmit}
        onCancel={toggleModal}
        width={600}>
        <Box>
          <Typography.Title level={2}>{children}</Typography.Title>

          <Box gap={8}>
            <Typography.Text>Topic Name</Typography.Text>
            <Input
              ref={inputRef}
              autoSize={{ minRows: 2, maxRows: 5 }}
              onChange={(e) => {
                setState((state) => ({ ...state, name: e.target.value }))
              }}
              value={state.name}
            />

            <Typography.Text style={{ color: grey[1] }}>
              You must either provide a group name for the initial group of this
              topic or the ID of the group that should be moved into this topic
              to become the initial group.
            </Typography.Text>

            <Typography.Text>First group content</Typography.Text>
            <Input
              autoSize={{ minRows: 2, maxRows: 5 }}
              onChange={(e) => {
                setState((state) => ({
                  ...state,
                  initialGroupName: e.target.value,
                  initialGroupIdToMove: undefined,
                }))
              }}
              value={state.initialGroupName}
            />

            <Typography.Text style={{ color: grey[1] }}>or</Typography.Text>
            <Typography.Text>Group ID to move to this topic</Typography.Text>
            <Input
              autoSize={{ minRows: 2, maxRows: 5 }}
              type="number"
              onChange={(e) => {
                setState((state) => ({
                  ...state,
                  initialGroupIdToMove: e.target.value,
                  initialGroupName: undefined,
                }))
              }}
              value={state.initialGroupIdToMove}
            />
          </Box>
        </Box>
      </Modal>
    </>
  )
}
