import { EditOutlined } from '@ant-design/icons'
import { intHash } from '@tellonym/core/helpers'
import { Button, Input, Modal, Tag, Typography } from 'antd'
import React from 'react'
import { AntdModal, Box, styleSheets } from '../../common'
import { antdTagColors } from '../../common/styles/colors'
import {
  useAddTopicTagMutation,
  useRemoveTopicTagMutation,
  useTopicTagsQuery,
} from '../queries'

const showModal = () =>
  AntdModal.show({
    id: 'ModalTopicTags',
    render: (props) => <ModalTopicTagsComponent {...props} />,
    footer: null,
  })

export const TopicTags = ({
  tags,
  onPressTag,
  activeTags,
  hasEditTag,
  hasRemoveTag,
}) => {
  const { mutate: removeTag } = useRemoveTopicTagMutation()

  const onPressAddTag = () => {
    showModal()
  }

  const onPressRemoveTag = (tag) => {
    Modal.confirm({
      title: 'Remove Tag',
      content: `Are you sure you want to remove the tag "${tag}"?`,
      okText: 'Yes',
      cancelText: 'No',
      onOk: () => {
        removeTag(tag)
      },
    })
  }

  if (!tags) {
    return null
  }

  return (
    <Box transparent flexDirection="row" flexWrap="wrap">
      {tags.map((tag) => (
        <Tag
          key={tag}
          color={antdTagColors[intHash(tag) % antdTagColors.length]}
          onClick={() => onPressTag?.(tag)}
          closable={hasRemoveTag}
          onClose={() => onPressRemoveTag?.(tag)}
          style={{
            marginRight: 8,
            marginBottom: 8,
            cursor: 'pointer',
            fontWeight: activeTags?.includes(tag) ? 'bold' : 'normal',
          }}>
          {tag}
        </Tag>
      ))}

      {hasEditTag && (
        <Tag
          className="site-tag-plus"
          onClick={onPressAddTag}
          style={{ marginBottom: 8, cursor: 'pointer' }}>
          <EditOutlined /> Edit
        </Tag>
      )}
    </Box>
  )
}

const ModalTopicTagsComponent = () => {
  const [name, setName] = React.useState('')
  const [description, setDescription] = React.useState('')

  const { data } = useTopicTagsQuery()

  const { mutate: addTag } = useAddTopicTagMutation()

  const onPressSave = () => {
    addTag({ tag: name, description })
  }

  return (
    <Box transparent>
      <Typography.Title level={3}>ModalTopicTagsComponent</Typography.Title>

      <TopicTags hasRemoveTag tags={data?.tags.map((tag) => tag.tag)} />

      <Typography.Title level={5} style={styleSheets.margin.top[24]}>
        Add new tag
      </Typography.Title>
      <Typography.Text style={{ fontSize: 10, fontWeight: '700' }}>
        Name
      </Typography.Text>
      <Input
        autoFocus
        onChange={(e) => {
          setName(e.target.value)
        }}
        value={name}
        onPressEnter={onPressSave}
        style={styleSheets.margin.bottom[12]}
      />

      <Typography.Text style={{ fontSize: 10, fontWeight: '700' }}>
        Description
      </Typography.Text>
      <Input
        onChange={(e) => {
          setDescription(e.target.value)
        }}
        value={description}
        onPressEnter={onPressSave}
        style={styleSheets.margin.bottom[12]}
      />

      <Box transparent alignItems="flex-end">
        <Button
          type="primary"
          style={styleSheets.margin.top[12]}
          onClick={onPressSave}>
          Save
        </Button>
      </Box>
    </Box>
  )
}
