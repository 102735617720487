import { faGlobeEurope } from '@fortawesome/free-solid-svg-icons'
import { Button, Switch, Typography } from 'antd'
import React from 'react'
import { config } from '../../../config'
import {
  Box,
  BoxSecondary,
  FlatList,
  Icon,
  Modal,
  TextTranslatable,
  styleSheets,
  theme,
} from '../../common'
import { useAnswersQuery } from '../queries'
import { SelectorsLanguage } from './SelectorsLanguage'

const styles = {
  mainContainer: {
    backgroundColor: theme.colors.background,
    minHeight: window.innerHeight - 50,
    width: 800,
    padding: 8,
  },
  tableHeadContainer: {
    flexDirection: 'row',
    paddingTop: 16,
    paddingBottom: 16,
  },
  tableHeadCell: {
    borderWidth: 0,
    borderLeftWidth: 1,
    borderColor: theme.colors.borderGrey,
    borderStyle: 'solid',
    paddingRight: 16,
    paddingLeft: 16,
    fontWeight: '500',
  },
  tableItemContainer: {
    flexDirection: 'row',
    backgroundColor: theme.colors.background,
    borderWidth: 0,
    borderColor: theme.colors.borderLightGrey,
    borderStyle: 'solid',
    paddingVertical: 12,
    onHover: { backgroundColor: theme.colors.gray98 },
  },
  tableItemCell: {
    paddingLeft: 16,
    paddingRight: 16,
  },
}

const Header = ({ close, group, onChangeShouldTranslate, shouldTranslate }) => {
  if (!group) {
    return null
  }

  return (
    <BoxSecondary>
      <Box
        paddingTop={16}
        paddingLeft={24}
        paddingRight={24}
        marginBottom={12}
        transparent>
        <Box
          flexDirection="row"
          transparent
          alignItems="center"
          justifyContent="flex-end">
          <Button size="small" onClick={close}>
            x
          </Button>
        </Box>
      </Box>

      <Box
        transparent
        paddingHorizontal={24}
        marginBottom={12}
        justifyContent="space-between">
        <Box alignItems="flex-end" paddingBottom={12} transparent>
          <SelectorsLanguage languageStatusList={group?.languages} />
        </Box>

        <Box transparent flex={1}>
          <Typography.Title level={2} style={{ marginBottom: 12 }}>
            {group.name}
          </Typography.Title>
        </Box>
      </Box>

      <Box {...styles.tableHeadContainer}>
        <Typography.Text
          style={{ flex: 0.5, ...styles.tableHeadCell, textAlign: 'left' }}>
          Id
        </Typography.Text>
        <Typography.Text style={{ flex: 6, ...styles.tableHeadCell }}>
          Content
        </Typography.Text>
        <Box flex={1} alignItems="center" paddingRight={16} transparent>
          <Typography.Text
            style={{ textAlign: 'center', ...styles.tableHeadCell }}>
            Translate
          </Typography.Text>
          <Switch
            checked={shouldTranslate}
            checkedChildren={<Icon icon={faGlobeEurope} />}
            unCheckedChildren={<Icon icon={faGlobeEurope} />}
            onChange={onChangeShouldTranslate}
          />
        </Box>
      </Box>
    </BoxSecondary>
  )
}

const Item = ({ hasSeparator, item, shouldTranslate: shouldTranslateAll }) => {
  const [shouldTranslate, setShouldTranslate] =
    React.useState(shouldTranslateAll)

  const [isLoadingTranslation, setIsLoadingTranslation] = React.useState(false)

  const onChange = (isChecked, e) => {
    e.stopPropagation()
    setShouldTranslate(isChecked)
  }

  const onPress = () => {
    window.open(`${config.webUrl}/unknown/answer/${item.id}`, '_blank')
  }

  const preventOnPress = (e) => {
    e.stopPropagation()
  }

  React.useEffect(() => {
    setShouldTranslate(shouldTranslateAll)
  }, [shouldTranslateAll])

  return (
    <Box
      onPress={onPress}
      {...styles.tableItemContainer}
      borderBottomWidth={hasSeparator ? 1 : 0}>
      <Typography.Text style={{ flex: 1, ...styles.tableItemCell }}>
        {item.id}
      </Typography.Text>
      <TextTranslatable.Pure
        onLoadChange={setIsLoadingTranslation}
        shouldTranslate={shouldTranslate}
        style={[styles.tableItemCell, styleSheets.flex[6]]}>
        {item.content}
      </TextTranslatable.Pure>
      <Box
        transparent
        onPress={preventOnPress}
        {...styles.tableItemCell}
        flex={1}
        alignItems="flex-end">
        <Switch
          checked={shouldTranslate}
          checkedChildren={<Icon icon={faGlobeEurope} />}
          unCheckedChildren={<Icon icon={faGlobeEurope} />}
          loading={isLoadingTranslation}
          onClick={onChange}
        />
      </Box>
    </Box>
  )
}

const ModalArtificialTellAnswersComponent = ({
  groupId,
  modalId,
  varianceId,
}) => {
  const [shouldTranslate, setShouldTranslate] = React.useState(false)

  const close = React.useCallback(() => {
    Modal.hide({ id: modalId })
  }, [modalId])

  const { data, fetchNextPage, refetch, isLoading } = useAnswersQuery(
    typeof groupId === 'number' ? { groupId } : { varianceId }
  )

  const actions = React.useMemo(
    () => ({
      fetch: (payload) => {
        fetchNextPage({
          pageParam: payload,
          cancelRefetch: false,
        })
      },
      refresh: (payload) => {
        refetch(payload)
      },
    }),
    [fetchNextPage, refetch]
  )

  const extraData = React.useMemo(
    () => ({
      hasMore: data.hasMore,
      isFetching: false,
      isRefreshing: isLoading,
    }),
    [data.hasMore, isLoading]
  )

  return (
    <Box flex={1} justifyContent="center" alignItems="center" transparent>
      <Modal.Body style={styles.mainContainer}>
        <FlatList
          actions={actions}
          component={Item}
          extraData={extraData}
          extraProps={{ shouldTranslate }}
          hasMore
          // This should be used but does not work correctly  hasMore={extraData.hasMore}
          isFetching={extraData.isFetching}
          isRefreshing={extraData.isRefreshing}
          items={data.answers}
          onEndReachedThreshold={0.5}
          renderHeader={(props) => (
            <Header
              close={close}
              group={data.group}
              onChangeShouldTranslate={setShouldTranslate}
              {...props}
            />
          )}
          style={{ height: window.innerHeight - 50 }}
        />
      </Modal.Body>
    </Box>
  )
}

const show = (payload) =>
  Modal.show({
    render: (props) => (
      <ModalArtificialTellAnswersComponent {...payload} {...props} />
    ),
    hasBackdrop: true,
    shouldHideOnBackgroundPress: true,
  })

export const ModalArtificialTellAnswers = { show }
