import { grey } from '@ant-design/colors'
import { ARTIFICIAL_TELL_TARGET_GENDER } from '@tellonym/enums/lib/Tell'
import { Breadcrumb, Button, Input, Typography } from 'antd'
import React from 'react'
import * as ReactRedux from 'react-redux'
import { Box, BoxSecondary, Col, history, Row } from '../../common'
import { editPrompt, refreshPrompts } from '../actionsV2'
import { getIsSavingPrompt, getLanguage, getPromptsTool } from '../selectorsV2'
import { ButtonBack } from './ButtonBack'
import { SelectorsLanguage } from './SelectorsLanguage'

const Header = () => {
  return (
    <BoxSecondary paddingBottom={16} paddingHorizontal={24} paddingTop={24}>
      <Row>
        <Col xs={24} md={6}>
          <Box
            flexDirection="row"
            alignItems="center"
            transparent
            marginBottom={12}>
            <ButtonBack
              onPress={() => {
                history.goBack()
              }}
              style={{ marginRight: 12, width: 30, height: 30 }}
            />

            <Breadcrumb>
              <Breadcrumb.Item key="PromptTool">
                Art. Tells Prompt Tool
              </Breadcrumb.Item>
            </Breadcrumb>
          </Box>
        </Col>

        <Col xs={24} md={18}>
          <Box transparent alignItems="flex-end" marginBottom={24}>
            <SelectorsLanguage />
          </Box>
        </Col>
      </Row>
    </BoxSecondary>
  )
}

export const PageArtificialTellsPromptTool = () => {
  const language = ReactRedux.useSelector(getLanguage)
  const dispatch = ReactRedux.useDispatch()

  const promptsTool = ReactRedux.useSelector(getPromptsTool)
  const isSavingPrompt = ReactRedux.useSelector(getIsSavingPrompt)

  const [prompts, setPrompts] = React.useState({
    bothPrompt: '',
    bothPromptFilled: '',
    malePrompt: '',
    malePromptFilled: '',
    femalePrompt: '',
    femalePromptFilled: '',
  })

  React.useEffect(() => {
    dispatch(refreshPrompts())
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [language])

  React.useEffect(() => {
    setPrompts(promptsTool)
  }, [promptsTool])

  return (
    <Box flex={1} transparent>
      <Header />

      <BoxSecondary flex={1} paddingHorizontal={24}>
        {[
          ARTIFICIAL_TELL_TARGET_GENDER.BOTH,
          ARTIFICIAL_TELL_TARGET_GENDER.MALE,
          ARTIFICIAL_TELL_TARGET_GENDER.FEMALE,
        ].map((gender) => {
          const genderString =
            ARTIFICIAL_TELL_TARGET_GENDER[gender].toLowerCase()
          const promptKey = `${genderString}Prompt`
          const promptKeyFilled = `${genderString}PromptFilled`

          return (
            <Box key={gender} transparent paddingBottom={24}>
              <Typography.Title level={3}>{genderString}</Typography.Title>
              <Row>
                <Col>
                  <Input.TextArea
                    onChange={(e) => {
                      setPrompts((prompts) => ({
                        ...prompts,
                        [promptKey]: e.target.value,
                      }))
                    }}
                    rows={15}
                    cols={50}
                    value={prompts[promptKey]}
                  />
                </Col>
                <Col>
                  <Input.TextArea
                    disabled
                    onChange={(e) => {
                      setPrompts((prompts) => ({
                        ...prompts,
                        [promptKeyFilled]: e.target.value,
                      }))
                    }}
                    rows={15}
                    cols={50}
                    value={prompts[promptKeyFilled]}
                    style={{
                      cursor: 'default',
                      backgroundColor: 'white',
                      color: grey[5],
                    }}
                  />
                </Col>
              </Row>
              <Box transparent alignItems="flex-start">
                <Button
                  type="primary"
                  disabled={isSavingPrompt}
                  onClick={() => {
                    dispatch(
                      editPrompt({ gender, promptTemplate: prompts[promptKey] })
                    )
                  }}>
                  Save
                </Button>
              </Box>
            </Box>
          )
        })}
      </BoxSecondary>
    </Box>
  )
}
