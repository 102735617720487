import { headerModes } from '@tellonym/core/artificialTells/constants'
import { langDetectObjectsByType1 } from '@tellonym/enums/lib/Language'
import { ARTIFICIAL_TELL_STATUS } from '@tellonym/enums/lib/Tell'
import { Badge, Button, Dropdown, Typography } from 'antd'
import qs from 'qs'
import React, { useMemo } from 'react'
import * as ReactRedux from 'react-redux'
import { Box, TableEndlessScroll, View, _, styleSheets } from '../../common'
import { editGroup } from '../actionsV2'
import { getGroupMode, getHeaderMode } from '../selectorsV2'
import { ModalMoveGroupToOtherTopic } from './ModalMoveGroupToOtherTopic'
import {
  SharedCells,
  SharedColumns,
  Stat,
  useColumnSearchProps,
} from './SharedColumns'

const activeVariancesCountKeyMap = [
  ['both', 'green'],
  ['male', 'blue'],
  ['female', 'magenta'],
]

const GenderIndicator = ({ activeVariancesCount }) => {
  return (
    <Box transparent flexDirection="row" width="100%">
      {activeVariancesCountKeyMap.map(([key, color]) => (
        <Box transparent key={key} flex={1} alignItems="center">
          {activeVariancesCount[key] > 0 && <Badge color={color} />}
        </Box>
      ))}
    </Box>
  )
}

export const ArtificialTellTopicGroupsTable = (props) => {
  const dispatch = ReactRedux.useDispatch()

  const groupMode = ReactRedux.useSelector(getGroupMode)
  const headerMode = ReactRedux.useSelector(getHeaderMode)

  const searchProps = useColumnSearchProps('name')

  const columns = useMemo(() => {
    return (
      props.columns ??
      [
        {
          ...SharedColumns.id,
          ...SharedCells.statusCountsTitle(props.dataSource),
          sorter: { multiple: 1, compare: SharedColumns.id.sorter },
          /**
           * This allows to pass specific ids that should be filtered for
           * Requires onPressResetFilter to be passed as well
           */
          ...(props.isDataSourceFiltered
            ? {
                filtered: true,
                filters: [],
                filterDropdownOpen: false,
                onFilterDropdownOpenChange: (open) => {
                  props.onPressResetFilter?.(open)
                },
              }
            : {}),
        },
        SharedColumns.status,
        SharedColumns.groupDepth,
        SharedColumns.groupType,
        props.hasTopic && {
          title: 'Topic',
          dataIndex: 'id',
          key: 'topicName',
          width: 80,
          render: (id, group) => {
            const isTooLong = group.topicName?.length > 10

            const topicNameShort = isTooLong
              ? group.topicName.substring(0, 10)
              : group.topicName

            return (
              <Typography.Paragraph
                ellipsis={{
                  rows: 1,
                  expandable: false,
                }}
                title={group.topicName}
                style={{ fontSize: 10 }}>
                {topicNameShort}
              </Typography.Paragraph>
            )
          },
        },
        {
          title: 'Question',
          dataIndex: 'id',
          key: 'name',
          width: 280,
          render: (id, group) => <View>{group.name}</View>,
          ...searchProps,
          sorter: (a, b) => {
            const textA = a.name?.toLowerCase()
            const textB = b.name?.toLowerCase()

            if (textA < textB) {
              return -1
            }

            if (textA > textB) {
              return 1
            }

            return 0
          },
        },
        SharedColumns.groupTotalSent,
        {
          title: 'Answer Rate',
          dataIndex: 'id',
          key: 'answerRate',
          width: 80,
          sorter: (a, b) =>
            a.analytics.answerRate.amount - b.analytics.answerRate.amount,
          render: (id, group) => {
            if (group.status === ARTIFICIAL_TELL_STATUS.NOT_LOCALIZED) {
              return null
            }

            return (
              <Stat
                isPercentage
                value={group.analytics.answerRate.amount}
                change={group.analytics.answerRate.change}
              />
            )
          },
        },
        {
          title: 'Median Length',
          dataIndex: 'id',
          key: 'p50Length',
          width: 80,
          sorter: (a, b) =>
            a.analytics.p50Length.amount - b.analytics.p50Length.amount,
          render: (id, group) => {
            if (group.status === ARTIFICIAL_TELL_STATUS.NOT_LOCALIZED) {
              return null
            }

            return (
              <Stat
                value={group.analytics.p50Length.amount}
                change={group.analytics.p50Length.change}
              />
            )
          },
        },
        {
          title: 'Share Rate',
          dataIndex: 'id',
          key: 'shareRate',
          width: 80,
          sorter: (a, b) =>
            a.analytics.shareRate.amount - b.analytics.shareRate.amount,
          render: (id, group) => {
            if (group.status === ARTIFICIAL_TELL_STATUS.NOT_LOCALIZED) {
              return null
            }

            return (
              <Stat
                isPercentage
                value={group.analytics.shareRate.amount}
                change={group.analytics.shareRate.change}
              />
            )
          },
        },
        {
          title: 'Reuse Days',
          dataIndex: 'id',
          key: 'reaskInDays',
          width: 80,
          filterMultiple: false,
          filters: [
            {
              text: 'Greater than 0',
              value: 1,
            },
          ],
          onFilter: (value, group) => {
            return value === 1 ? group.reaskInDays > 0 : true
          },
          onHeaderCell: () => ({
            onClick: () => {
              props.setTableState((tableState) => ({
                ...tableState,
                filters: {
                  ...tableState.filters,
                  reaskInDays: Array.isArray(tableState.filters.reaskInDays)
                    ? undefined
                    : [1],
                },
              }))
            },
          }),
          render: (id, group) => (
            <View style={styleSheets.center}>{group.reaskInDays}</View>
          ),
        },
        props.hasTimeLimited && {
          title: 'Time Limited',
          dataIndex: 'id',
          key: 'topicValidPeriod',
          width: 80,
          filterMultiple: false,
          filters: [
            {
              text: 'Yes',
              value: 1,
            },
            {
              text: 'No',
              value: 0,
            },
          ],
          onFilter: (value, group) => {
            switch (value) {
              case 1:
                return (
                  Boolean(group.topicValidPeriod?.startDate) ||
                  Boolean(group.topicValidPeriod?.endDate)
                )
              case 0:
                return (
                  !Boolean(group.topicValidPeriod?.startDate) &&
                  !Boolean(group.topicValidPeriod?.endDate)
                )
              default:
                return true
            }
          },
          render: (id, group) => {
            const isTimeLimited =
              Boolean(group.topicValidPeriod?.startDate) ||
              Boolean(group.topicValidPeriod?.endDate)

            return (
              <View style={styleSheets.center}>
                {isTimeLimited ? 'Yes' : 'No'}
              </View>
            )
          },
        },
        {
          title: 'Vars',
          dataIndex: 'id',
          key: 'activeVariancesCountTotal',
          width: 80,
          sorter: (a, b) =>
            a.activeVariancesCount.total - b.activeVariancesCount.total,
          filterMultiple: false,
          filters: [
            {
              text: 'Has Female Variances',
              value: 'hasFemale',
            },
            {
              text: 'Has Male Variances',
              value: 'hasMale',
            },
            {
              text: 'No Both Variances',
              value: 'noBoth',
            },
            {
              text: 'Only Both Variances',
              value: 'onlyBoth',
            },
          ],
          onFilter: (value, group) => {
            switch (value) {
              case 'hasFemale':
                return group.activeVariancesCount.female > 0

              case 'hasMale':
                return group.activeVariancesCount.male > 0

              case 'noBoth':
                return group.activeVariancesCount.both === 0

              case 'onlyBoth':
                return (
                  group.activeVariancesCount.both > 0 &&
                  group.activeVariancesCount.female === 0 &&
                  group.activeVariancesCount.male === 0
                )
              default:
                return true
            }
          },
          render: (id, group) => {
            if (group.status === ARTIFICIAL_TELL_STATUS.NOT_LOCALIZED) {
              return null
            }

            return (
              <Box transparent alignItems="center">
                {group.activeVariancesCount.total}
                {typeof group.inactiveVariancesCount === 'number' &&
                  ` (${group.inactiveVariancesCount})`}
                <GenderIndicator
                  activeVariancesCount={group.activeVariancesCount}
                />
              </Box>
            )
          },
        },
        SharedColumns.groupSenderHint,
        SharedColumns.lastUpdatedAt,
        {
          title: 'Actions',
          dataIndex: 'id',
          key: 'actions',
          width: 80,
          render: (id, group) => {
            if (group.status === ARTIFICIAL_TELL_STATUS.NOT_LOCALIZED) {
              return null
            }

            const items = [
              { key: 'move', label: 'Move group to other topic' },
              {
                key: 'inactiveCurrentLanguage',
                label: 'Set inactive for selected language',
              },
              {
                key: 'inactiveAllLanguages',
                label: 'Set inactive for all languages',
              },
              group.status === ARTIFICIAL_TELL_STATUS.INACTIVE && {
                key: 'deleteAllLanguages',
                label: 'Delete for all languages',
              },
            ].filter(Boolean)

            const onClick = ({ key, domEvent }) => {
              domEvent.stopPropagation()

              switch (key) {
                case 'move': {
                  ModalMoveGroupToOtherTopic.show({
                    topicId: props.topicId,
                    group,
                  })
                  break
                }

                case 'inactiveCurrentLanguage': {
                  dispatch(
                    editGroup({
                      id: group.id,
                      status: ARTIFICIAL_TELL_STATUS.INACTIVE,
                      languageForChangingStatus:
                        langDetectObjectsByType1[props.language],
                    })
                  )
                  break
                }

                case 'inactiveAllLanguages': {
                  dispatch(
                    editGroup({
                      id: group.id,
                      status: ARTIFICIAL_TELL_STATUS.INACTIVE,
                      languageForChangingStatus: 'ALL',
                    })
                  )

                  break
                }

                case 'deleteAllLanguages': {
                  if (group.status === ARTIFICIAL_TELL_STATUS.INACTIVE) {
                    dispatch(
                      editGroup({
                        id: group.id,
                        status: ARTIFICIAL_TELL_STATUS.DELETED,
                        languageForChangingStatus: 'ALL',
                      })
                    )
                  }
                  break
                }

                default:
                  break
              }
            }

            return (
              <Box
                alignItems="center"
                flexDirection="row"
                justifyContent="center"
                transparent
                width={55}>
                <Button
                  onClick={(e) => onClick({ key: 'move', domEvent: e })}
                  size="small">
                  Move
                </Button>

                <Dropdown
                  menu={{ items, onClick }}
                  placement="bottomLeft"
                  onClick={(e) => {
                    e.stopPropagation()
                  }}>
                  <Button size="small" style={{ letterSpacing: -0.5 }}>
                    ...
                  </Button>
                </Dropdown>
              </Box>
            )
          },
        },
      ].filter(Boolean)
    )
  }, [dispatch, props, searchProps])

  const onRow = React.useCallback(
    (group) => {
      const queryString = qs.stringify({
        groupMode,
        headerMode: headerMode === headerModes.CONTENT ? groupMode : headerMode,
      })

      const routeProps = _.openRouteProps(
        `/artificialtells_v2/${props.language ?? 'en'}/group/${
          group.id
        }?${queryString}`
      )

      return { onClick: routeProps.onPress, onAuxClick: routeProps.onAuxClick }
    },
    [props.language, groupMode, headerMode]
  )

  return (
    <TableEndlessScroll
      {...props}
      hasSmallRows
      hasZebraRows
      rowKey="id"
      columns={columns}
      onRow={onRow}
    />
  )
}
