import { faGlobeEurope } from '@fortawesome/free-solid-svg-icons'
import { Breadcrumb, Switch, Typography } from 'antd'
import React from 'react'
import * as ReactRedux from 'react-redux'
import { config } from '../../../config'
import {
  Box,
  BoxSecondary,
  FlatList,
  Icon,
  TextTranslatable,
  history,
  styleSheets,
  theme,
} from '../../common'
import { useAnswersQuery } from '../queries'
import { getLanguage } from '../selectorsV2'
import { ButtonBack } from './ButtonBack'
import { SelectorsLanguage } from './SelectorsLanguage'

const styles = {
  tableHeadContainer: {
    flexDirection: 'row',
    paddingTop: 16,
    paddingBottom: 16,
  },
  tableHeadCell: {
    borderWidth: 0,
    borderLeftWidth: 1,
    borderColor: theme.colors.borderGrey,
    borderStyle: 'solid',
    paddingRight: 16,
    paddingLeft: 16,
    fontWeight: '500',
  },
  tableItemContainer: {
    flexDirection: 'row',
    backgroundColor: theme.colors.background,
    borderWidth: 0,
    borderColor: theme.colors.borderLightGrey,
    borderStyle: 'solid',
    paddingVertical: 12,
    onHover: { backgroundColor: theme.colors.gray98 },
  },
  tableItemCell: {
    paddingLeft: 16,
    paddingRight: 16,
  },
}

const Header = ({
  group,
  language,
  onChangeShouldTranslate,
  shouldTranslate,
}) => {
  if (!group) {
    return null
  }

  return (
    <BoxSecondary>
      <Box
        paddingTop={16}
        paddingLeft={24}
        paddingRight={24}
        marginBottom={12}
        transparent>
        <Box flexDirection="row" transparent alignItems="center">
          <ButtonBack
            onPress={() => history.goBack()}
            style={{ marginRight: 12, width: 30, height: 30 }}
          />

          <Breadcrumb>
            <Breadcrumb.Item key="Group">
              <a
                href="#"
                onClick={(e) => {
                  e.preventDefault()
                  history.push({
                    pathname: `/artificialtells_v2/${language}/group/${group.id}`,
                  })
                }}>
                {`Group ${group.id}`}
              </a>
            </Breadcrumb.Item>
          </Breadcrumb>
        </Box>
      </Box>

      <Box
        transparent
        paddingHorizontal={24}
        marginBottom={12}
        flexDirection="row"
        justifyContent="space-between">
        <Box transparent flex={1}>
          <Typography.Title level={2} style={{ marginBottom: 12 }}>
            {group.name}
          </Typography.Title>
        </Box>

        <SelectorsLanguage languageStatusList={group?.languages} />
      </Box>

      <BoxSecondary hasBottomBorder {...styles.tableHeadContainer}>
        <Typography.Text
          style={{ flex: 0.5, ...styles.tableHeadCell, textAlign: 'left' }}>
          Id
        </Typography.Text>
        <Typography.Text style={{ flex: 6, ...styles.tableHeadCell }}>
          Content
        </Typography.Text>
        <Box
          flex={1}
          justifyContent="space-between"
          flexDirection="row"
          paddingRight={16}
          transparent>
          <Typography.Text
            style={{ textAlign: 'center', ...styles.tableHeadCell }}>
            Translate
          </Typography.Text>
          <Switch
            checked={shouldTranslate}
            checkedChildren={<Icon icon={faGlobeEurope} />}
            unCheckedChildren={<Icon icon={faGlobeEurope} />}
            onChange={onChangeShouldTranslate}
          />
        </Box>
      </BoxSecondary>
    </BoxSecondary>
  )
}

const Item = ({ hasSeparator, item, shouldTranslate: shouldTranslateAll }) => {
  const [shouldTranslate, setShouldTranslate] =
    React.useState(shouldTranslateAll)

  const [isLoadingTranslation, setIsLoadingTranslation] = React.useState(false)

  const onChange = (isChecked, e) => {
    e.stopPropagation()
    setShouldTranslate(isChecked)
  }

  const onPress = () => {
    window.location = `${config.webUrl}/unknown/answer/${item.id}`
  }

  const preventOnPress = (e) => {
    e.stopPropagation()
  }

  React.useEffect(() => {
    setShouldTranslate(shouldTranslateAll)
  }, [shouldTranslateAll])

  return (
    <Box
      onPress={onPress}
      {...styles.tableItemContainer}
      borderBottomWidth={hasSeparator ? 1 : 0}>
      <Typography.Text style={{ flex: 1, ...styles.tableItemCell }}>
        {item.id}
      </Typography.Text>
      <TextTranslatable.Pure
        onLoadChange={setIsLoadingTranslation}
        shouldTranslate={shouldTranslate}
        style={[styles.tableItemCell, styleSheets.flex[6]]}>
        {item.content}
      </TextTranslatable.Pure>
      <Box
        transparent
        onPress={preventOnPress}
        {...styles.tableItemCell}
        flex={1}
        alignItems="flex-end">
        <Switch
          checked={shouldTranslate}
          checkedChildren={<Icon icon={faGlobeEurope} />}
          unCheckedChildren={<Icon icon={faGlobeEurope} />}
          loading={isLoadingTranslation}
          onClick={onChange}
        />
      </Box>
    </Box>
  )
}

export const PageArtificialTellsAnswersV2 = ({ match }) => {
  const [shouldTranslate, setShouldTranslate] = React.useState(false)

  const groupId = parseInt(match.params.groupId, 10)
  const varianceId = parseInt(match.params.varianceId, 10)

  const isGroup = Number.isNaN(groupId) === false

  const { data, fetchNextPage, refetch, isLoading } = useAnswersQuery(
    isGroup ? { groupId } : { varianceId }
  )

  const language = ReactRedux.useSelector(getLanguage)

  const actions = React.useMemo(
    () => ({
      fetch: (payload) => {
        fetchNextPage({
          pageParam: payload,
          cancelRefetch: false,
        })
      },
      refresh: (payload) => {
        refetch(payload)
      },
    }),
    [fetchNextPage, refetch]
  )

  const extraData = React.useMemo(
    () => ({
      hasMore: data.hasMore,
      isFetching: false,
      isRefreshing: isLoading,
    }),
    [data.hasMore, isLoading]
  )

  return (
    <FlatList
      actions={actions}
      component={Item}
      extraData={extraData}
      extraProps={{ shouldTranslate }}
      hasMore
      // This should be used but does not work correctly  hasMore={extraData.hasMore}
      isFetching={extraData.isFetching}
      isRefreshing={extraData.isRefreshing}
      items={data.answers}
      onEndReachedThreshold={0.5}
      renderHeader={(props) => (
        <Header
          group={data.group}
          language={language}
          onChangeShouldTranslate={setShouldTranslate}
          {...props}
        />
      )}
    />
  )
}
