import { theme } from './theme'

export const globalStylesheet = {
  html: {
    fontFamily: 'Roboto, Helvetica Neue, Helvetica, Arial, sans-serif',
    height: '100%',
    overflow: 'hidden',
  },
  body: {
    margin: 0,
    height: '100%',
    overflow: 'auto',
    backgroundColor: theme.colors.white,
  },
  '#root': {
    height: '100%',
    display: 'flex',
  },
  '#root > [data-radium]': {
    display: 'flex',
    flex: 1,
  },
  '.tnym-hidden': {
    display: 'none',
  },
  '.ant-radio-button-wrapper.ant-radio-button-wrapper-checked.warning': {
    borderColor: '#faad14 !important',
    backgroundColor: '#faad14',
    color: 'white',
  },
  '.ant-radio-button-wrapper.ant-radio-button-wrapper-checked.warning:before': {
    backgroundColor: '#faad14',
  },
  '.ant-radio-button-wrapper.warning:hover': {
    color: '#faad14',
  },
  '.ant-radio-button-wrapper.ant-radio-button-wrapper-checked.warning:hover': {
    backgroundColor: '#ffc53d',
    color: 'white',
  },
  '.ant-radio-button-wrapper.ant-radio-button-wrapper-checked.danger': {
    borderColor: '#f5222d !important',
    backgroundColor: '#f5222d',
    color: 'white',
  },
  '.ant-radio-button-wrapper.ant-radio-button-wrapper-checked.danger:before': {
    backgroundColor: '#f5222d',
  },
  '.ant-radio-button-wrapper.danger:hover': {
    color: '#f5222d',
  },
  '.ant-radio-button-wrapper.ant-radio-button-wrapper-checked.danger:hover': {
    backgroundColor: '#ff4d4f',
    color: 'white',
  },
  '.ant-radio-button-wrapper.ant-radio-button-wrapper-checked.success': {
    borderColor: '#52c41a !important',
    backgroundColor: '#52c41a',
    color: 'white',
  },
  '.ant-radio-button-wrapper.ant-radio-button-wrapper-checked.success:before': {
    backgroundColor: '#52c41a',
  },
  '.ant-radio-button-wrapper.success:hover': {
    color: '#52c41a',
  },
  '.ant-radio-button-wrapper.ant-radio-button-wrapper-checked.success:hover': {
    backgroundColor: '#73d13d',
    color: 'white',
  },
  '.ant-btn.success': {
    borderColor: '#52c41a !important',
    color: '#52c41a',
  },
  '.ant-btn.success:hover': {
    borderColor: '#73d13d !important',
    color: '#73d13d',
  },
  '.ant-btn.success.disabled': {
    borderColor: '#d9d9d9 !important',
    color: 'rgba(0, 0, 0, 0.25)',
  },
  '.ant-btn.ant-btn-primary.success': {
    color: 'white',
    backgroundColor: '#52c41a',
  },
  '.ant-btn.ant-btn-primary.success:hover': {
    color: 'white',
    backgroundColor: '#73d13d',
  },
}

export const blackStylesheet = {
  body: {
    backgroundColor: '#1D1D1D',
  },
  '.ant-pro-card': {
    backgroundColor: '#141414',
  },
  '.ant-pro-card-title': {
    color: 'rgba(255, 255, 255, 0.85) !important',
  },
  '.ant-statistic-title': {
    color: 'rgba(255, 255, 255, 0.85) !important',
  },
  '.ant-statistic-content-value': {
    color: 'rgba(255, 255, 255, 0.85) !important',
  },
  '.ant-pro-card-statistic-description': {
    color: 'rgba(255, 255, 255, 0.85) !important',
  },
}
