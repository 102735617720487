import { DownOutlined, UpOutlined } from '@ant-design/icons'
import { Pie } from '@ant-design/plots'
import { ProCard, StatisticCard } from '@ant-design/pro-components'
import { colors } from '@tellonym/core/common/colorSystem'
import {
  ARTIFICIAL_TELL_STATUS,
  ARTIFICIAL_TELL_USER_GROUP_TYPE,
} from '@tellonym/enums/lib/Tell'
import { Breadcrumb, Drawer, Tooltip, Typography } from 'antd'
import React from 'react'
import * as ReactRedux from 'react-redux'
import {
  _,
  Box,
  BoxSecondary,
  history,
  hooks,
  ScrollView,
  TableEndlessScroll,
  Text,
} from '../../common'
import { TagBox } from '../../common/components/TagBox'
import { valueToPercentage } from '../../statistics/services'
import { useProfileSharingDashboardQuery, useTopicsQuery } from '../queries'
import { getLanguage } from '../selectorsV2'
import { ArtificialTellStatusTag } from './ArtificialTellStatusTag'
import { ButtonBack } from './ButtonBack'
import {
  getChangePercentage,
  getShortnameValue,
} from './PageArtificialTellsDashboard'
import { SelectorsLanguage } from './SelectorsLanguage'
import { SharedColumns, statusOrder } from './SharedColumns'

const PieChart = ({ data }) => {
  const config = {
    appendPadding: 10,
    data,
    angleField: 'totalCount',
    colorField: 'tag',
    radius: 1,
    innerRadius: 0.6,
    label: {
      type: 'inner',
      offset: '-50%',
      content: '{value}',
      style: {
        textAlign: 'center',
        fontSize: 14,
      },
    },
    statistic: { title: false },
    legend: {
      position: 'right',
      itemName: {
        formatter: (text) => {
          const item = data.find((t) => t.tag === text)

          return `${text}: ${item.totalCount}`
        },
      },
    },
  }

  return <Pie {...config} />
}

const ConnectedTopicsTable = ({ categories, language }) => {
  const table = hooks.useTableState()

  const { data, isLoading } = useTopicsQuery({
    language,
    dataMode: ARTIFICIAL_TELL_USER_GROUP_TYPE.HIGH,
  })

  const datasource = React.useMemo(() => {
    if (categories === null || typeof data === 'undefined') {
      return []
    }

    return data?.topics.filter((topic) =>
      categories.includes(topic.topicCategory)
    )
  }, [data, categories])

  const columns = [
    SharedColumns.id,
    {
      title: 'Topic',
      dataIndex: 'name',
      render: (name) => {
        return <Text type="small">{name}</Text>
      },
    },
    {
      title: 'Category',
      dataIndex: 'topicCategory',
      width: 240,
      render: (category, item) => {
        return (
          <TagBox
            isLabel
            backgroundColor={
              item.isCategoryExclusive ? colors.blue[15] : 'transparent'
            }
            color={item.isCategoryExclusive ? 'white' : 'black'}
            text={category}
          />
        )
      },
    },
  ]

  const onRow = React.useCallback(
    (item) => {
      return _.openRoutePropsAnt(
        `/artificialtells_v2/${language}/topic/${item.id}`
      )
    },
    [language]
  )

  return (
    <TableEndlessScroll
      {...table}
      hasSmallRows
      hasZebraRows
      rowKey="id"
      loading={isLoading}
      dataSource={datasource}
      columns={columns}
      onRow={onRow}
    />
  )
}

const getDescription = (data, shortname) => {
  const shortnameData = data?.data[shortname]

  return `last ${data?.config?.timeframeInDays}d ${getChangePercentage(
    shortnameData
  )}`
}

const Header = ({ data, isLoading }) => {
  const getThemedColor = hooks.useThemedColor()

  const [isNoContentExtended, setIsNoContentExtended] = React.useState(false)

  const onPressNoContentExtended = () => {
    setIsNoContentExtended(!isNoContentExtended)
  }

  return (
    <BoxSecondary paddingHorizontal={24} paddingTop={24} paddingBottom={8}>
      <Box
        transparent
        flexDirection="row"
        justifyContent="space-between"
        marginBottom={16}>
        <Box transparent flexDirection="row" alignItems="center">
          <ButtonBack
            onPress={() => {
              history.goBack()
            }}
            style={{ marginRight: 12, width: 30, height: 30 }}
          />

          <Breadcrumb>
            <Breadcrumb.Item key="Profile Share">Profile Share</Breadcrumb.Item>
          </Breadcrumb>
        </Box>

        <SelectorsLanguage />
      </Box>

      <ProCard ghost wrap gutter={[16, 16]}>
        <ProCard
          title="Screen visits"
          loading={isLoading}
          bordered
          headerBordered
          colSpan={4}
          bodyStyle={{ padding: 0 }}>
          <StatisticCard
            statistic={{
              value: getShortnameValue(data?.data['CH-ShareCards-View-WAUs']),
              description: getDescription(data, 'CH-ShareCards-View-WAUs'),
            }}
          />
        </ProCard>
        <ProCard
          title="Shares"
          colSpan={{ xs: 24, md: 12, xl: 8 }}
          loading={isLoading}
          bordered
          headerBordered
          wrap
          bodyStyle={{ padding: 0 }}>
          <StatisticCard
            colSpan={12}
            title="DAUs: % users"
            statistic={{
              value: getShortnameValue(data?.data['CH-ShareCards-Share-Open']),
              description: getDescription(data, 'CH-ShareCards-Share-Open'),
            }}
          />
          <StatisticCard
            colSpan={12}
            title="DAUs: Actions per unique"
            statistic={{
              value: getShortnameValue(
                data?.data['CH-ShareCards-Share-Open-ActionsPerUnique'],
                2
              ),
              description: getDescription(
                data,
                'CH-ShareCards-Share-Open-ActionsPerUnique'
              ),
            }}
          />
          <StatisticCard
            colSpan={12}
            title="WAUs: % users"
            statistic={{
              value: getShortnameValue(
                data?.data['CH-ShareCards-Share-Open-Weekly']
              ),
              description: getDescription(
                data,
                'CH-ShareCards-Share-Open-Weekly'
              ),
            }}
          />
          <StatisticCard
            colSpan={12}
            title="WAUs: Actions per unique"
            statistic={{
              value: getShortnameValue(
                data?.data['CH-ShareCards-Share-Open-Weekly-ActionsPerUnique'],
                2
              ),
              description: getDescription(
                data,
                'CH-ShareCards-Share-Open-Weekly-ActionsPerUnique'
              ),
            }}
          />
        </ProCard>
        <ProCard
          title="Interactions"
          colSpan={isNoContentExtended ? 4 : 8}
          loading={isLoading}
          bordered
          headerBordered
          wrap
          bodyStyle={{ padding: 0 }}>
          <StatisticCard
            colSpan={isNoContentExtended ? 24 : 12}
            title="% users receiving organic tell"
            statistic={{
              value: getShortnameValue(
                data?.data['CH-ShareCards-ShareToOrganicTell'],
                2
              ),
              description: getDescription(
                data,
                'CH-ShareCards-ShareToOrganicTell'
              ),
            }}
          />
          <StatisticCard
            colSpan={isNoContentExtended ? 24 : 12}
            title="Art. Tell Answer rate"
            statistic={{
              value: getShortnameValue(
                data?.data['CH-ShareCards-ArTell-AnswerRate'],
                2
              ),
              description: getDescription(
                data,
                'CH-ShareCards-ArTell-AnswerRate'
              ),
            }}
          />
        </ProCard>
        <ProCard
          title="No Content"
          colSpan={isNoContentExtended ? 8 : 4}
          loading={isLoading}
          bordered
          headerBordered
          bodyStyle={{ padding: 0 }}
          extra={
            isNoContentExtended ? (
              <UpOutlined
                onClick={onPressNoContentExtended}
                style={{ color: getThemedColor(undefined, colors.white[1]) }}
              />
            ) : (
              <DownOutlined
                onClick={onPressNoContentExtended}
                style={{ color: getThemedColor(undefined, colors.white[1]) }}
              />
            )
          }>
          {isNoContentExtended ? (
            <Box width="100%" height={320}>
              <PieChart data={data?.noContentData} />
            </Box>
          ) : (
            <StatisticCard
              statistic={{
                value: data?.noContentData?.reduce(
                  (acc, curr) => acc + curr.totalCount,
                  0
                ),
                description: `last ${data?.config?.timeframeInDays}d`,
                style: { alignItems: 'center' },
              }}
            />
          )}
        </ProCard>
      </ProCard>
    </BoxSecondary>
  )
}

const statusKeys = [
  'amountAvailableFollowups',
  'amountTotalShares',
  'percentageUsersReceivingOrganicTells',
  'percentageArTellAnswerRate',
]

const getComputedStatus = (record) => {
  return statusKeys.some((key) => record[key] > 0)
    ? ARTIFICIAL_TELL_STATUS.ACTIVE
    : ARTIFICIAL_TELL_STATUS.INACTIVE
}

export const PageProfileSharing = () => {
  const table = hooks.useTableState()

  const [selectedCategories, setSelectedCategories] = React.useState(null)

  const language = ReactRedux.useSelector(getLanguage)

  const { data, isLoading } = useProfileSharingDashboardQuery({
    language,
  })

  const datasource = data?.contentData

  const columns = React.useMemo(
    () => [
      SharedColumns.id,
      {
        title: 'Status',
        dataIndex: 'id',
        width: 90,
        defaultSortOrder: 'ascend',
        sorter: (a, b) => {
          const statusA = getComputedStatus(a)
          const statusB = getComputedStatus(b)

          const textA = statusOrder[statusA]
          const textB = statusOrder[statusB]

          if (textA < textB) {
            return -1
          }

          if (textA > textB) {
            return 1
          }

          return 0
        },
        render: (id, record) => {
          return <ArtificialTellStatusTag status={getComputedStatus(record)} />
        },
      },
      {
        title: 'Share card',
        dataIndex: 'contentEnglish',
        width: '40%',
        render: (content, record) => (
          <Tooltip title={record.content} placement="topLeft">
            <span>{record.contentEnglish}</span>
          </Tooltip>
        ),
      },
      {
        title: 'Sub Page',
        dataIndex: 'subPage',
        width: 120,
      },
      {
        title: 'Categories',
        dataIndex: 'categories',
        width: '25%',
        render: (categories) => {
          return (
            <Box transparent flexDirection="row" flexWrap="wrap">
              {categories.map((c) => (
                <TagBox
                  key={c}
                  isLabel
                  backgroundColor={'transparent'}
                  color={'black'}
                  text={c}
                />
              ))}
            </Box>
          )
        },
      },
      {
        title: (
          <Tooltip title="This is not an actual amount but a calculated number that represents how often we can send a Tell in response to this share card that has at least one group from all desired categories. Calculation: It uses the amount of groups from the group with the least groups by weight (total amount groups / category weight). Then we divide the category weight by the total weight and then divide the total amount of groups by that result (total amount groups / (category weight / total weight)).">
            <span>Avail. Follow ups</span>
          </Tooltip>
        ),
        dataIndex: 'amountAvailableFollowups',
        width: 100,
      },
      {
        title: 'Total Shares',
        dataIndex: 'amountTotalShares',
        width: 80,
      },
      {
        title: '% users receiving organic tell',
        dataIndex: 'percentageUsersReceivingOrganicTells',
        width: 120,
        render: (value) => valueToPercentage(value),
      },
      {
        title: 'Total AR Tells sent',
        dataIndex: 'amountTotalArTellsSent',
        width: 120,
      },
      {
        title: 'Answer rate',
        dataIndex: 'percentageArTellAnswerRate',
        width: 80,
        render: (value) => valueToPercentage(value),
      },
    ],
    []
  )

  const onRow = React.useCallback((item) => {
    const openDrawer = () => {
      setSelectedCategories(item.categories)
    }

    return { onClick: openDrawer, onAuxClick: openDrawer }
  }, [])

  const onClose = () => {
    setSelectedCategories(null)
  }

  return (
    <Box>
      <Header data={data} isLoading={isLoading} />
      <Drawer
        title="Connected Groups"
        placement="right"
        onClose={onClose}
        open={Array.isArray(selectedCategories)}
        size="large"
        bodyStyle={{ padding: 0 }}>
        <ConnectedTopicsTable
          categories={selectedCategories}
          language={language}
        />
      </Drawer>
      <ScrollView>
        <BoxSecondary paddingHorizontal={12}>
          <Typography.Text type="secondary" style={{ textAlign: 'right' }}>
            All data points represent the last 28d
          </Typography.Text>
        </BoxSecondary>
        <TableEndlessScroll
          {...table}
          hasSmallRows
          hasZebraRows
          rowKey="id"
          loading={isLoading}
          dataSource={datasource}
          language={language}
          columns={columns}
          onRow={onRow}
        />
      </ScrollView>
    </Box>
  )
}
