import * as PropsStyles from '@tellonym/core/constants/PropsStyles'
import { Col as AntdCol, Row as AntdRow } from 'antd'
import { mergeDeepRight, mergeRight, omit, pick } from 'ramda'
import React from 'react'
import * as hooks from '../hooks'
import { colors } from '../styles/colors'
import { TouchableOpacity } from './TouchableOpacity'
import { View } from './View'

const styleProps = [
  PropsStyles.alignContent,
  PropsStyles.alignItems,
  PropsStyles.alignSelf,
  PropsStyles.aspectRatio,
  PropsStyles.backgroundColor,
  PropsStyles.borderBottomColor,
  PropsStyles.borderBottomEndRadius,
  PropsStyles.borderBottomLeftRadius,
  PropsStyles.borderBottomRightRadius,
  PropsStyles.borderBottomStartRadius,
  PropsStyles.borderBottomWidth,
  PropsStyles.borderColor,
  PropsStyles.borderEndColor,
  PropsStyles.borderEndWidth,
  PropsStyles.borderLeftColor,
  PropsStyles.borderLeftWidth,
  PropsStyles.borderRadius,
  PropsStyles.borderRightColor,
  PropsStyles.borderRightWidth,
  PropsStyles.borderStartColor,
  PropsStyles.borderStartWidth,
  PropsStyles.borderStyle,
  PropsStyles.borderTopColor,
  PropsStyles.borderTopEndRadius,
  PropsStyles.borderTopLeftRadius,
  PropsStyles.borderTopRightRadius,
  PropsStyles.borderTopStartRadius,
  PropsStyles.borderTopWidth,
  PropsStyles.borderWidth,
  PropsStyles.bottom,
  PropsStyles.color,
  PropsStyles.cursor,
  PropsStyles.display,
  PropsStyles.elevation,
  PropsStyles.flex,
  PropsStyles.flexBasis,
  PropsStyles.flexDirection,
  PropsStyles.flexGrow,
  PropsStyles.flexShrink,
  PropsStyles.flexWrap,
  PropsStyles.height,
  PropsStyles.justifyContent,
  PropsStyles.left,
  PropsStyles.margin,
  PropsStyles.marginBottom,
  PropsStyles.marginEnd,
  PropsStyles.marginHorizontal,
  PropsStyles.marginLeft,
  PropsStyles.marginRight,
  PropsStyles.marginStart,
  PropsStyles.marginTop,
  PropsStyles.marginVertical,
  PropsStyles.maxHeight,
  PropsStyles.maxWidth,
  PropsStyles.minHeight,
  PropsStyles.minWidth,
  PropsStyles.opacity,
  PropsStyles.overflow,
  PropsStyles.padding,
  PropsStyles.paddingBottom,
  PropsStyles.paddingEnd,
  PropsStyles.paddingHorizontal,
  PropsStyles.paddingLeft,
  PropsStyles.paddingRight,
  PropsStyles.paddingStart,
  PropsStyles.paddingTop,
  PropsStyles.paddingVertical,
  PropsStyles.position,
  PropsStyles.right,
  PropsStyles.shadowColor,
  PropsStyles.shadowOffset,
  PropsStyles.shadowOpacity,
  PropsStyles.shadowRadius,
  PropsStyles.top,
  PropsStyles.width,
  PropsStyles.zIndex,
  'filter',
  'overflowX',
  'overflowY',
  'whiteSpace',
  'borderTopStyle',
  'borderRightStyle',
  'borderBottomStyle',
  'borderLeftStyle',
  'onHover',
  'gap',
]

const computeProps = (props) => {
  return {
    isTouchable:
      props.onPress || props.onLongPress || props.onPressIn || props.onPressOut,
    props: omit([...styleProps, 'transparent', 'isRow'], props),
    style: mergeRight(props.style, pick(styleProps, props)),
  }
}

export const Box = React.forwardRef((props, ref) => {
  const getThemedColor = hooks.useThemedColor()

  const computed = React.useMemo(
    () =>
      mergeDeepRight(computeProps(props), {
        style: {
          backgroundColor:
            props.backgroundColor ??
            (props.transparent
              ? 'transparent'
              : getThemedColor(
                  colors.background,
                  colors.antdDarkModeBackground
                )),
        },
      }),
    [getThemedColor, props]
  )

  if (computed.isTouchable) {
    return (
      <TouchableOpacity
        {...computed.props}
        forwardRef={ref}
        style={computed.style}
      />
    )
  }

  return <View {...computed.props} forwardRef={ref} style={computed.style} />
})

export const Row = (props) => {
  const computed = React.useMemo(() => computeProps(props), [props])

  return <AntdRow {...computed.props} style={computed.style} />
}

export const Col = (props) => {
  const computed = React.useMemo(() => computeProps(props), [props])

  return <AntdCol {...computed.props} style={computed.style} />
}
