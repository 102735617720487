export const name = '__app__'

export const REFRESH_PERMISSIONS = `${name}/REFRESH_PERMISSIONS`
export const REFRESH_PERMISSIONS_ERROR = `${name}/REFRESH_PERMISSIONS_ERROR`
export const REFRESH_PERMISSIONS_SUCCESS = `${name}/REFRESH_PERMISSIONS_SUCCESS`

export const SET_IS_DARK_MODE_ENABLED = `${name}/SET_IS_DARK_MODE_ENABLED`

export const TOGGLE_SIDEMENU = `${name}/TOGGLE_SIDEMENU`

export const SET_CUSTOM_API_HOST = `${name}/SET_CUSTOM_API_HOST`
