import { uuidv4 } from '@tellonym/core/helpers'
import { Modal } from 'antd'
import { mergeDeepRight } from 'ramda'
import React from 'react'
import { createStore } from 'redux'

const initialState = {
  queue: { ids: [], data: {} },
}

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case 'SHOW': {
      const { id } = action.payload

      if (state.queue.ids.includes(id)) {
        return state
      }

      return mergeDeepRight(state, {
        queue: {
          ids: [...new Set([...state.queue.ids, id])],
          data: {
            [id]: { ...action.payload, isRequestingClose: false },
          },
        },
      })
    }

    case 'HIDE': {
      const { extraData, id, resolveHide } = action.payload
      const itemId = id ?? state.queue.ids[0]

      if (!state.queue.ids.includes(itemId)) {
        return state
      }

      return mergeDeepRight(state, {
        queue: {
          data: {
            [itemId]: { extraData, isRequestingClose: true, resolveHide },
          },
        },
      })
    }

    case 'RESET': {
      const { id } = action.payload
      const { data, ids } = state.queue

      return {
        ...state,
        queue: {
          ids: ids.filter((item) => item !== id),
          data: ids.reduce(
            (acc, curr) => (curr !== id ? { ...acc, [curr]: data[curr] } : acc),
            {}
          ),
        },
      }
    }

    case 'UPDATE': {
      const { id, ...props } = action.payload

      return mergeDeepRight(state, { queue: { data: { [id]: props } } })
    }

    default:
      return state
  }
}

const store = createStore(reducer)

const hide = ({ id, extraData }) =>
  new Promise((resolve) => {
    store.dispatch({
      type: 'HIDE',
      payload: {
        extraData,
        id,
        resolveHide: resolve,
      },
    })
  })

const isVisible = (payload = { id: undefined }) => {
  const { queue } = store.getState()
  const { id } = payload
  return id ? queue.data[id] && queue.data[id].isVisible : queue.ids.length > 0
}

const reset = ({ id }) => store.dispatch({ type: 'RESET', payload: { id } })

const show = ({ id = undefined, render = () => {}, ...props }) =>
  new Promise((resolve) => {
    store.dispatch({
      type: 'SHOW',
      payload: {
        id: id || uuidv4(),
        render,
        resolveShow: resolve,
        resolveHide: () => {},
        ...props,
      },
    })
  })

const update = ({ id, ...props }) =>
  store.dispatch({ type: 'UPDATE', payload: { id, ...props } })

const AntdModal = () => {
  const [, setForceUpdate] = React.useState(0)

  React.useEffect(() => {
    const unsubscribe = store.subscribe(() =>
      setForceUpdate((forceUpdate) => forceUpdate + 1)
    )

    return () => {
      unsubscribe()
    }
  }, [])

  const { queue } = store.getState()

  return queue.ids[0]
    ? queue.ids.map((id) => {
        const {
          id: _id, // we filter this out to not set it as a prop on the Modal component
          afterClose,
          extraData,
          isRequestingClose,
          render,
          resolveHide,
          resolveShow,
          ...props
        } = queue.data[id]

        return (
          <Modal
            key={id}
            afterClose={() => {
              reset({ id })
              resolveHide(extraData)
              resolveShow(extraData)
              afterClose?.()
            }}
            open={!isRequestingClose}
            onCancel={() => {
              hide({ id, extraData })
            }}
            onOk={() => {
              hide({ id, extraData })
            }}
            {...props}>
            {render({
              closeModal: (extraData) => {
                hide({ id, extraData })
              },
              modalId: id,
              updateProps: (props) => update({ id, ...props }),
            })}
          </Modal>
        )
      })
    : null
}

AntdModal.hide = hide
AntdModal.show = show
AntdModal.isVisible = isVisible
AntdModal.update = update

export { AntdModal }
