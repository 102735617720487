import { Button, Col, Input, Row, Select } from 'antd'
import React from 'react'
import * as ReactRedux from 'react-redux'
import { useRouteMatch } from 'react-router'
import { Box, history } from '../../common'
import { search } from '../actions'
import { getIsRefreshingSearch } from '../selectors'

const searchCategories = {
  EMAIL: 'email',
  IP: 'ip',
  PHONE_NUMBER: 'phonenumber',
  SOCIAL_LINK: 'sociallink',
  USERNAME: 'username',
  USER_ID: 'userid',
  REVENUECAT_ID: 'revenuecatid',
}

const searchCategoriesOrder = [
  searchCategories.USERNAME,
  searchCategories.USER_ID,
  searchCategories.SOCIAL_LINK,
  searchCategories.EMAIL,
  searchCategories.PHONE_NUMBER,
  searchCategories.REVENUECAT_ID,
  searchCategories.IP,
]

const SearchBarNew = () => {
  const dispatch = ReactRedux.useDispatch()
  const match = useRouteMatch()
  const [searchTerm, setSearchTerm] = React.useState('')
  const [searchCategory, setSearchCategory] = React.useState(
    searchCategoriesOrder[0]
  )

  const isLoading = ReactRedux.useSelector(getIsRefreshingSearch)

  const onChangeSelect = (value) => {
    setSearchCategory(value)

    history.push(`/search/${value}`)
  }

  const onSubmit = (e) => {
    e.preventDefault()

    dispatch(
      search({
        value: searchTerm,
        type: searchCategory,
      })
    )
  }

  React.useEffect(() => {
    const { type } = match.params || {}
    const foundType = searchCategoriesOrder.find((i) => i === type)

    if (foundType) {
      setSearchCategory(foundType)
    }
  }, [match.params])

  return (
    <Box paddingVertical={24}>
      <Row>
        <Col
          xs={{ span: 20, offset: 2 }}
          lg={{ span: 16, offset: 4 }}
          xl={{ span: 12, offset: 6 }}>
          <Input.Group compact size="large" style={{ width: '100%' }}>
            <Input
              autoFocus
              disabled={isLoading}
              placeholder="Username or id"
              enterButton="Search"
              defaultValue="mysite"
              onChange={(e) => setSearchTerm(e.target.value)}
              onPressEnter={onSubmit}
              value={searchTerm}
              style={{ width: 'calc(100% - 120px - 80px)' }}
            />
            <Select
              className="select-after"
              disabled={isLoading}
              options={searchCategoriesOrder.map((value) => ({
                label: value,
                value,
              }))}
              onChange={onChangeSelect}
              value={searchCategory}
              style={{ width: 120 }}
            />
            <Button
              type="primary"
              size="large"
              onClick={onSubmit}
              loading={isLoading}
              style={{ width: 80 }}>
              Submit
            </Button>
          </Input.Group>
        </Col>
      </Row>
    </Box>
  )
}

export const SearchBar = SearchBarNew
