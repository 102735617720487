import {
  Alert,
  AutoComplete,
  Button,
  Descriptions,
  Input,
  Select,
  Typography,
} from 'antd'
import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Box, hooks, Modal, Text, theme, View } from '../../common'
import { editGroup, refreshTopics } from '../actionsV2'

const styles = {
  alert: { marginBottom: 24 },
  bgContainer: {
    alignItems: 'center',
    flex: 1,
    justifyContent: 'center',
  },
  buttonSubmit: { alignSelf: 'center', marginTop: 48 },
  mainContainer: {
    backgroundColor: theme.colors.background,
    borderRadius: 25,
    minHeight: 600,
    minWidth: 800,
    padding: 32,
  },
}

const MoveGroupToOtherTopic = ({ modalId, group, language }) => {
  const dispatch = useDispatch()

  const data = useSelector((state) => state.artificialTellsV2.topics)

  const { currentTopicName, currentTopicId, topicNames } = data?.topics?.reduce(
    (acc, topic) => {
      if (topic.id === (group.topicId ?? group.topic?.id)) {
        acc.currentTopicName = topic.name
        acc.currentTopicId = topic.id
      }

      if (acc.topicNames.includes(topic.name) === false) {
        acc.topicNames.push(topic.name)
      }

      return acc
    },
    { currentTopicName: undefined, currentTopicId: undefined, topicNames: [] }
  ) ?? {
    currentTopicName: undefined,
    currentTopicId: undefined,
    topicNames: [],
  }

  const [newTopicId, setNewTopicId] = React.useState('')
  const [newTopicName, setNewTopicName] = React.useState('')
  const [selectedTopicName, setSelectedTopicName] = React.useState('')
  const [options, setOptions] = React.useState([])

  const inputRef = React.useRef(null)

  const onChangeId = ({ target: { value } }) => {
    setNewTopicName('')
    setNewTopicId(value)

    const topicName = data?.topics?.find(
      (topic) => topic.id === parseInt(value, 10)
    )?.name

    if (topicName) {
      setNewTopicName(topicName)
      setSelectedTopicName(topicName)
    } else {
      setSelectedTopicName('')
    }
  }

  const onChangeName = (value) => {
    setNewTopicId('')
    setNewTopicName(value)

    const topicId = data?.topics?.find((topic) => topic.name === value)?.id

    if (topicId) {
      setNewTopicId(topicId)
      setSelectedTopicName(value)
    } else {
      setSelectedTopicName('')
    }
  }

  const onSearch = (searchText) => {
    setOptions(
      !searchText
        ? []
        : topicNames
            .filter((name) =>
              name.toLowerCase().includes(searchText.toLowerCase())
            )
            .map((name) => ({ label: name, value: name }))
    )
  }

  const onSubmit = () => {
    if (selectedTopicName) {
      Modal.hide({ id: modalId })

      dispatch(
        editGroup({
          id: group.id,
          language,
          topicId: Number(newTopicId),
          _noRefresh: true,
          _oldTopicId: currentTopicId,
        })
      )
    }
  }

  React.useEffect(() => {
    dispatch(refreshTopics())
  }, [language])

  hooks.useKeyboardShortcutToSubmit({
    inputRef,
    onSubmit,
    hasActionKey: true,
  })

  return (
    <View style={styles.bgContainer}>
      <Modal.Body style={styles.mainContainer}>
        <Text type="h2" bold style={{ marginBottom: 12 }}>
          Move group to other topic
        </Text>

        <Alert
          message="Please select a new topic by typing an ID or name or by using the dropdown below."
          type="info"
          showIcon
          style={{ marginBottom: 12 }}
        />

        <Box marginBottom={12}>
          <Typography.Text style={{ fontSize: 18 }}>
            Old:{' '}
            <Typography.Text style={{ fontSize: 18, fontWeight: 'bold' }}>
              {currentTopicName}
            </Typography.Text>
          </Typography.Text>
          <Typography.Text style={{ fontSize: 18 }}>
            New:{' '}
            <Typography.Text style={{ fontSize: 18, fontWeight: 'bold' }}>
              {selectedTopicName}
            </Typography.Text>
          </Typography.Text>
        </Box>

        <Descriptions bordered labelStyle={{ maxWidth: 100 }}>
          <Descriptions.Item label="Topic ID" span={3}>
            <Input
              ref={inputRef}
              value={newTopicId}
              onChange={onChangeId}
              autoFocus
            />
          </Descriptions.Item>
          <Descriptions.Item label="Topic Name" span={3}>
            <AutoComplete
              value={newTopicName}
              options={options}
              style={{ width: '100%' }}
              onSelect={onChangeName}
              onSearch={onSearch}
              onChange={onChangeName}
            />
          </Descriptions.Item>
          <Descriptions.Item label="Topic Selection" span={3}>
            <Select
              value={selectedTopicName}
              options={topicNames.map((name) => ({ label: name, value: name }))}
              onChange={onChangeName}
              style={{ width: '100%', marginRight: 12 }}
            />
          </Descriptions.Item>
        </Descriptions>

        <Button
          type="primary"
          shape="round"
          disabled={!selectedTopicName}
          onClick={onSubmit}
          style={styles.buttonSubmit}>
          Submit
        </Button>
      </Modal.Body>
    </View>
  )
}

const show = (payload) =>
  Modal.show({
    render: (props) => <MoveGroupToOtherTopic {...payload} {...props} />,
    hasBackdrop: true,
    shouldHideOnBackgroundPress: true,
  })

export const ModalMoveGroupToOtherTopic = { show }
