export const groupModes = {
  EDIT: 'edit',
  STATS: 'stats',
  TRANSLATE: 'translate',
}

export const headerModes = {
  CONTENT: 'content',
  EDIT: 'edit',
  GRAPH: 'graph',
  STATS: 'stats',
  TRANSLATE: 'translate',
}
