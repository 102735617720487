import { Input, Modal, Tag } from 'antd'
import React from 'react'
import * as ReactRedux from 'react-redux'
import { config } from '../../../config'
import { setCustomApiHost } from '../../app/actions'
import { getCustomApiHost } from '../../app/selectors'
import { Box } from '../../common/components/Box'
import { Text } from '../../common/components/Text'

const predefinedApiHosts = [
  ['Production', 'https://api.tellonym.me'],
  ['Staging', 'https://api-staging.tellonym.me'],
  ['Testing', 'https://api.tnym.de'],
  ['Testing red', 'https://api-red.tnym.de'],
  ['Testing blue', 'https://api-blue.tnym.de'],
  ['Local', 'http://localhost:3005'],
]

export const DebugModal = ({ open, onRequestClose }) => {
  const dispatch = ReactRedux.useDispatch()
  const customHost = ReactRedux.useSelector(getCustomApiHost)

  const [apiHost, setApiHost] = React.useState(
    typeof customHost === 'string' ? customHost : config.api.host
  )

  const changeApiHost = (host) => {
    setApiHost(host)
  }

  const onOk = () => {
    dispatch(setCustomApiHost({ customApiHost: apiHost }))
    onRequestClose()
  }

  return (
    <Modal
      open={open}
      title="Debug Modal"
      okText="Save"
      width={600}
      onOk={onOk}
      onCancel={onRequestClose}>
      <Box flex={1}>
        <Input
          label="Api Host"
          value={apiHost}
          onChange={(e) => changeApiHost(e.target.value)}
          style={{ marginTop: 24 }}
        />
        <Text bold style={{ marginTop: 24 }}>
          Presets
        </Text>
        <Box flexDirection="row" flexWrap="wrap" marginBottom={24}>
          {predefinedApiHosts.map(([name, host]) => (
            <Tag
              key={host}
              color="blue"
              onClick={() => {
                changeApiHost(host)
              }}
              style={{ marginTop: 8 }}>
              {name}
            </Tag>
          ))}
        </Box>
      </Box>
    </Modal>
  )
}
