import { getArtificialTellsLanguage } from '../common/helpers'
import { history } from '../common/history'

interface RouteData {
  name: string
  getPath?: () => string
  permissions?: string // Can override the permissions of the sidemenu section
}

interface Routes {
  ids: string[]
  data: Record<string, RouteData>
}

interface SidemenuNestedRoute {
  name: string
  isOpenByDefault?: boolean
  children: string[]
  permissions?: string
}

interface SidemenuSectionData {
  name?: string
  routes: (string | SidemenuNestedRoute)[]
  permissions?: string
}

interface Sidemenu {
  ids: string[]
  data: Record<string, SidemenuSectionData>
}

export const sidemenu: Sidemenu = {
  ids: ['index', 'moderation', 'arttells', 'shortnames', 'clickhouse'],
  data: {
    index: {
      routes: ['/'],
    },
    arttells: {
      name: 'Artificial Tells',
      routes: [
        {
          name: 'Dashboard',
          children: [
            '/artificialtells_v2/:language/dashboard',
            '/artificialtells_v2/workprogress',
            '/artificialtells_v2/:language/profilesharing',
          ],
        },
        {
          name: 'Groups',
          permissions: 'artificialtells.group.get',
          children: [
            '/artificialtells_v2/allgroups',
            '/artificialtells_v2/:language/notlocalized',
            '/artificialtells_v2/:language/outperforming',
            '/artificialtells_v2/:language/underperforming',
          ],
        },
        '/artificialtells_v2/:language/validation',
        '/artificialtells_v2/activity',
        '/artificialtells_v2/todos',
      ],
    },
    moderation: {
      name: 'Moderation',
      routes: [
        '/search',
        '/moderation',
        '/accountverification',
        '/nsfwpictures',
      ],
      permissions: 'moderation.get',
    },
    shortnames: {
      name: 'Shortnames',
      routes: ['/stats/ch/diff', '/stats/ch/compare'],
      permissions: 'stats.get',
    },
    clickhouse: {
      name: 'Clickhouse',
      routes: ['/stats/chevents', '/tools/analyticse2e'],
      permissions: 'stats.get',
    },
  },
}

export const routes: Routes = {
  ids: ['/'],

  data: {
    '/': {
      name: 'Dashboard',
    },
    '/artificialtells_v2/:language/dashboard': {
      name: 'Dashboard',
      permissions: 'artificialtells.dashboard',
    },
    '/artificialtells_v2/:language/profilesharing': {
      name: 'Profile Sharing',
      // permissions: 'artificialtells.dashboard',
    },
    '/artificialtells_v2/:language/topics/list': {
      name: 'Topics',
      getPath: () =>
        window.location.pathname.indexOf('/topics/list') >= 0
          ? `/artificialtells_v2/${getArtificialTellsLanguage()}/topics/list${
              history.location.search
            }`
          : `/artificialtells_v2/${getArtificialTellsLanguage()}/topics/list`,
      permissions: 'artificialtells.topic.get',
    },
    '/artificialtells_v2/allgroups': {
      name: 'All Groups',
    },
    '/artificialtells_v2/:language/notlocalized': {
      name: 'Not localized Groups',
      getPath: () =>
        `/artificialtells_v2/${getArtificialTellsLanguage()}/notlocalized`,
    },
    '/artificialtells_v2/:language/underperforming': {
      name: 'Underperf. Groups',
      getPath: () =>
        `/artificialtells_v2/${getArtificialTellsLanguage()}/underperforming`,
    },
    '/artificialtells_v2/activity': {
      name: 'Activity',
      permissions: 'artificialtells.topic.get',
    },
    '/artificialtells_v2/todos': {
      name: 'Todos',
      permissions: 'artificialtells.todo.get',
    },
    '/artificialtells_v2/:language/outperforming': {
      name: 'Overperf. Groups',
      getPath: () =>
        `/artificialtells_v2/${getArtificialTellsLanguage()}/outperforming`,
    },
    '/artificialtells_v2/:language/validation': {
      name: 'Validation',
      getPath: () =>
        `/artificialtells_v2/${getArtificialTellsLanguage()}/validation`,
      permissions: 'artificialtells.validation',
    },
    '/artificialtells_v2/workprogress': {
      name: 'Work Progress',
    },
    '/search': {
      name: 'Search',
    },
    '/spam/:spamItemId': {
      name: 'Spam Detection Item',
    },
    '/stats/ch/diff': {
      name: 'Changes',
    },
    '/stats/ch/compare': {
      name: 'Compare',
    },
    '/stats/chevents': {
      name: 'EventGraph',
    },
    '/moderation': {
      name: 'Moderation',
    },
    '/accountverification': {
      name: 'Acc. Verification',
    },
    '/nsfwpictures': {
      name: 'NSFW Pictures',
    },
    '/tools/analyticse2e': {
      name: 'Analytics E2E',
    },
  },
}
