import { faGlobeEurope } from '@fortawesome/free-solid-svg-icons'
import { Switch, Typography } from 'antd'
import React from 'react'
import { config } from '../../../config'
import {
  Box,
  FlatList,
  Icon,
  TextTranslatable,
  styleSheets,
  theme,
} from '../../common'
import { useAnswersQuery } from '../queries'

const styles = {
  tableItemContainer: {
    flexDirection: 'row',
    borderWidth: 0,
    borderColor: theme.colors.borderLightGrey,
    borderStyle: 'solid',
    paddingVertical: 4,
    onHover: { backgroundColor: theme.colors.gray98 },
  },
  tableItemCell: {
    paddingLeft: 16,
    paddingRight: 16,
  },
}

const Header = ({ group, onChangeShouldTranslate, shouldTranslate }) => {
  if (!group) {
    return null
  }

  return (
    <Box
      flex={1}
      flexDirection="row"
      alignItems="center"
      justifyContent="center"
      paddingTop={12}
      paddingRight={16}
      paddingBottom={12}
      gap={8}
      transparent>
      <Typography.Text style={{ textAlign: 'center' }}>
        Translate Answers
      </Typography.Text>
      <Switch
        checked={shouldTranslate}
        checkedChildren={<Icon icon={faGlobeEurope} />}
        unCheckedChildren={<Icon icon={faGlobeEurope} />}
        onChange={onChangeShouldTranslate}
      />
    </Box>
  )
}

const Item = ({ item, shouldTranslate: shouldTranslateAll }) => {
  const [shouldTranslate, setShouldTranslate] =
    React.useState(shouldTranslateAll)

  const onPress = () => {
    window.open(`${config.webUrl}/unknown/answer/${item.id}`, '_blank')
  }

  React.useEffect(() => {
    setShouldTranslate(shouldTranslateAll)
  }, [shouldTranslateAll])

  return (
    <Box onPress={onPress} {...styles.tableItemContainer} borderBottomWidth={1}>
      <TextTranslatable.Pure
        shouldTranslate={shouldTranslate}
        style={[styles.tableItemCell, styleSheets.flex[6]]}>
        {item.content}
      </TextTranslatable.Pure>
    </Box>
  )
}

export const ArtificialTellAnswers = ({
  height = window.innerHeight - 50,
  groupId,
  varianceId,
}) => {
  const [shouldTranslate, setShouldTranslate] = React.useState(false)

  const { data, fetchNextPage, refetch, isLoading } = useAnswersQuery(
    typeof groupId === 'number' ? { groupId } : { varianceId }
  )

  const actions = React.useMemo(
    () => ({
      fetch: (payload) => {
        fetchNextPage({
          pageParam: payload,
          cancelRefetch: false,
        })
      },
      refresh: (payload) => {
        refetch(payload)
      },
    }),
    [fetchNextPage, refetch]
  )

  const extraData = React.useMemo(
    () => ({
      hasMore: data.hasMore,
      isFetching: false,
      isRefreshing: isLoading,
    }),
    [data.hasMore, isLoading]
  )

  return (
    <FlatList
      actions={actions}
      component={Item}
      extraData={extraData}
      extraProps={{ shouldTranslate }}
      hasMore={extraData.hasMore}
      // This should be used but does not work correctly  hasMore={extraData.hasMore}
      isFetching={extraData.isFetching}
      isRefreshing={extraData.isRefreshing}
      items={data.answers}
      onEndReachedThreshold={0.5}
      renderHeader={(props) => (
        <Header
          group={data.group}
          onChangeShouldTranslate={setShouldTranslate}
          {...props}
        />
      )}
      style={{ height }}
    />
  )
}
