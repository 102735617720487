import {
  blue,
  cyan,
  geekblue,
  gold,
  green,
  grey,
  lime,
  magenta,
  purple,
  red,
  volcano,
  yellow,
} from '@ant-design/colors'
import {
  ARTIFICIAL_TELL_GROUP_TYPES,
  ARTIFICIAL_TELL_QUESTION_DEPTH,
  ARTIFICIAL_TELL_SENDER_HINT_TYPE,
  ARTIFICIAL_TELL_STATUS,
} from '@tellonym/enums/lib/Tell'

const c = {
  antdBackgroundElevated: 'rgb(250, 250, 250)',
  antdBackgroundElevatedBorder: 'rgb(240, 240, 240)',
  antdBreadcrumbInactive: 'rgba(0, 0, 0, 0.45)',
  appleBackground: '#EFEFF4',
  applePickerBackground: '#DEDEDE',
  appleBlue: '#006aff',
  appleInputs: '#efedf0',
  appleLines: '#CECED2',
  appleGrey: '#edecf2',
  appleRed: '#ff3b30',
  appleTooltip: '#333333',
  bermudagrey: '#78909C',
  black: '#000',
  blue: '#22A7CC',
  borderGrey: '#eeeeee',
  inactiveGrey: '#E0E0E0',
  borderLightGrey: '#f3f3f3',
  darkBlue: '#14171a',
  darkThemePrimary: '#19222d',
  darkThemePrimarySimilar: '#414b59',
  darkThemeSecondary: '#222c38',
  darkGrey: '#444444',
  darkerGrey: '#929292',
  evenDarkerGrey: '#818181',
  email: '#DD5144',
  facebook: '#3B5998',
  gray98: '#fafafa',
  grayishGreen: '#dff0d8',
  grayishGreenDarker: '#d6e9c6',
  grayishOrange: '#faebcc',
  grayishRed: '#f2dede',
  grayishRedDarker: '#ebccd1',
  grayishYellow: '#fcf8e3',
  green: '#54C08D',
  grey: '#464242',
  greyishBlack: '#4A4A4A',
  gray80: '#cccccc',
  instagram: '#bc2a8d',
  instagramText: '#393939',
  lightBlue: '#44c9ee',
  lightYellow: '#fff78a',
  darkYellow: '#b4ac61',
  lighterBlue: '#66daff',
  lightGrey: '#d5d5d5',
  lightThemePrimary: '#ffffff',
  lightThemePrimarySimilar: '#dfdfdf',
  lightThemeSecondary: '#cdcdcd',
  limeGreen: '#3c763d',
  moderateOrange: '#8a6d3b',
  moderateRed: '#a94442',
  nero: '#191919',
  niceBlue: '#20a8d8',
  niceBlueDark: '#187da0',
  niceLightBlue: '#63c2de',
  niceLightBlueDark: '#2eadd3',
  niceGreen: '#4dbd74',
  niceGreenDark: '#409e61',
  niceRed: '#f86c6b',
  niceRedDark: '#f5302e',
  niceYellow: '#ffc107',
  niceYellowDark: '#c69500',
  pink: '#FF005A',
  snapchat: '#ede904',
  translucentDark: 'rgba(0,0,0,.4)',
  translucentPink: 'rgba(255,68,134,.8)',
  translucentGrey: 'rgba(206,206,210,.8)',
  transparent: 'transparent',
  transparentWhite: 'rgba(255,255,255,0.7)',
  twitter: '#1da1f2',
  veryLightGreen: '#b3ffa5',
  veryLightGrey: '#fafafa',
  veryLightYellow: '#fff9a5',
  whatsapp: '#34A34F',
  white: '#ffffff',
  whitesmoke: '#f5f5f5',
  yellow: '#fff1c6',
  artTellSenderHint: {
    [ARTIFICIAL_TELL_SENDER_HINT_TYPE.NONE_DEFAULT]: lime[4],
    [ARTIFICIAL_TELL_SENDER_HINT_TYPE.NONE]: geekblue[4],
    [ARTIFICIAL_TELL_SENDER_HINT_TYPE.PRETEND_ORGANIC]: magenta[4],
    [ARTIFICIAL_TELL_SENDER_HINT_TYPE.REVEAL]: cyan[4],
  },
  artTellGroup: {
    [ARTIFICIAL_TELL_GROUP_TYPES.ORGANIC]: yellow[7],
    [ARTIFICIAL_TELL_GROUP_TYPES.SIMPLE]: volcano[3],
    [ARTIFICIAL_TELL_GROUP_TYPES.OPEN]: geekblue[3],
    [ARTIFICIAL_TELL_GROUP_TYPES.SHARE]: purple[3],
    [ARTIFICIAL_TELL_GROUP_TYPES.STATEMENT]: yellow[5],
  },
  artTellDepth: {
    [ARTIFICIAL_TELL_QUESTION_DEPTH.INTRO]: blue[3],
    [ARTIFICIAL_TELL_QUESTION_DEPTH.DEEP]: purple[3],
  },
  artTellStatus: {
    [ARTIFICIAL_TELL_STATUS.ACTIVE]: green[3],
    [ARTIFICIAL_TELL_STATUS.INACTIVE]: gold[3],
    [ARTIFICIAL_TELL_STATUS.DELETED]: red[3],
    [ARTIFICIAL_TELL_STATUS.NOT_LOCALIZED]: grey[1],
    [ARTIFICIAL_TELL_STATUS.VALIDATION]: blue[3],
  },
}

export const antdTagColors = [
  'blue',
  'geekblue',
  'green',
  'magenta',
  'orange',
  'purple',
  'red',
  'volcano',
  'orange',
  'gold',
  'lime',
  'cyan',
  'blue',
  'geekblue',
  'purple',
]

export const colors = {
  antdDarkModeBackground: '#1D1D1D',
  background: c.white,
  backgroundLight: c.borderLightGrey,
  border: c.appleLines,
  greyBackground: c.gray98,
  icon: c.darkerGrey,
  inputBackground: c.borderLightGrey,
  modalBackground: c.translucentDark,
  darkerBackground: c.borderLightGrey,
  placeholder: c.darkerGrey,
  primary: c.pink,
  socialLink: c.appleLines,
  text: c.darkBlue,
  F3F3F3: c.greyishBlack,
  textLight: c.darkerGrey,
  secondary: c.appleBlue,
  separator: c.appleLines,
  softGrey: c.borderLightGrey,
  ...c,
}
