import { yellow } from '@ant-design/colors'
import { Input, Select, Tooltip, Typography } from 'antd'
import React, { useState } from 'react'
import { Box, View } from '../../common'

const styles = {
  container: {
    flexDirection: 'row',
    alignItems: 'center',
  },
  input: { width: 180 },
  text: { marginRight: 12, width: 120 },
}

const defaultValidateValue = () => true

const TextInput = ({
  defaultValue,
  isDisabled,
  isOptional,
  onChangeText: _onChangeText,
  placeholder,
  validateValue = defaultValidateValue,
  width = styles.input.width,
}) => {
  const [value, setValue] = useState(defaultValue ?? '')
  const [isValueSupported, setIsValueSupported] = useState(true)

  const onChangeText = (e) => {
    const { value } = e.target

    setValue(value)

    const isValueSupported = value === '' ? true : validateValue(value)

    setIsValueSupported(isValueSupported)

    if (isValueSupported) {
      _onChangeText?.(value)
    }
  }

  return (
    <Tooltip
      color={yellow[6]}
      placement="right"
      title="unsupported value!"
      visible={value !== '' && !isValueSupported}>
      <Tooltip
        placement="right"
        title="required"
        visible={!isOptional && value === ''}>
        <Input
          disabled={isDisabled}
          onChange={onChangeText}
          placeholder={placeholder || (isOptional ? 'optional' : undefined)}
          value={value}
          style={{ width }}
        />
      </Tooltip>
    </Tooltip>
  )
}

const getDefaultValue = (hasMultipleValues, value) => {
  if (typeof value === 'undefined') {
    return []
  }

  if (hasMultipleValues) {
    return value.split(',')
  }

  return [value]
}

const SelectInput = ({
  defaultValue,
  hasMultipleValues,
  isDisabled,
  isOptional,
  onChangeText,
  options,
  placeholder,
  validateValue = defaultValidateValue,
}) => {
  const [value, setValue] = useState(
    getDefaultValue(hasMultipleValues, defaultValue)
  )
  const [isValueSupported, setIsValueSupported] = useState(true)

  const onChange = (values) => {
    const externalValue = hasMultipleValues
      ? values
      : values.slice(values.length - 1)
    const internalValue = hasMultipleValues
      ? values
      : values.slice(values.length - 1)

    setValue(internalValue)

    const isValueSupported =
      externalValue && externalValue !== '' && validateValue(externalValue)

    setIsValueSupported(isValueSupported)

    if (isValueSupported) {
      onChangeText?.(internalValue)
    }
  }

  return (
    <Tooltip
      color={yellow[6]}
      placement="right"
      title="unsupported value!"
      visible={value.length > 0 && !isValueSupported}>
      <Tooltip
        placement="right"
        title="required"
        visible={!isOptional && value.length === 0}>
        <Select
          maxTagCount={1}
          mode="tags"
          disabled={isDisabled}
          onChange={onChange}
          placeholder={placeholder || (isOptional ? 'optional' : undefined)}
          value={value}
          options={options}
          style={styles.input}
        />
      </Tooltip>
    </Tooltip>
  )
}

export const InputValidation = ({ text, onPressText, label, ...props }) => {
  const Component = Array.isArray(props.options) ? SelectInput : TextInput

  if (label) {
    return (
      <Box>
        <Typography.Text type="secondary" style={{ fontSize: 12 }}>
          {label}
        </Typography.Text>

        <Component {...props} />
      </Box>
    )
  }

  return (
    <View style={styles.container}>
      {typeof text === 'string' && (
        <Typography.Text strong onClick={onPressText} style={styles.text}>
          {text}
        </Typography.Text>
      )}

      <Component {...props} />
    </View>
  )
}
