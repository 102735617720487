import { CaretDownOutlined, CaretUpOutlined } from '@ant-design/icons'
import { colors } from '@tellonym/core/common/colorSystem'
import { shortenNumber } from '@tellonym/core/helpers'
import { ARTIFICIAL_TELL_USER_GROUP_TYPE } from '@tellonym/enums/lib/Tell'
import { Segmented, Table, Typography } from 'antd'
import React from 'react'
import * as ReactRedux from 'react-redux'
import { Box, Text } from '../../common'
import { valueToPercentage } from '../../statistics/services'
import { setDataMode } from '../actionsV2'
import { getDataMode } from '../selectorsV2'

const getTrendProps = (item, style) => {
  if (item.change === 0) {
    return {
      prefix: null,
    }
  }

  // Determine if trend should be shown as positive or negative
  const showAsPositive = item.change > 0

  // Use up arrow for positive trend, down arrow for negative
  const Icon = showAsPositive ? CaretUpOutlined : CaretDownOutlined
  const color = showAsPositive ? colors.green[8] : colors.red[1]

  return {
    prefix: <Icon style={{ color, ...style }} />,
  }
}

const ChangePercentageCell = ({ item, record }) => {
  const isPercentage = record.text.includes('Rate')

  const { prefix } = getTrendProps(item)

  const changeString = isPercentage
    ? valueToPercentage(item.change, 1).replace('.0', '')
    : shortenNumber(item.change)

  return (
    <Text type="micro">
      {prefix}
      <Text type="micro" style={{ marginLeft: 2 }}>
        {changeString}
      </Text>
    </Text>
  )
}

const DataCell = ({ item, record }) => {
  const isPercentage = record.text.includes('Rate')

  const amountValue = item?.amount ?? item?.percentage ?? item

  const amountString = isPercentage
    ? valueToPercentage(amountValue, 1).replace('.0', '')
    : shortenNumber(amountValue)

  return (
    <Text type="small" style={{ marginRight: 4 }}>
      {amountString}
    </Text>
  )
}

const MedianDataOverview = ({ backgroundColor, texts, values, isLoading }) => {
  const dataset = texts.map((text, index) => ({
    text,
    value: values[index],
  }))

  const onCell = () => ({
    style: {
      backgroundColor,
      paddingTop: 2,
      paddingBottom: 2,
      paddingRight: 2,
      paddingLeft: 2,
    },
  })

  const columns = [
    {
      dataIndex: 'text',
      onCell,
      render: (text) => <span>{text}</span>,
    },
    {
      dataIndex: 'value',
      align: 'right',
      onCell,
      render: (value, record) => <DataCell item={value} record={record} />,
    },
    {
      dataIndex: 'value',
      key: 'change',
      onCell,
      render: (value, record) => (
        <ChangePercentageCell item={value} record={record} />
      ),
    },
  ]

  return (
    <Table
      loading={isLoading}
      dataSource={dataset}
      columns={columns}
      bordered={false}
      pagination={false}
      showHeader={false}
      rowKey="text"
    />
  )
}

export const MedianDataForDataMode = ({
  backgroundColor,
  extra,
  hasTitle,
  isLoading = false,
  onChange,
  data = [],
  tableWidth,
  ...props
}) => {
  const dispatch = ReactRedux.useDispatch()

  const dataModeNumber = ReactRedux.useSelector(getDataMode)

  const onChangeDataMode = React.useCallback(
    (value) => {
      dispatch(setDataMode(ARTIFICIAL_TELL_USER_GROUP_TYPE[value]))
      onChange(value)
    },
    [dispatch, onChange]
  )

  return (
    <Box alignItems="center" transparent {...props}>
      <Box
        transparent
        alignItems="center"
        flexDirection="row"
        marginBottom={12}>
        {hasTitle && (
          <Typography.Title
            level={5}
            style={{ marginBottom: 0, marginRight: 24 }}>
            Data MODE:
          </Typography.Title>
        )}

        <Segmented
          value={ARTIFICIAL_TELL_USER_GROUP_TYPE[dataModeNumber]}
          options={[
            ARTIFICIAL_TELL_USER_GROUP_TYPE[2],
            ARTIFICIAL_TELL_USER_GROUP_TYPE[0],
            ARTIFICIAL_TELL_USER_GROUP_TYPE[3],
            ARTIFICIAL_TELL_USER_GROUP_TYPE[4],
          ]}
          onChange={onChangeDataMode}
          size="small"
        />

        {extra}
      </Box>

      {data.map((item, index) => (
        <Box transparent key={index} width={tableWidth ?? '100%'}>
          {typeof title === 'string' && (
            <Typography.Title level={5} style={{ marginBottom: 0 }}>
              {item.title}
            </Typography.Title>
          )}
          <MedianDataOverview
            key={index}
            texts={item.texts}
            values={item.values}
            isLoading={isLoading}
            backgroundColor={backgroundColor}
          />
        </Box>
      ))}
    </Box>
  )
}
