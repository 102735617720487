import React from 'react'
import * as ReactRedux from 'react-redux'
import { ContentContainer, Input, Text, theme, View } from '../../common'
import { refreshSpamScore } from '../actions'
import { getIsRefreshingSpamScore, getSpamScoreData } from '../selectors'

const SpamInput = () => {
  const [str, setStr] = React.useState('')

  const searchInputRef = React.useRef(null)

  const dispatch = ReactRedux.useDispatch()

  React.useEffect(() => {
    searchInputRef.current.focus()
  }, [])

  const handleSubmit = (e) => {
    e.preventDefault()

    dispatch(refreshSpamScore({ str }))
  }

  return (
    <View
      style={{
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'space-between',
        backgroundColor: theme.colors.background,
        borderRadius: 25,
        boxShadow: theme.styles.shadow,
        marginTop: 48,
        width: '100%',
      }}>
      <View
        style={{
          flexDirection: 'row',
          alignItems: 'center',
          paddingVertical: 12,
          flex: 1,
        }}>
        <form
          onSubmit={handleSubmit}
          style={{ flex: 1, marginLeft: 24, marginRight: 12 }}>
          <Input
            forwardRef={searchInputRef}
            placeholder="Input Tell here"
            onChange={(e) => {
              setStr(e.target.value)
            }}
            value={str}
            style={{
              borderBottom: 'none',
              marginBottom: 0,
              width: '100%',
            }}
          />
        </form>
      </View>
    </View>
  )
}

const ResultField = ({ style, ...props }) => (
  <Text
    style={[
      {
        flex: 1,
        marginTop: 5,
      },
      style,
    ]}
    {...props}
  />
)

const SpamScoreResult = ({ data }) => (
  <View
    style={{
      backgroundColor: theme.colors.background,
      flexDirection: 'column',
      borderWidth: 0,
      borderBottomWidth: 0,
      borderColor: theme.colors.borderLightGrey,
      borderStyle: 'solid',
      paddingVertical: 12,
      alignItems: 'left',
      marginLeft: 12,
    }}>
    <ResultField>Score: {data.score}</ResultField>
    <ResultField>Factor: {data.factor.toPrecision(2)}</ResultField>
    <ResultField>Found Words: {data.foundWords.join(',')}</ResultField>
    <ResultField>Would be blocked?: {data.result.toString()}</ResultField>
  </View>
)

export const PageSpamScore = () => {
  const data = ReactRedux.useSelector(getSpamScoreData)
  const isRefreshing = ReactRedux.useSelector(getIsRefreshingSpamScore)

  return (
    <ContentContainer>
      <View
        style={{
          maxWidth: 1200,
          alignSelf: 'center',
          flex: 1,
          width: '90%',
        }}>
        <SpamInput />
        <View
          style={{
            flexDirection: 'row',
            borderWidth: 0,
            borderBottomWidth: 0,
            borderColor: theme.colors.borderLightGrey,
            borderStyle: 'solid',
            paddingVertical: 12,
            alignItems: 'center',
          }}>
          {isRefreshing ? (
            <Text>Refreshing...</Text>
          ) : data.str !== '' ? (
            <SpamScoreResult data={data} />
          ) : null}
        </View>
      </View>
    </ContentContainer>
  )
}
