import { CountryPhoneCodes } from '@tellonym/core/constants/CountryPhoneCodes'
import { adjust, concat, join, split, toLower, toUpper } from 'ramda'

export const camelizeString = (str) => {
  if (!str.length) {
    return ''
  }

  const substrings = split(' ', toLower(str))

  return substrings.reduce((substring, word, index) => {
    // dont camelize first substring
    if (word.length && index !== 0) {
      const newWord = join('', adjust(0, toUpper, word))

      return concat(substring, newWord)
    }

    return concat(substring, word)
  }, '')
}

export const getLangFlag = (lang) => {
  switch (lang) {
    case 'ar':
      return CountryPhoneCodes.find((c) => c.code === 'SA') ?? {}

    case 'en':
      return CountryPhoneCodes.find((c) => c.code === 'US') ?? {}

    case 'hi':
      return CountryPhoneCodes.find((c) => c.code === 'IN') ?? {}

    default:
      return CountryPhoneCodes.find((c) => c.code === lang.toUpperCase()) ?? {}
  }
}
