import { prop } from 'ramda'
import { createSelector } from 'reselect'

const getState = prop('tools')

export const getAnswers = createSelector(getState, prop('answers'))

export const getIsRefreshingAnswers = createSelector(
  getState,
  prop('isRefreshingAnswers')
)

const getBadwordscore = createSelector(getState, prop('badwordscore'))

export const getBadwordscoreData = createSelector(getBadwordscore, prop('data'))

const getExperiments = createSelector(getState, prop('experiments'))

export const getIsLoadingRerollExperiments = createSelector(
  getExperiments,
  prop('isLoadingReroll')
)

const getSpamScore = createSelector(getState, prop('spamScore'))

export const getSpamScoreData = createSelector(getSpamScore, prop('data'))

export const getIsRefreshingSpamScore = createSelector(
  getSpamScore,
  prop('isRefreshing')
)
