import { useQueryClient } from '@tanstack/react-query'
import { events } from '@tellonym/core/events'
import {
  ARTIFICIAL_TELL_STATUS,
  ARTIFICIAL_TELL_TARGET_GENDER,
} from '@tellonym/enums/lib/Tell'
import { Grid, message, Typography } from 'antd'
import React from 'react'
import * as ReactRedux from 'react-redux'
import { _, AntdModal } from '../../common'
import { refreshGroup, refreshTopic } from '../actionsV2'
import {
  artellv2 as artellv2QueryKeys,
  fetchVarianceGeneration,
  useVarianceGenerationQuery,
  useVarianceGenerationRegenerateMutation,
} from '../queries'
import { CREATE_VARIANCES_SUCCESS } from '../typesV2'
import { VarianceGenerationBody } from './VarianceGenerationBody'

const getGenderIsLoadingMap = () => ({
  [ARTIFICIAL_TELL_TARGET_GENDER.BOTH]: false,
  [ARTIFICIAL_TELL_TARGET_GENDER.MALE]: false,
  [ARTIFICIAL_TELL_TARGET_GENDER.FEMALE]: false,
})

/**
 * This modal receives either one groupId or a topicId and gets all the eligible groups from it.
 */
const ModalVarianceGeneratorComponent = ({
  closeModal,
  language,
  groupId,
  topicId,
  updateProps,
}) => {
  const dispatch = ReactRedux.useDispatch()
  const screens = Grid.useBreakpoint()
  const queryClient = useQueryClient()

  const [currentGroupIndex, setCurrentGroupIndex] = React.useState(0)

  const [genderIsLoadingMap, setGenderIsLoadingMap] = React.useState(
    getGenderIsLoadingMap()
  )

  const allGroups = ReactRedux.useSelector(
    (state) => state.artificialTellsV2.topicDetails?.[topicId]?.groups ?? []
  )

  const oneGroup = ReactRedux.useSelector(
    (state) => state.artificialTellsV2.groupDetails?.[groupId]
  )

  const inactiveGroupsWithFewVariances = groupId
    ? [oneGroup]
    : allGroups.filter(
        (group) =>
          group.status === ARTIFICIAL_TELL_STATUS.INACTIVE &&
          group.inactiveVariancesCount < 4
      )

  const group = inactiveGroupsWithFewVariances[currentGroupIndex] ?? {}

  const { data: resultVariances, isFetching } = useVarianceGenerationQuery({
    language,
    groupId: group?.id,
  })

  const { mutate: regenerateVariances } =
    useVarianceGenerationRegenerateMutation()

  const onChangePagination = (page) => {
    if (page > inactiveGroupsWithFewVariances.length) {
      message.success('All groups done 🎉')
      closeModal()
    } else {
      setCurrentGroupIndex(page - 1)
      setGenderIsLoadingMap(getGenderIsLoadingMap())
    }
  }

  const onPressRegenerate = (gender) => {
    setGenderIsLoadingMap((genderIsLoadingMap) => ({
      ...genderIsLoadingMap,
      [gender]: true,
    }))

    const resetIsLoading = () => {
      setGenderIsLoadingMap((genderIsLoadingMap) => ({
        ...genderIsLoadingMap,
        [gender]: false,
      }))
    }

    regenerateVariances(
      {
        language,
        groupId: group.id,
        gender,
        prevResponseQuestions: resultVariances?.generatedVariances[gender],
      },
      {
        onSettled: resetIsLoading,
      }
    )
  }

  React.useEffect(() => {
    updateProps({
      width: screens?.xxl ? '65%' : '75%',
      style: { top: 12 },
    })
  }, [screens])

  /**
   * Shows a success message when the variances are created and updates the topic so the user can see the changes
   */
  React.useEffect(() => {
    const subscription = events.addListener(CREATE_VARIANCES_SUCCESS, () => {
      message.success('Variances created successfully')
    })

    if (!group.id) {
      message.info('No groups found with few variances')

      closeModal()
    }

    return () => {
      events.clearListener(subscription)

      if (topicId) {
        dispatch(refreshTopic({ topicId }))
      } else if (groupId) {
        dispatch(refreshGroup({ groupId }))
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  /**
   * Prefetches the data for the next group
   */
  React.useEffect(() => {
    if (currentGroupIndex + 1 < inactiveGroupsWithFewVariances.length) {
      const payload = {
        language,
        groupId: inactiveGroupsWithFewVariances[currentGroupIndex + 1].id,
      }

      queryClient.prefetchQuery({
        queryKey: artellv2QueryKeys.varianceGeneration(payload),
        queryFn: fetchVarianceGeneration(payload),
      })
    }
  }, [currentGroupIndex])

  if (inactiveGroupsWithFewVariances.length === 0) {
    return null
  }

  return (
    <VarianceGenerationBody
      closeModal={closeModal}
      currentGroupIndex={currentGroupIndex}
      existingVariances={resultVariances?.originalVariances}
      footer={
        <Typography.Paragraph style={{ marginBottom: 4, textAlign: 'center' }}>
          If using BOTH, add at least 3 variances. For MALE or FEMALE, add at
          least 2 each. Max 10 variances total.
        </Typography.Paragraph>
      }
      generatedVariances={resultVariances?.generatedVariances}
      genderIsLoadingMap={genderIsLoadingMap}
      group={group}
      groupsAmount={inactiveGroupsWithFewVariances.length}
      isLoading={isFetching}
      language={language}
      onChangePagination={onChangePagination}
      onPressRegenerate={onPressRegenerate}
    />
  )
}

const show = (payload) =>
  AntdModal.show({
    render: (props) => (
      <ModalVarianceGeneratorComponent {...payload} {...props} />
    ),
    closable: false,
    maskClosable: false,
    footer: null,
  })

const shouldShow = ({ topicId }) => {
  const allGroups = _.getState(
    (state) => state.artificialTellsV2.topicDetails?.[topicId]?.groups ?? []
  )

  const inactiveGroupsWithFewVariances = allGroups.filter(
    (group) =>
      group.status === ARTIFICIAL_TELL_STATUS.INACTIVE &&
      group.inactiveVariancesCount < 4
  )

  return inactiveGroupsWithFewVariances.length > 0
}

export const ModalVarianceGenerator = { show, shouldShow }
