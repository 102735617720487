import { copyToClipboard } from '@tellonym/core/share/actions'
import dayjs from 'dayjs'
import React from 'react'
import * as ReactRedux from 'react-redux'
import { Button, Modal, Spinner, Text, theme, View } from '../../common'
import { useGdprExportQuery } from '../../profile/queries'
import { generateGdprExport } from '../actions'

export const dataExportToUrl = (dataExport) =>
  `https://api.tellonym.me/accounts/export/${dataExport?.hash}`

const GdprExport = ({ modalId, userId }) => {
  const dispatch = ReactRedux.useDispatch()

  const [isComplete, setIsComplete] = React.useState(false)

  const dateRef = React.useRef(dayjs())

  const { data, refetch } = useGdprExportQuery({ userId })

  React.useEffect(() => {
    const pollData = async () => {
      if (isComplete) {
        return
      }

      const result = await refetch()

      if (
        result.data.dataExport.filename &&
        dayjs(result.data.dataExport.date).isAfter(dateRef.current)
      ) {
        setIsComplete(true)
      }
    }

    const interval = setInterval(pollData, 2000)

    return () => clearInterval(interval)
  }, [isComplete, refetch])

  React.useEffect(() => {
    dispatch(generateGdprExport({ userId }))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const link = dataExportToUrl(data?.dataExport)

  return (
    <View style={{ flex: 1, justifyContent: 'center', alignItems: 'center' }}>
      <Modal.Body>
        <View
          style={{
            borderRadius: 25,
            backgroundColor: theme.colors.background,
            padding: 32,
            width: 500,
            minHeight: 150,
          }}>
          <Text type="h2" bold style={{ marginBottom: 12 }}>
            Generate GDPR Export
          </Text>
          <View
            style={{
              flex: 1,
              alignItems: 'center',
              padding: 16,
            }}>
            <View style={{ flex: 1 }}>
              {isComplete === false ? (
                <View style={{ alignItems: 'center' }}>
                  <Spinner />
                </View>
              ) : (
                <>
                  <a href={link}>{link}</a>
                  <Button
                    onPress={() => dispatch(copyToClipboard(link))}
                    style={[{ flex: 1, alignSelf: 'center', marginTop: 8 }]}>
                    Copy
                  </Button>
                </>
              )}
              <Button
                onPress={() => Modal.hide({ id: modalId })}
                style={[{ flex: 1, alignSelf: 'center', marginTop: 24 }]}>
                Close
              </Button>
            </View>
          </View>
        </View>
      </Modal.Body>
    </View>
  )
}

const show = (payload) =>
  Modal.show({
    render: (props) => <GdprExport {...payload} {...props} />,
    hasBackdrop: true,
    shouldHideOnBackgroundPress: true,
  })

export const ModalGdprExport = { show }
