import React from 'react'

const DarkTheme = React.lazy(() => import('./DarkTheme'))

export const ThemeProvider = ({ children }) => {
  return (
    <>
      <React.Suspense fallback={<span />}>
        {window.tnym.isDarkModeEnabled ? <DarkTheme /> : null}
      </React.Suspense>
      {children}
    </>
  )
}
