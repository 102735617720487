// @flow

import { Card, Col, Row } from 'antd'
import React from 'react'
import { _, Avatar, Box, moment, styleSheets, Text } from '../../common'
import { IconVerified } from '../../common/components/IconVerified'
import { UserTypeBadge } from './UserTypeBadge'

export const SearchItem = ({ profile }) => {
  return (
    <Row style={styleSheets.margin.bottom[12]}>
      <Col
        xs={{ span: 20, offset: 2 }}
        lg={{ span: 16, offset: 4 }}
        xl={{ span: 12, offset: 6 }}>
        <Card {..._.openRoutePropsAnt(`/user/${profile.id}`)}>
          <Card.Meta
            avatar={<Avatar user={profile} />}
            title={
              <Box
                flexDirection="row"
                alignItems="center"
                justifyContent="space-between">
                <Box flexDirection="row" alignItems="center">
                  <Text semibold>{profile.displayName}</Text>
                  {profile.isVerified && (
                    <IconVerified style={styleSheets.margin.left[4]} />
                  )}
                </Box>
                <UserTypeBadge type={profile.type} />
              </Box>
            }
            description={`@${profile.username} - last active ${moment(
              profile.lastActiveAllAt
            ).fromNow()}`}
          />
        </Card>
      </Col>
    </Row>
  )
}
