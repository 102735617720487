import { colors } from '@tellonym/core/common/colorSystem'
import React from 'react'
import { RNImage } from './Image'

export const IconVerified = (props = {}) => {
  return (
    <RNImage
      source="/assets/img/verified.png"
      style={{
        tintColor: props.tintColor ?? colors.modcp[2],
        width: props.size ?? 14,
        height: props.size ?? 14,
        ...props.style,
      }}
    />
  )
}
