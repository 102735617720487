import { DualAxes, Line } from '@ant-design/plots'
import { Modal, Select, Tag } from 'antd'
import equals from 'fast-deep-equal'
import React from 'react'
import { View, styleSheets } from '../../common'
import { useQueryParams } from '../../common/hooks'
import {
  comparisonIntervalOptions,
  defaultValueKeys as rowValueKeys,
} from '../constants'
import { useGraphDataQuery } from '../queries'
import {
  camelToSentenceCase,
  dateTypeToTimespan,
  getDisplayValue,
} from '../services'
import { TimeFramePicker } from './TimeFramePicker'

const styles = {
  tooltipSmallChart: {
    marginTop: 4,
    marginBottom: 4,
  },
}

const buildChartData = ({ metric, metrics, graphData, compareKeys = [] }) => {
  const result = graphData.ids.reduce(
    (acc, key) => {
      const hasAmountActions =
        typeof graphData.data[key]?.amountActions?.value !== 'undefined'
      const hasAmountUniques =
        typeof graphData.data[key]?.amountUniques?.value !== 'undefined'

      if (!hasAmountActions && !hasAmountUniques) {
        return acc
      }

      /**
       * When multiple metrics are defined we build a dataset with those. If only one metric
       * is defined we add the selected compare keys to the dataset.
       */
      if (Array.isArray(metrics)) {
        const metricsData = {
          date: key,
        }

        if (hasAmountActions) {
          metricsData.value = graphData.data[key].amountActions.value
          metricsData.metric = 'amountActions'
        } else if (hasAmountUniques) {
          metricsData.value = graphData.data[key].amountUniques.value
          metricsData.metric = 'amountUniques'
        }

        acc.dataset.push(metricsData)
      } else {
        const currData = graphData.data[key][metric]

        acc.dataset.push({
          date: key,
          value: currData.value,
          kind: 'Selected Range',
        })

        compareKeys.forEach((compareKey) => {
          acc.dataset.push({
            date: key,
            value: currData.change[compareKey].value,
            kind: `${camelToSentenceCase(compareKey)} before`,
          })
        })
      }

      return acc
    },

    { dataset: [] }
  )

  return result
}

const inlineChartConfig = {
  xField: 'date',
  yField: ['value', 'value'],
  padding: 'auto',
  appendPadding: 4,
  height: 80,
  width: 240,
  smooth: true,
  legend: false,
  lineStyle: {
    lineWidth: 1,
  },
  xAxis: {
    line: null,
    label: null,
  },
  yAxis: {
    value: {
      label: null,
      grid: null,
    },
  },
}

const arePropsEqualInlineChart = (prev, next) => {
  const isExpandEqual = prev.record?.isExpanded === next.record?.isExpanded
  const isObjectEqual = equals(prev.record, next.record)

  return isExpandEqual && isObjectEqual
}

const InlineChart = React.memo(({ record }) => {
  const ref = React.useRef(null)
  const dataset = React.useMemo(
    () =>
      buildChartData({
        metrics: ['value'],
        graphData: { ids: Object.keys(record), data: record },
      }).dataset,
    [record]
  )

  const tooltipConfig = React.useMemo(
    () => ({
      showTitle: false,
      showCrosshairs: false,
      // eslint-disable-next-line react/no-unstable-nested-components
      customContent: (_, data) => {
        if (!data?.[0]) return null
        const { name, value, metric } = data[0].data
        const displayValue = getDisplayValue(
          Number(value),
          name === 'percentageOfUsers'
        )
        const key = metric === 'amountActions' ? 'Actions' : 'Uniques'

        return (
          <View
            style={styles.tooltipSmallChart}>{`${key}: ${displayValue}`}</View>
        )
      },
    }),
    []
  )

  const [minRange, maxRange] = React.useMemo(() => {
    if (!dataset || dataset?.length === 0) {
      return [0, 0]
    }

    const latestValue = dataset[dataset.length - 1].value
    const maxValue = Math.max(...dataset.map((d) => d.value))
    const minValue = Math.min(...dataset.map((d) => d.value).filter(Boolean))
    const percentFromMax = ((maxValue - latestValue) / maxValue) * 100
    const percentFromMin = ((latestValue - minValue) / minValue) * 100

    const minRange = (() => {
      switch (true) {
        case percentFromMin < 10:
          return latestValue * 0.9
        case percentFromMin < 20:
          return latestValue * 0.8
        case percentFromMin < 30:
          return latestValue * 0.7
        case percentFromMin < 40:
          return latestValue * 0.8
        default:
          return latestValue * 0.5
      }
    })()

    const maxRange = (() => {
      switch (true) {
        case percentFromMax < 10:
          return latestValue * 1.1
        case percentFromMax < 20:
          return latestValue * 1.2
        case percentFromMax < 30:
          return latestValue * 1.3
        case percentFromMax < 40:
          return latestValue * 1.4
        default:
          return latestValue * 1.5
      }
    })()

    return [minRange, maxRange]
  }, [dataset])

  if (dataset === 0) {
    return (
      <Tag color="red" style={styleSheets.alignSelf.center}>
        No data available
      </Tag>
    )
  }

  return (
    <View
      style={{
        maxWidth: inlineChartConfig.width,
        height: inlineChartConfig.height,
      }}>
      <DualAxes
        ref={ref}
        data={[dataset, dataset]} // data needs to be 2 to make dual axis work
        tooltip={tooltipConfig}
        {...inlineChartConfig}
        yAxis={{
          ...inlineChartConfig.yAxis,
          value: {
            ...inlineChartConfig.yAxis.value,
            min: minRange,
            max: maxRange,
          },
        }}
      />
    </View>
  )
}, arePropsEqualInlineChart)

const lineConfig = {
  padding: 'auto',
  xField: 'date',
  yField: 'value',
  seriesField: 'kind',
}

const rowValueOptions = rowValueKeys.map((v) => ({
  label: camelToSentenceCase(v),
  value: v,
}))

const ModalGraphContent = ({ shortName }) => {
  const [queryParams] = useQueryParams()
  const [intervalType, setIntervalType] = React.useState(
    Number(queryParams.intervalType)
  )
  const [dateType, setDateType] = React.useState(queryParams.dateType)
  const [dates, setDates] = React.useState([
    queryParams.startDate,
    queryParams.endDate,
  ])
  const [compareKeys, setCompareKeys] = React.useState([])
  const [graphMetric, setGraphMetric] = React.useState(rowValueKeys[0])

  const params = React.useMemo(() => {
    const { endDate, timespan } =
      dateTypeToTimespan({
        dateType,
        startDate: dates[0],
        endDate: dates[1],
      }) ?? {}

    return {
      ...queryParams,
      startDate: dates[0],
      endDate,
      intervalType,
      shortName,
      timespan,
    }
  }, [queryParams, intervalType, dateType, dates, shortName])

  const { data: graphData, isFetching } = useGraphDataQuery(params)

  const timePickerDefaults = React.useMemo(
    () => ({
      dateType,
      selectedInterval: intervalType,
      startDate: dates[0],
      endDate: dates[1],
    }),
    [intervalType, dateType, dates]
  )

  const tooltip = React.useMemo(
    () => ({
      formatter: ({ value, kind }) => ({
        name: kind,
        value: getDisplayValue(value, graphMetric === 'percentageOfUsers'),
      }),
    }),
    [graphMetric]
  )

  const dataset = React.useMemo(() => {
    return buildChartData({ metric: graphMetric, graphData, compareKeys })
      .dataset
  }, [graphData, graphMetric, compareKeys])

  const handleMetricChange = (graphMetric) => {
    setGraphMetric(graphMetric)
  }

  const onChangeCompareKey = React.useCallback((values) => {
    setCompareKeys(values)
  }, [])

  const stopPropagation = (e) => {
    e.stopPropagation()
  }

  const onChangeDateType = (dateType) => {
    setDateType(dateType)
  }

  const onChangeInterval = (intervalType) => {
    setIntervalType(intervalType)
  }

  const onChangeDates = (dates) => {
    setDates(dates)
    setDateType(dateType)
  }

  return (
    <View onPress={stopPropagation}>
      <TimeFramePicker
        isLoading={isFetching}
        defaults={timePickerDefaults}
        onChangeDateType={onChangeDateType}
        onChangeInterval={onChangeInterval}
        onChangeDates={onChangeDates}
      />
      <View
        style={[styleSheets.margin.bottom[24], styleSheets.flex.direction.row]}>
        <Select
          disabled={isFetching}
          onChange={handleMetricChange}
          value={graphMetric}
          options={rowValueOptions}
          style={styleSheets.margin.right[16]}
        />
        <Select
          allowClear
          disabled={isFetching}
          maxTagCount="responsive"
          mode="multiple"
          placeholder="Compare with"
          options={comparisonIntervalOptions}
          onChange={onChangeCompareKey}
          style={{ width: 260 }}
        />
      </View>
      <Line data={dataset} tooltip={tooltip} {...lineConfig} />
    </View>
  )
}

export const ModalGraphCh = ({
  width = 1000,
  title,
  isVisible,
  onClose,
  ...props
}) => (
  <Modal
    width={width}
    title={title}
    open={isVisible}
    onOk={onClose}
    onCancel={onClose}>
    <ModalGraphContent {...props} />
  </Modal>
)
export { InlineChart }
