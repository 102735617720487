import {
  blue,
  geekblue,
  green,
  grey,
  lime,
  magenta,
  red,
  yellow,
} from '@ant-design/colors'
import { colors } from '@tellonym/core/common/colorSystem'
import { intHash } from '@tellonym/core/helpers'
import { PERFORMANCE_TYPE } from '@tellonym/enums/lib/TellArtificial'
import {
  Button,
  Card,
  Divider,
  Empty,
  Input,
  List,
  Select,
  Tooltip,
  message,
} from 'antd'
import dayjs from 'dayjs'
import React from 'react'
import * as ReactRedux from 'react-redux'
import { getPermissions } from '../../app/selectors'
import { Avatar, Box, ScrollView, Text, helpers, hooks } from '../../common'
import { colors as avatarColors } from '../../common/components/Avatar'
import { TagBox } from '../../common/components/TagBox'
import { languageEnumAsString } from '../../common/helpers'
import {
  useAddPerformanceCheckInMutation,
  usePerformanceCheckInsQuery,
} from '../queries'

const performanceTypeConfig = {
  [PERFORMANCE_TYPE.OPPORTUNITY]: {
    color: colors.green[8],
  },
  [PERFORMANCE_TYPE.COMMENT]: {
    color: yellow[5],
  },
  [PERFORMANCE_TYPE.MONITOR]: {
    color: colors.yellow[1],
  },
  [PERFORMANCE_TYPE.CHANGELOG_GROUP_ACTIVATE]: {
    color: green[3],
  },
  [PERFORMANCE_TYPE.CHANGELOG_GROUP_DEACTIVATE]: {
    color: red[3],
  },
  [PERFORMANCE_TYPE.CHANGELOG_GROUP_DEPTH]: {
    color: lime[6],
  },
  [PERFORMANCE_TYPE.CHANGELOG_GROUP_DESCRIPTION]: {
    color: lime[7],
  },
  [PERFORMANCE_TYPE.CHANGELOG_GROUP_NAME]: {
    color: lime[3],
  },
  [PERFORMANCE_TYPE.CHANGELOG_GROUP_SENDER_HINT_TYPE]: {
    color: lime[4],
  },
  [PERFORMANCE_TYPE.CHANGELOG_GROUP_TYPE]: {
    color: lime[5],
  },
  [PERFORMANCE_TYPE.CHANGELOG_GROUP_TOPIC_CHANGE]: {
    color: lime[8],
  },
  [PERFORMANCE_TYPE.CHANGELOG_VARIANCE_CREATE]: {
    color: blue[3],
  },
  [PERFORMANCE_TYPE.CHANGELOG_VARIANCE_ACTIVATE]: {
    color: green[3],
  },
  [PERFORMANCE_TYPE.CHANGELOG_VARIANCE_DEACTIVATE]: {
    color: red[3],
  },
  [PERFORMANCE_TYPE.CHANGELOG_VARIANCE_GENDER]: {
    color: magenta[2],
  },
  [PERFORMANCE_TYPE.CHANGELOG_REASKABILITY]: {
    color: blue[2],
  },
}

const performanceTypeOptions = [
  PERFORMANCE_TYPE.COMMENT,
  PERFORMANCE_TYPE.MONITOR,
  PERFORMANCE_TYPE.OPPORTUNITY,
].map((value) => ({
  value,
  label: PERFORMANCE_TYPE[value].replace('CHANGELOG_', ''),
}))

export const ListItem = ({ item, isActivity = false }) => {
  const factor = isActivity ? 2 : 1

  return (
    <>
      <Tooltip title={item.kpiText} placement="right">
        <Box
          transparent
          flexDirection="row"
          marginTop={4 * factor}
          alignItems="center">
          {isActivity && Boolean(item.topicName) && (
            <Box marginRight={6} marginTop={2}>
              <TagBox
                note
                width={180}
                text={item.topicName}
                backgroundColor={geekblue[2]}
              />
            </Box>
          )}

          {isActivity && Boolean(item.groupName) && (
            <Box marginRight={6} marginTop={2}>
              <TagBox
                note
                width={180}
                text={item.groupName}
                backgroundColor={geekblue[3]}
              />
            </Box>
          )}

          <Box marginRight={6} marginTop={2}>
            <TagBox
              note
              width={22.5}
              text={languageEnumAsString[item.language]?.toUpperCase()}
              backgroundColor={
                avatarColors[
                  intHash(languageEnumAsString[item.language]) %
                    avatarColors.length
                ]
              }
            />
          </Box>

          <Box marginRight={6} marginTop={2}>
            <TagBox
              note
              text={
                PERFORMANCE_TYPE[item.performanceType]?.replace(
                  'CHANGELOG_',
                  ''
                ) ?? 'None'
              }
              backgroundColor={
                performanceTypeConfig[item.performanceType]?.color ?? grey[0]
              }
            />
          </Box>

          {item.amount > 1 && (
            <Box marginRight={6} marginTop={2}>
              {String(item.amount)}
            </Box>
          )}

          <Avatar
            size={isActivity ? 20 : 16}
            user={{
              username: item.creator?.username ?? '',
              avatarFileName: item.creator?.avatarFileName,
            }}
            style={{ marginRight: 3 * factor }}
          />

          <Text
            type={isActivity ? 'note' : 'micro'}
            semibold
            style={{
              flex: 1,
              overflow: 'hidden',
              textOverflow: 'ellipsis',
              whiteSpace: 'nowrap',
            }}>{`${item.creator?.username}`}</Text>

          <Text type="micro" color={colors.grey[7]}>
            {dayjs(item.createdAt).fromNow(!isActivity)}
          </Text>
        </Box>

        {Boolean(item.content) && (
          <Text type="note" style={{ marginTop: 4 * factor }}>
            {item.content}
          </Text>
        )}
      </Tooltip>

      <Divider
        style={{
          marginTop: 4 * factor,
          marginBottom: 0,
        }}
      />
    </>
  )
}

/**
 * Either groupId or topicId is defined
 */
export const PerformanceCheckIns = ({ groupId, topicId, language }) => {
  const inputRef = React.useRef(null)

  const [content, setContent] = React.useState('')

  const [selectedPerformanceType, setSelectedPerformanceType] = React.useState(
    PERFORMANCE_TYPE.COMMENT
  )

  const permissions = ReactRedux.useSelector(getPermissions)
  const isTranslator = helpers.checkIsTranslator(permissions)

  const { data, isLoading } = usePerformanceCheckInsQuery({
    groupId,
    topicId,
    language,
  })

  const { mutate, isLoading: isSubmitting } = useAddPerformanceCheckInMutation({
    groupId,
    topicId,
    language,
  })

  const onChangePerformanceType = (value) => {
    setSelectedPerformanceType(value)
  }

  const onSubmitNewEntry = () => {
    if (!selectedPerformanceType) {
      message.warn('Please select a performance type')

      return
    }

    mutate({
      groupId,
      topicId,
      language,
      content,
      performanceType: selectedPerformanceType,
    })

    setContent('')
  }

  hooks.useKeyboardShortcutToSubmit({
    inputRef,
    onSubmit: onSubmitNewEntry,
    hasActionKey: true,
  })

  hooks.useEscapeKey({
    inputRef,
    onPress: () => {
      inputRef.current.blur()
    },
  })

  return (
    <Box transparent justifyContent="space-between">
      <Card
        size="small"
        bodyStyle={{ paddingTop: 0, paddingBottom: 0 }}
        style={{ flex: 1 }}>
        <ScrollView
          contentContainerStyle={{ paddingTop: 4, paddingBottom: 12 }}
          style={{ height: 150 }}>
          <List
            loading={isLoading}
            locale={{
              emptyText: (
                <Empty
                  image={Empty.PRESENTED_IMAGE_SIMPLE}
                  description="No performance check-ins yet."
                  style={{ marginTop: 0, marginBottom: 0 }}
                />
              ),
            }}
            itemLayout="horizontal"
            dataSource={data?.items.ids ?? []}
            renderItem={(id) => (
              <ListItem key={id} item={data?.items.data[id]} />
            )}
          />
        </ScrollView>
      </Card>
      <Input.Group compact style={{ paddingTop: 8 }}>
        <Select
          className="select-after"
          disabled={isTranslator}
          options={performanceTypeOptions}
          onChange={onChangePerformanceType}
          value={selectedPerformanceType}
          dropdownMatchSelectWidth={false}
          style={{ width: 120, fontSize: 10 }}
        />

        <Input.TextArea
          ref={inputRef}
          size="small"
          autoSize={{ maxRows: 1 }}
          style={{ width: 'calc(100% - 120px - 45px)' }}
          defaultValue={content}
          onChange={(e) => setContent(e.target.value)}
          value={content}
          placeholder="Performance check-in entry..."
        />

        <Button
          type="bordered"
          onClick={onSubmitNewEntry}
          loading={isSubmitting}
          style={{ width: 45, padding: 4 }}>
          Add
        </Button>
      </Input.Group>
    </Box>
  )
}
