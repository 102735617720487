import { Breadcrumb } from 'antd'
import React from 'react'
import * as ReactRedux from 'react-redux'
import { Box, BoxSecondary, ScrollView, history, hooks } from '../../common'
import { updateLocationSearch } from '../../common/helpers'
import { refreshUnderperformingGroups } from '../actionsV2'
import { getLanguage } from '../selectorsV2'
import { ArtificialTellTopicGroupsTable } from './ArtificialTellTopicGroupsTable'
import { ButtonBack } from './ButtonBack'
import { MedianDataForDataMode } from './MedianDataForDataMode'
import { SelectorsLanguage } from './SelectorsLanguage'

const Header = () => {
  const dispatch = ReactRedux.useDispatch()

  const onChangeDataMode = () => {
    dispatch(refreshUnderperformingGroups())
  }

  return (
    <BoxSecondary padding={24}>
      <Box
        alignItems="center"
        transparent
        flexDirection="row"
        paddingBottom={24}>
        <Box
          flexDirection="row"
          justifyContent="center"
          alignItems="center"
          transparent>
          <ButtonBack
            onPress={() => {
              history.goBack()
            }}
            style={{ marginRight: 12, width: 30, height: 30 }}
          />

          <Breadcrumb>
            <Breadcrumb.Item key="Underperforming">
              Underperforming Groups
            </Breadcrumb.Item>
          </Breadcrumb>
        </Box>
      </Box>

      <Box flexDirection="row" justifyContent="space-between" transparent>
        <Box transparent />

        <Box transparent marginRight={48}>
          <MedianDataForDataMode
            data={[]}
            hasTitle
            onChange={onChangeDataMode}
          />
        </Box>

        <SelectorsLanguage />
      </Box>
    </BoxSecondary>
  )
}

export const PageArtificialTellsGroupsUnderperforming = () => {
  const dispatch = ReactRedux.useDispatch()

  const table = hooks.useTableState()

  const language = ReactRedux.useSelector(getLanguage)

  const artificialTellsV2 = ReactRedux.useSelector(
    (state) => state.artificialTellsV2
  )

  const { groups, dataMode } = artificialTellsV2.underperformingGroups

  React.useEffect(() => {
    updateLocationSearch(table)
  }, [])

  React.useEffect(() => {
    dispatch(refreshUnderperformingGroups())
  }, [language])

  return (
    <Box>
      <Header dataMode={dataMode} />
      <ScrollView>
        <ArtificialTellTopicGroupsTable
          {...table}
          dataSource={groups}
          language={language}
        />
      </ScrollView>
    </Box>
  )
}
