import { green } from '@ant-design/colors'
import { shortenNumber } from '@tellonym/core/helpers'
import { Table, Tooltip } from 'antd'
import React, { useMemo } from 'react'
import { Text, theme } from '../../common'

const MAX_DIFF = 0.03

const DIFF_STATES = {
  HIGHER: 'HIGHER',
  LOWER: 'LOWER',
}

const labelStyles = {
  color: theme.colors.placeholder,
  fontStyle: 'italic',
}

const styles = {
  cell: {
    borderBottomWidth: 0,
    paddingTop: 2,
    paddingBottom: 2,
    paddingLeft: 8,
    paddingRight: 8,
  },
  labelText: labelStyles,
  labelTextPrimary: {
    ...labelStyles,
    color: theme.colors.secondary,
  },
  valueText: { color: theme.colors.placeholder, float: 'right' },
  valueTextPrimary: { color: theme.colors.secondary, float: 'right' },
}

const getDiffState = ({ key, diff }) => {
  if (['answerPctShort'].includes(key)) {
    switch (true) {
      case diff < -MAX_DIFF:
        return DIFF_STATES.HIGHER

      case diff > MAX_DIFF:
        return DIFF_STATES.LOWER

      default:
        return undefined
    }
  }

  switch (true) {
    case key === 'amountSent':
      return undefined

    case diff > MAX_DIFF:
      return DIFF_STATES.HIGHER

    case diff < -MAX_DIFF:
      return DIFF_STATES.LOWER

    default:
      return undefined
  }
}

const getColorForDiffState = (state) => {
  switch (state) {
    case DIFF_STATES.HIGHER:
      return green.primary

    case DIFF_STATES.LOWER:
      return theme.colors.appleRed

    default:
      return undefined
  }
}

const getRenderDiff = (diff, record) => {
  const size = 8
  const diffState = getDiffState({ key: record.key, diff })
  const color = getColorForDiffState(diffState)

  if (!Object.values(DIFF_STATES).includes(diffState)) {
    return null
  }

  const changeString = diffState.toLocaleLowerCase()

  return (
    <Tooltip
      title={`The value is ${changeString} than the group average: ${(
        diff * 100
      ).toFixed(2)}%`}>
      <div
        style={{
          width: size,
          height: size,
          backgroundColor: color,
          borderRadius: size / 2,
        }}
      />
    </Tooltip>
  )
}

const getRenderValue = (text, record) => {
  return (
    <Text
      type="note"
      style={
        record.key === 'answerRate' ? styles.valueTextPrimary : styles.valueText
      }>
      {text}
    </Text>
  )
}

const getRenderLabel = (text, record) => {
  return (
    <Text
      type="note"
      style={
        record.key === 'answerRate' ? styles.labelTextPrimary : styles.labelText
      }>
      {text}
    </Text>
  )
}

export const ArtificialTellStats = ({
  items = {},
  groupStats,
  onPress,
  type = 'note',
  style,
}) => {
  const readablePercentage = (percentage) =>
    percentage
      ? `${(percentage * 100).toFixed(type === 'micro' ? 1 : 2)}%`
      : type === 'micro'
      ? '0.0%'
      : '0.00%'

  const readableTime = (num) => shortenNumber(num, type === 'micro' ? 0 : 1)

  const supportedItems = {
    amountSent: { text: 'times sent', transformer: readableTime },
    longAnswers: { text: 'long answers', transformer: readablePercentage },
    answerPctLong: { text: 'long answers', transformer: readablePercentage },
    amountTells: { text: 'entries' },
    amountDeclined: { text: 'declined' },
    amountDrafts: { text: 'drafts' },
    totalSent: { text: 'total sent', transformer: readableTime },
    answerRate: { text: 'answer rate', transformer: readablePercentage },
    p50Length: { text: 'median length', transformer: readableTime },
    shareRate: { text: 'share rate', transformer: readablePercentage },
  }

  const keys = Object.keys(items).filter((key) => !!supportedItems[key])

  const [columns, data] = useMemo(() => {
    if (keys.length === 0) {
      return []
    }

    const columns = [
      {
        title: 'Value',
        dataIndex: 'value',
        key: 'value',
        width: 65,
        render: getRenderValue,
        onCell: () => ({ style: { ...styles.cell, ...style } }),
      },
      {
        title: 'Name',
        dataIndex: 'label',
        key: 'label',
        render: getRenderLabel,
        onCell: () => ({ style: { ...styles.cell, ...style } }),
      },
    ]

    const rows = keys.map((key) => {
      const item = supportedItems[key]
      const value = item?.transformer?.(items[key]) ?? items[key]
      const diff = groupStats?.[key] ? items[key] - groupStats[key] : 0

      return { key, label: item.text, value, diff }
    })

    return [columns, rows]
  }, [keys, items])

  return (
    <Table
      bordered={false}
      columns={columns}
      dataSource={data}
      loading={keys.length === 0}
      onRow={() => ({ onClick: onPress })}
      pagination={false}
      // rowKey="label"
      showHeader={false}
    />
  )
}
