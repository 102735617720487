import { grey } from '@ant-design/colors'
import { SearchOutlined } from '@ant-design/icons'
import { shortenNumber } from '@tellonym/core/helpers'
import {
  ARTIFICIAL_TELL_GROUP_TYPES,
  ARTIFICIAL_TELL_QUESTION_DEPTH,
  ARTIFICIAL_TELL_SENDER_HINT_TYPE,
  ARTIFICIAL_TELL_STATUS,
} from '@tellonym/enums/lib/Tell'
import { Button, Input, Typography } from 'antd'
import React from 'react'
import { Box, Text, View, hooks, moment, styleSheets } from '../../common'
import {
  valueToPercentage,
  valueToPercentageWithSign,
} from '../../statistics/services'
import { depthOrder, typeOrder } from '../constants'
import { ArtificialTellDepthTag } from './ArtificialTellDepthTag'
import { ArtificialTellGroupTypeTag } from './ArtificialTellGroupTypeTag'
import { ArtificialTellStatusTag } from './ArtificialTellStatusTag'

const onCellIdObject = {
  style: { paddingLeft: 4, paddingRight: 4 },
}

export const statusOrder = {
  [ARTIFICIAL_TELL_STATUS.ACTIVE]: 0,
  [ARTIFICIAL_TELL_STATUS.VALIDATION]: 1,
  [ARTIFICIAL_TELL_STATUS.INACTIVE]: 4,
  [ARTIFICIAL_TELL_STATUS.DELETED]: 5,
  [ARTIFICIAL_TELL_STATUS.NOT_LOCALIZED]: 6,
}

export const Stat = ({ value, change, isPercentage }) => {
  const formatValue = isPercentage
    ? (v) => valueToPercentage(v, v < 10 ? 1 : 0).replace('.0', '')
    : (v) => shortenNumber(v, false, 999)
  const formatChange = isPercentage
    ? (v) => valueToPercentageWithSign(v, v < 10 ? 1 : 0).replace('.0', '')
    : (v) => `${v > 0 ? '+' : ''}${shortenNumber(v, false, 999)}`

  return (
    <View
      style={{
        justifyContent: 'center',
        alignItems: 'center',
      }}>
      <Typography.Text>{formatValue(value)}</Typography.Text>
      {typeof change !== 'undefined' && (
        <Typography.Text
          style={{
            marginLeft: 4,
            fontSize: 12,
            color: grey[1],
          }}>
          {formatChange(change)}
        </Typography.Text>
      )}
    </View>
  )
}

export const SharedColumns = {
  id: {
    title: '',
    dataIndex: 'id',
    key: 'id',
    width: 50,
    onCell: () => onCellIdObject,
    sorter: (a, b) => a.id - b.id,
    render: (id, groupOrTopic, index) => (
      <View style={styleSheets.center}>
        <Typography.Text>{index + 1}</Typography.Text>
        <Typography.Text style={{ fontSize: 10, color: grey[1] }}>
          {id}
        </Typography.Text>
      </View>
    ),
  },
  status: {
    title: 'Status',
    dataIndex: 'id',
    key: 'status',
    width: 90,
    render: (id, groupOrTopic) => (
      <ArtificialTellStatusTag status={groupOrTopic.status} />
    ),
    filters: [
      {
        text: 'Active',
        value: ARTIFICIAL_TELL_STATUS.ACTIVE,
      },
      {
        text: 'Validation',
        value: ARTIFICIAL_TELL_STATUS.VALIDATION,
      },
      {
        text: 'Inactive',
        value: ARTIFICIAL_TELL_STATUS.INACTIVE,
      },
      {
        text: 'Not Localized',
        value: ARTIFICIAL_TELL_STATUS.NOT_LOCALIZED,
      },
      {
        text: 'Deleted',
        value: ARTIFICIAL_TELL_STATUS.DELETED,
      },
    ],
    filterMultiple: true,
    filterResetToDefaultFilteredValue: true, // click the reset button, whether to restore the default filter
    defaultFilteredValue: [
      ARTIFICIAL_TELL_STATUS.ACTIVE,
      ARTIFICIAL_TELL_STATUS.INACTIVE,
      ARTIFICIAL_TELL_STATUS.NOT_LOCALIZED,
      ARTIFICIAL_TELL_STATUS.VALIDATION,
    ],
    onFilter: (value, groupOrTopic) => {
      switch (value) {
        case ARTIFICIAL_TELL_STATUS.ACTIVE:
          return groupOrTopic.status === ARTIFICIAL_TELL_STATUS.ACTIVE

        case ARTIFICIAL_TELL_STATUS.INACTIVE:
          return groupOrTopic.status === ARTIFICIAL_TELL_STATUS.INACTIVE

        case ARTIFICIAL_TELL_STATUS.NOT_LOCALIZED:
          return groupOrTopic.status === ARTIFICIAL_TELL_STATUS.NOT_LOCALIZED

        case ARTIFICIAL_TELL_STATUS.DELETED:
          return groupOrTopic.status === ARTIFICIAL_TELL_STATUS.DELETED

        case ARTIFICIAL_TELL_STATUS.VALIDATION:
          return groupOrTopic.status === ARTIFICIAL_TELL_STATUS.VALIDATION

        default:
          return true
      }
    },
    defaultSortOrder: 'ascend',
    sorter: (a, b) => {
      const textA = statusOrder[a.status]
      const textB = statusOrder[b.status]

      if (textA < textB) {
        return -1
      }

      if (textA > textB) {
        return 1
      }

      return 0
    },
  },
  lastUpdatedAt: {
    title: 'Last Updated',
    dataIndex: 'id',
    key: 'last_updated_at',
    width: 110,
    render: (id, groupOrTopic) => {
      if (groupOrTopic.status === ARTIFICIAL_TELL_STATUS.NOT_LOCALIZED) {
        return null
      }

      const date =
        groupOrTopic.last_updated_at || groupOrTopic.lastUpdatedAt
          ? moment(
              groupOrTopic.last_updated_at || groupOrTopic.lastUpdatedAt
            ).format('DD.MM.YY')
          : ''

      return <View style={styleSheets.center}>{date}</View>
    },
    sorter: (a, b) => {
      const timeA = a.lastUpdatedAt
      const timeB = b.lastUpdatedAt

      if (timeA && timeB) {
        return new Date(timeA).getTime() - new Date(timeB).getTime()
      }

      if (!timeA && !timeB) {
        return 0
      }

      if (timeA) {
        return 1
      }

      return -1
    },
  },
  groupDepth: {
    title: 'Depth',
    dataIndex: 'id',
    key: 'depth',
    width: 90,
    render: (id, group) => (
      <Box transparent style={{ alignItems: 'center' }}>
        <ArtificialTellDepthTag depth={group.depth} />
      </Box>
    ),
    filters: [
      {
        text: 'Intro',
        value: ARTIFICIAL_TELL_QUESTION_DEPTH.INTRO,
      },
      {
        text: 'Deep',
        value: ARTIFICIAL_TELL_QUESTION_DEPTH.DEEP,
      },
    ],
    filterMultiple: true,
    filterResetToDefaultFilteredValue: true, // click the reset button, whether to restore the default filter
    onFilter: (value, groupOrTopic) => {
      switch (value) {
        case ARTIFICIAL_TELL_QUESTION_DEPTH.INTRO:
          return groupOrTopic.depth === ARTIFICIAL_TELL_QUESTION_DEPTH.INTRO

        case ARTIFICIAL_TELL_QUESTION_DEPTH.DEEP:
          return groupOrTopic.depth === ARTIFICIAL_TELL_QUESTION_DEPTH.DEEP

        default:
          return true
      }
    },
    defaultSortOrder: 'ascend',
    sorter: (a, b) => {
      const textA = depthOrder[a.depth]
      const textB = depthOrder[b.depth]

      if (textA < textB) {
        return -1
      }

      if (textA > textB) {
        return 1
      }

      return 0
    },
  },
  groupType: {
    title: 'Type',
    dataIndex: 'id',
    key: 'type',
    width: 85,
    render: (id, group) => (
      <Box transparent style={{ alignItems: 'center' }}>
        <ArtificialTellGroupTypeTag groupType={group.type} />
      </Box>
    ),
    filters: [
      {
        text: 'Simple',
        value: ARTIFICIAL_TELL_GROUP_TYPES.SIMPLE,
      },
      {
        text: 'Organic',
        value: ARTIFICIAL_TELL_GROUP_TYPES.ORGANIC,
      },
      {
        text: 'Open',
        value: ARTIFICIAL_TELL_GROUP_TYPES.OPEN,
      },
      {
        text: 'Share',
        value: ARTIFICIAL_TELL_GROUP_TYPES.SHARE,
      },
      {
        text: 'Statement',
        value: ARTIFICIAL_TELL_GROUP_TYPES.STATEMENT,
      },
    ],
    filterMultiple: true,
    filterResetToDefaultFilteredValue: true, // click the reset button, whether to restore the default filter
    onFilter: (value, groupOrTopic) => {
      switch (value) {
        case ARTIFICIAL_TELL_GROUP_TYPES.SIMPLE:
          return groupOrTopic.type === ARTIFICIAL_TELL_GROUP_TYPES.SIMPLE

        case ARTIFICIAL_TELL_GROUP_TYPES.ORGANIC:
          return groupOrTopic.type === ARTIFICIAL_TELL_GROUP_TYPES.ORGANIC

        case ARTIFICIAL_TELL_GROUP_TYPES.OPEN:
          return groupOrTopic.type === ARTIFICIAL_TELL_GROUP_TYPES.OPEN

        case ARTIFICIAL_TELL_GROUP_TYPES.SHARE:
          return groupOrTopic.type === ARTIFICIAL_TELL_GROUP_TYPES.SHARE

        case ARTIFICIAL_TELL_GROUP_TYPES.STATEMENT:
            return groupOrTopic.type === ARTIFICIAL_TELL_GROUP_TYPES.STATEMENT //prettier-ignore

        default:
          return true
      }
    },
    defaultSortOrder: 'ascend',
    sorter: (a, b) => {
      const textA = typeOrder[a.type]
      const textB = typeOrder[b.type]

      if (textA < textB) {
        return -1
      }

      if (textA > textB) {
        return 1
      }

      return 0
    },
  },
  groupTotalSent: {
    title: 'Total Sent',
    dataIndex: 'id',
    key: 'total_sent',
    width: 80,
    defaultSortOrder: 'descend',
    sorter: (a, b) =>
      (a.analytics?.totalSent?.amount ?? a.total_sent ?? 0) -
      (b.analytics?.totalSent?.amount ?? b.total_sent ?? 0),
    filterMultiple: false,
    filters: [
      {
        text: 'Total Sent > 0',
        value: 1,
      },
      {
        text: 'Total Sent > 100',
        value: 2,
      },
      {
        text: 'Total Sent > 1000',
        value: 3,
      },
    ],
    onFilter: (value, group) => {
      switch (value) {
        case 1:
          return (group.analytics?.totalSent?.amount ?? group.total_sent) > 0
        case 2:
          return (group.analytics?.totalSent?.amount ?? group.total_sent) > 100
        case 3:
          return (group.analytics?.totalSent?.amount ?? group.total_sent) > 1000
        default:
          return true
      }
    },
    render: (id, group) => {
      if (group.status === ARTIFICIAL_TELL_STATUS.NOT_LOCALIZED) {
        return null
      }

      return (
        <Stat
          value={group.analytics?.totalSent?.amount ?? group.total_sent}
          change={group.analytics?.totalSent?.change}
        />
      )
    },
  },
  groupSenderHint: {
    title: 'Sender Hint',
    dataIndex: 'id',
    key: 'sender_hint_type',
    width: 100,
    render: (id, group) => {
      if (group.status === ARTIFICIAL_TELL_STATUS.NOT_LOCALIZED) {
        return null
      }

      return (
        <Text type="note">
          {
            {
              [ARTIFICIAL_TELL_SENDER_HINT_TYPE.NONE_DEFAULT]: 'Not set',
              [ARTIFICIAL_TELL_SENDER_HINT_TYPE.NONE]: 'None',
              [ARTIFICIAL_TELL_SENDER_HINT_TYPE.PRETEND_ORGANIC]:
                'Pretend Organic',
              [ARTIFICIAL_TELL_SENDER_HINT_TYPE.REVEAL]: 'Reveal',
            }[group.sender_hint_type]
          }
        </Text>
      )
    },
    filterIcon: () => null,
    filterDropdown: () => null,
    onFilter: (value, group) => {
      if (!value) {
        return true
      }

      return new RegExp(value, 'i').test(group.sender_hint_type)
    },
    sorter: (a, b) => {
      const textA = a.sender_hint_type
      const textB = b.sender_hint_type

      if (textA < textB) {
        return -1
      }

      if (textA > textB) {
        return 1
      }

      return 0
    },
  },
}

export const useColumnSearchProps = (dataIndex) => {
  const searchInput = React.useRef(null)

  return {
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
      close,
    }) => {
      const onConfirm = () => {
        confirm()
        searchInput.current?.blur()
      }

      hooks.useEscapeKey({
        inputRef: searchInput,
        onPress: close,
      })

      return (
        <Box padding={12} onKeyDown={(e) => e.stopPropagation()}>
          <Input
            ref={searchInput}
            allowClear
            placeholder={`Search ${dataIndex}`}
            value={`${selectedKeys[0] || ''}`}
            onChange={(e) =>
              setSelectedKeys(e.target.value ? [e.target.value] : [])
            }
            onPressEnter={onConfirm}
            style={{ marginBottom: 8, width: 280 }}
          />

          <Box flexDirection="row" justifyContent="space-between">
            <Button
              type="primary"
              onClick={onConfirm}
              icon={<SearchOutlined />}
              size="small"
              style={{ width: 90 }}>
              Search
            </Button>
            <Button
              onClick={() => {
                clearFilters?.()
                setTimeout(() => {
                  onConfirm()
                }, 100)
              }}
              size="small"
              style={{ width: 90 }}>
              Reset
            </Button>
            <Button
              type="link"
              size="small"
              onClick={() => {
                close()
              }}>
              close
            </Button>
          </Box>
        </Box>
      )
    },
    filterIcon: (filtered) => (
      <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />
    ),
    onFilter: (value, record) =>
      record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()),
    onFilterDropdownOpenChange: (visible) => {
      if (visible) {
        setTimeout(() => searchInput.current?.select(), 100)
      }
    },
  }
}

export const SharedCells = {
  statusCountsTitle: (items) => {
    const groupStatusCounts = items?.reduce(
      (acc, item) => {
        if (item.status === ARTIFICIAL_TELL_STATUS.ACTIVE) {
          acc.active++
        }

        if (item.status === ARTIFICIAL_TELL_STATUS.INACTIVE) {
          acc.inactive++
        }

        return acc
      },
      { active: 0, inactive: 0 }
    )

    return {
      title: (
        <View style={styleSheets.center}>
          <Typography.Text style={{ fontSize: 10, textAlign: 'center' }}>
            {`${groupStatusCounts?.active ?? '-'} (${
              groupStatusCounts?.inactive ?? '-'
            })`}
          </Typography.Text>
        </View>
      ),
      width: 80,
    }
  },
}
