import { Breadcrumb } from 'antd'
import React from 'react'
import * as ReactRedux from 'react-redux'
import { Box, BoxSecondary, ScrollView, history, hooks } from '../../common'
import { useOutperformingGroupsQuery } from '../queries'
import { getDataMode, getLanguage } from '../selectorsV2'
import { ArtificialTellTopicGroupsTable } from './ArtificialTellTopicGroupsTable'
import { ButtonBack } from './ButtonBack'
import { MedianDataForDataMode } from './MedianDataForDataMode'
import { SelectorsLanguage } from './SelectorsLanguage'

const Header = ({ refresh }) => {
  const onChangeDataMode = () => {
    refresh()
  }

  return (
    <BoxSecondary padding={24}>
      <Box
        alignItems="center"
        transparent
        flexDirection="row"
        paddingBottom={24}>
        <Box
          flexDirection="row"
          justifyContent="center"
          alignItems="center"
          transparent>
          <ButtonBack
            onPress={() => {
              history.goBack()
            }}
            style={{ marginRight: 12, width: 30, height: 30 }}
          />

          <Breadcrumb>
            <Breadcrumb.Item key="Underperforming">
              Outperforming Groups
            </Breadcrumb.Item>
          </Breadcrumb>
        </Box>
      </Box>

      <Box flexDirection="row" justifyContent="space-between" transparent>
        <Box transparent />

        <Box transparent marginRight={48}>
          <MedianDataForDataMode
            data={[]}
            hasTitle
            onChange={onChangeDataMode}
          />
        </Box>

        <SelectorsLanguage />
      </Box>
    </BoxSecondary>
  )
}

export const PageArtificialTellsGroupsOutperforming = () => {
  const table = hooks.useTableState()

  const language = ReactRedux.useSelector(getLanguage)
  const dataMode = ReactRedux.useSelector(getDataMode)

  const { data, refetch } = useOutperformingGroupsQuery({ language, dataMode })

  return (
    <Box>
      <Header dataMode={dataMode} refresh={refetch} />
      <ScrollView>
        <ArtificialTellTopicGroupsTable
          {...table}
          dataSource={data?.groups}
          language={language}
        />
      </ScrollView>
    </Box>
  )
}
