export const ScreenAccountVerification = 'ScreenAccountVerification'
export const ScreenAccountVerificationSelfie = 'ScreenAccountVerificationSelfie'
export const ScreenAnswer = 'ScreenAnswer'
export const ScreenAppIcons = 'ScreenAppIcons'
export const ScreenAuthConnectPhone = 'ScreenAuthConnectPhone'
export const ScreenAuthEmail = 'ScreenAuthEmail'
export const ScreenAuthFindContacts = 'ScreenAuthFindContacts'
export const ScreenAuthFollowFriends = 'ScreenAuthFollowFriends'
export const ScreenAuthLogin = 'ScreenAuthLogin'
export const ScreenAuthLoginMethodsEmailSent = 'ScreenAuthLoginMethodsEmailSent'
export const ScreenAuthLoginMethodsPicker = 'ScreenAuthLoginMethodsPicker'
export const ScreenAuthLoginMethodsRecoverByMail = 'ScreenAuthLoginMethodsRecoverByMail' // prettier-ignore
export const ScreenAuthMainLanguageSelection = 'ScreenAuthMainLanguageSelection'
export const ScreenAuthPassword = 'ScreenAuthPassword'
export const ScreenAuthPurchasePremium = 'ScreenAuthPurchasePremium'
export const ScreenAuthPushNotifications = 'ScreenAuthPushNotifications'
export const ScreenAuthRoot = 'ScreenAuthRoot'
export const ScreenAuthSharingCards = 'ScreenAuthSharingCards'
export const ScreenAuthSharingLink = 'ScreenAuthSharingLink'
export const ScreenAuthTellsArrivingSoon = 'ScreenAuthTellsArrivingSoon'
export const ScreenAuthUsername = 'ScreenAuthUsername'
export const ScreenBlocks = 'ScreenBlocks'
export const ScreenCommunityAdminInbox = 'ScreenCommunityAdminInbox'
export const ScreenCommunityBlockedUsers = 'ScreenCommunityBlockedUsers'
export const ScreenCommunityCreationRequirements =
  'ScreenCommunityCreationRequirements'
export const ScreenCommunityManagement = 'ScreenCommunityManagement'
export const ScreenCommunityMembers = 'ScreenCommunityMembers'
export const ScreenCommunityPosts = 'ScreenCommunityPosts'
export const ScreenCommunityProfile = 'ScreenCommunityProfile'
export const ScreenEditProfile = 'ScreenEditProfile'
export const ScreenEitherOrGame = 'ScreenEitherOrGame'
export const ScreenExploreRecommendations = 'ScreenExploreRecommendations'
export const ScreenEmail = 'ScreenEmail'
export const ScreenFeed = 'ScreenFeed'
export const ScreenFeedFeatured = 'ScreenFeedFeatured'
export const ScreenFollowers = 'ScreenFollowers'
export const ScreenFollowFriends = 'ScreenFollowFriends'
export const ScreenFollowings = 'ScreenFollowings'
export const ScreenFriendsRecommendations = 'ScreenFriendsRecommendations'
export const ScreenInterestUserRecommendations = 'ScreenInterestUserRecommendations' // prettier-ignore
export const ScreenInteractionsSettings = 'ScreenInteractionsSettings'
export const ScreenLikes = 'ScreenLikes'
export const ScreenMessaging = 'ScreenMessaging'
export const ScreenMoreSettings = 'ScreenMoreSettings'
export const ScreenNotificationGroupActivities = 'ScreenNotificationGroupActivities' // prettier-ignore
export const ScreenNotificationGroupProfileUpdates = 'ScreenNotificationGroupProfileUpdates' // prettier-ignore
export const ScreenNotificationGroupLikesAndFollowers = 'ScreenNotificationGroupLikesAndFollowers' // prettier-ignore
export const ScreenNotifications = 'ScreenNotifications'
export const ScreenPassword = 'ScreenPassword'
export const ScreenPost = 'ScreenPost'
export const ScreenPremiumMembership = 'ScreenPremiumMembership'
export const ScreenProfile = 'ScreenProfile'
export const ScreenProfileSharing = 'ScreenProfileSharing'
export const ScreenPushNotificationsSettings = 'ScreenPushNotificationsSettings'
export const ScreenReportCase = 'ScreenReportCase'
export const ScreenResult = 'ScreenResult'
export const ScreenSafetyLevel = 'ScreenSafetyLevel'
export const ScreenSearch = 'ScreenSearch'
export const ScreenSentTells = 'ScreenSentTells'
export const ScreenSettings = 'ScreenSettings'
export const ScreenSplash = 'ScreenSplash'
export const ScreenTellonymContact = 'ScreenTellonymContact'
export const ScreenTellPrompts = 'ScreenTellPrompts'
export const ScreenTellsPreferences = 'ScreenTellsPreferences'
export const ScreenTells = 'ScreenTells'
export const ScreenTellsPersonal = 'ScreenTellsPersonal'
export const ScreenTellsCommunities = 'ScreenTellsCommunities'
export const ScreenWordfilter = 'ScreenWordfilter'
