import { assocPath, mergeDeepRight } from 'ramda'
import * as t from './types'

export const { name } = t

export const initialState = {
  answers: [],
  isRefreshingAnswers: false,
  badwordscore: {
    isFetching: false,
    isRefreshing: false,
    data: {
      factor: 0,
      foundWords: [],
      language: 0,
      score: 0,
      str: '',
      wouldBeBlocked: false,
    },
  },
  experiments: {
    isLoadingReroll: false,
  },
  error: null,
  isLoading: false,
  isSaving: false,
  spamScore: {
    isRefreshing: false,
    data: {
      factor: 0,
      foundWords: [],
      score: 0,
      str: '',
      result: false,
    },
  },
}

export const reducer = (state = initialState, action) => {
  switch (action.type) {
    case t.CLEAR_ANSWERS:
      return {
        ...state,
        answers: [],
      }

    case t.REFRESH_ANSWERS:
      return mergeDeepRight(state, {
        isRefreshingAnswers: true,
      })

    case t.REFRESH_ANSWERS_ERROR:
      return mergeDeepRight(state, {
        isRefreshingAnswers: false,
      })

    case t.REFRESH_ANSWERS_SUCCESS:
      return mergeDeepRight(state, {
        answers: action.payload.answers.data,
        isRefreshingAnswers: false,
      })

    case t.REFRESH_BADWORDSCORE:
      return {
        ...state,
        badwordscore: {
          ...initialState.badwordscore,
          isRefreshing: true,
        },
        error: undefined,
      }

    case t.REFRESH_BADWORDSCORE_SUCCESS: {
      const { ...data } = action.payload
      return {
        ...state,
        badwordscore: {
          ...initialState.badwordscore,
          data: {
            ...data,
            foundWords: data.foundWords ? data.foundWords.data : [],
          },
        },
      }
    }

    case t.REFRESH_BADWORDSCORE_ERROR:
      return {
        ...state,
        badwordscore: {
          ...initialState.badwordscore,
          isRefreshing: false,
        },
        error: action.payload,
      }

    case t.REROLL_EXPERIMENTS:
      return assocPath(['experiments', 'isLoadingReroll'], true, state)

    case t.REROLL_EXPERIMENTS_ERROR:
    case t.REROLL_EXPERIMENTS_SUCCESS:
      return assocPath(['experiments', 'isLoadingReroll'], false, state)

    case t.REFRESH_SPAM_SCORE:
      return mergeDeepRight(state, {
        spamScore: {
          isRefreshing: true,
          data: {
            str: '',
          },
        },
      })

    case t.REFRESH_SPAM_SCORE_ERROR:
      return mergeDeepRight(state, {
        spamScore: {
          isRefreshing: false,
        },
      })

    case t.REFRESH_SPAM_SCORE_SUCCESS:
      return mergeDeepRight(state, {
        spamScore: {
          isRefreshing: false,
          data: {
            ...action.payload,
            foundWords: action.payload.foundWords
              ? action.payload.foundWords.data
              : [],
            str: action.meta.payload.str,
          },
        },
      })

    default:
      return state
  }
}
