import { colors } from '@tellonym/core/common/colorSystem'
import { Typography } from 'antd'
import { bool } from 'prop-types'
import React from 'react'
import { Radium } from '../hocs/Radium'
import { theme } from '../styles/theme'

class TextComponent extends React.Component {
  static Ant = Typography.Text

  static childContextTypes = {
    isInAParentText: bool,
  }

  static contextTypes = {
    isInAParentText: bool,
  }

  getChildContext() {
    return { isInAParentText: true }
  }

  render() {
    const {
      radiumConfigContext, // Removing here so it will not be passed to html component
      styleKeeperContext, // Removing here so it will not be passed to html component
      bold,
      center,
      children,
      color,
      desktopStyle,
      ellipsisWidth,
      onPress,
      semibold,
      style,
      type = 'default',
      ...props
    } = this.props

    const Component = this.context.isInAParentText ? 'span' : 'div'

    return (
      <Component
        onClick={onPress}
        style={[
          {
            color:
              color ??
              (window.tnym.isDarkModeEnabled
                ? colors.grey[1]
                : theme.colors.text),
            fontSize: theme.fontSizes[type],
            fontWeight: bold ? 'bold' : semibold ? '600' : undefined,
            textAlign: center ? 'center' : undefined,
            cursor: onPress ? 'pointer' : undefined,
            whiteSpace: 'pre-wrap',
            overflowWrap: 'break-word',
            wordWrap: 'break-word',
            wordBreak: 'break-word',
          },
          (window.navigator.userAgent.toLowerCase().includes('firefox') ||
            window.navigator.userAgent.toLowerCase().includes('edge')) && {
            wordBreak: 'break-all',
          },
          ellipsisWidth && {
            maxWidth: ellipsisWidth,
            whiteSpace: 'nowrap',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
          },
          style,
          desktopStyle && {
            '@media (min-width: 768px)': {
              ...desktopStyle,
            },
          },
        ]}
        {...props}>
        {children}
      </Component>
    )
  }
}

export const Text = Radium(TextComponent)
