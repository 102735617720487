import { colors } from '@tellonym/core/common/colorSystem'
import { BAN_REASON, BAN_TYPE } from '@tellonym/enums/lib/User'
import { Modal, Table, Typography } from 'antd'
import dayjs from 'dayjs'
import React from 'react'
import { Box, Text, _ } from '../../common'

const getBanCounts = (bans) =>
  bans?.reduce(
    (acc, ban) => {
      acc.count += 1

      switch (ban.banType) {
        case BAN_TYPE.PERMANENT:
          acc.perma = (acc.perma ?? 0) + 1
          return acc

        case BAN_TYPE.TEMPORARY: {
          const diffString = dayjs(ban.time).to(dayjs(ban.timeEnd), true)

          acc[diffString] = (acc[diffString] ?? 0) + 1
          return acc
        }

        case BAN_TYPE.PERMANENT_SHADOW:
          acc['perma shadow'] = (acc.shadowPerma ?? 0) + 1
          return acc

        case BAN_TYPE.TEMPORARY_SHADOW: {
          const diffString = dayjs(ban.time).to(dayjs(ban.timeEnd), true)

          acc[`${diffString} shadow`] = (acc[`${diffString} shadow`] ?? 0) + 1
          return acc
        }

        default:
          return acc
      }
    },
    { count: 0 }
  ) ?? { count: 0 }

export const PastBans = ({ profile }) => {
  const banCounts = getBanCounts(profile?.bans)

  const [isModalVisible, setIsModalVisible] = React.useState(false)

  const toggleModal = () => {
    setIsModalVisible((isModalVisible) => !isModalVisible)
  }

  return (
    <>
      <Modal
        open={isModalVisible}
        onOk={toggleModal}
        onCancel={toggleModal}
        width="90%">
        <AccountBansTable bans={profile?.bans} />
      </Modal>
      <Box transparent marginBottom={4}>
        <Box transparent flexDirection="row" onPress={toggleModal}>
          <Text color={colors.pink[1]}>{'Past Bans: '}</Text>
          <Text color={colors.grey[7]}>{banCounts?.count}</Text>
        </Box>

        {Object.keys(banCounts).map((key) => {
          if (key === 'count') return null

          return (
            <Text
              key={key}
              type="note"
              color={colors.grey[7]}
              style={{ marginLeft: 8 }}>{`${banCounts[key]}x ${key}`}</Text>
          )
        })}
      </Box>
    </>
  )
}

const UserId = ({ userId }) => (
  <Box transparent {..._.openRouteProps(`/user/${userId}`)}>
    <Typography.Text style={{ color: colors.pink[1] }}>
      {userId}
    </Typography.Text>
  </Box>
)

const renderDisplayTime = (dateTime) => {
  if (dayjs(dateTime).isValid()) {
    return dayjs(dateTime).format('DD.MM.YYYY HH:mm')
  }

  return dateTime
}

const columns = [
  {
    title: 'Ban Type',
    dataIndex: 'banType',
    key: 'banType',
    render: (banType) => BAN_TYPE[banType],
  },
  {
    title: 'User ID',
    dataIndex: 'userId',
    key: 'userId',
    width: 100,
    render: (userId) => <UserId userId={userId} />,
  },
  {
    title: 'Issuer User ID',
    dataIndex: 'issuerUserId',
    key: 'issuerUserId',
    width: 100,
    render: (userId) => <UserId userId={userId} />,
  },
  {
    title: 'Reason',
    dataIndex: 'reason',
    key: 'reason',
    render: (reason) => BAN_REASON[reason],
  },
  {
    title: 'Text Reason',
    dataIndex: 'textReason',
    key: 'textReason',
  },
  {
    title: 'Internal Note',
    dataIndex: 'internalNote',
    key: 'internalNote',
    render: (input) => <Box width={200}>{input}</Box>,
  },
  {
    title: 'Device uuid',
    dataIndex: 'deviceUuid',
    key: 'deviceUuid',
  },
  {
    title: 'Time',
    dataIndex: 'time',
    key: 'time',
    width: 160,
    render: renderDisplayTime,
  },
  {
    title: 'Time End',
    dataIndex: 'timeEnd',
    key: 'timeEnd',
    width: 160,
    render: renderDisplayTime,
  },
]

export const AccountBansTable = ({ bans }) => {
  return (
    <Box flex={1}>
      <Table dataSource={bans} columns={columns} />
    </Box>
  )
}

export const AccountBansByDevice = ({ profile }) => {
  const [isModalVisible, setIsModalVisible] = React.useState(false)

  const toggleModal = () => {
    setIsModalVisible((isModalVisible) => !isModalVisible)
  }

  if (typeof profile?.associatedDeviceBans === 'undefined') {
    return null
  }

  const banCounts = getBanCounts(profile?.associatedDeviceBans)

  return (
    <>
      <Modal
        open={isModalVisible}
        onOk={toggleModal}
        onCancel={toggleModal}
        width="90%">
        <AccountBansTable bans={profile?.associatedDeviceBans} />
      </Modal>
      <Box transparent marginBottom={4}>
        <Box transparent flexDirection="row" onPress={toggleModal}>
          <Text color={colors.pink[1]}>{'Account Bans by Device: '}</Text>
          <Text color={colors.grey[7]}>{banCounts.count}</Text>
        </Box>

        {Object.keys(banCounts).map((key) => {
          if (key === 'count') return null

          return (
            <Text
              key={key}
              type="note"
              color={colors.grey[7]}
              style={{
                marginLeft: 8,
              }}>{`${banCounts[key]}x ${key}`}</Text>
          )
        })}
      </Box>
    </>
  )
}
