import { capitalize } from '@tellonym/core/helpers'
import { USER_TYPE } from '@tellonym/enums/lib/User'
import { Badge } from 'antd'
import React from 'react'
import { theme } from '../../common'

const getUserTypeColor = (type) => {
  switch (type) {
    case USER_TYPE.ADMIN:
      return 'gold'

    case USER_TYPE.MODERATOR:
      return 'cyan'

    case USER_TYPE.BANNED:
    case USER_TYPE.BAN_SHADOW:
    case USER_TYPE.BAN_SHADOW_AUTOMATIC:
    case USER_TYPE.DELETED:
      return 'volcano'

    default:
      return theme.colors.placeholder
  }
}

export const UserTypeBadge = ({ type, style }) => (
  <Badge
    color={getUserTypeColor(type)}
    count={capitalize(USER_TYPE[type].toLowerCase())}
    style={style}
  />
)
