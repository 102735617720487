import React from 'react'
import { useProfileSettingsQuery } from '../../profile/queries'
import { PageProfile } from './PageProfile'

export const PageResult = ({ match }) => {
  const { data: profile, isFetching } = useProfileSettingsQuery({
    userId: parseInt(match.params.id, 10),
  })

  return <PageProfile profile={profile} isLoading={isFetching} />
}
