import { safeEffects } from '@tellonym/core/sagas'
import { ARTIFICIAL_TELL_TARGET_GENDER } from '@tellonym/enums/lib/Tell'
import { message } from 'antd'
import { put } from 'redux-saga/effects'
import { history } from '../common'
import { updateLocationSearch } from '../common/helpers'
import { queryClient } from '../common/queries'
import { clearSpeedContent, refreshGroup, refreshTopic } from './actionsV2'
import { artellv2 } from './queries'
import * as t from './typesV2'

const handleRefreshGroup = function* handleRefreshGroup() {
  const isGroupDetailsPage = history.location.pathname.includes('group/')

  if (!isGroupDetailsPage) {
    return
  }

  const [, , , , groupId] = history.location.pathname.split('/')

  yield put(refreshGroup({ groupId }))
}

const handleRefreshTopic = function* handleRefreshTopic() {
  const isTopicDetailsPage = history.location.pathname.includes('topic/')

  if (!isTopicDetailsPage) {
    return
  }

  const [, , , , topicId] = history.location.pathname.split('/')

  yield put(refreshTopic({ topicId }))
}

const syncUrlDataModeWithState = function* (action) {
  updateLocationSearch({ dataMode: action.payload })
}

const syncUrlLanguageWithState = function* (action) {
  const hasLanguageParam = /^\/artificialtells_v2\/\w{2}[?/]/.test(
    history.location.pathname
  )

  if (hasLanguageParam) {
    const [, routeString, , ...rest] = history.location.pathname.split('/')
    const updatedRoute = [routeString, action.payload, ...rest].join('/')

    history.replace({
      ...history.location,
      pathname: `/${updatedRoute}`,
    })
  }
}

const onCreateTopicSuccess = function* onCreateTopicSuccess(action) {
  const [, , language] = history.location.pathname.split('/')

  history.push({
    pathname: `/artificialtells_v2/${language}/topic/${action.payload.id}`,
  })
}

const onEditGroupSuccess = function* onCreateTopicSuccess(action) {
  const isTopicDetailsPage = history.location.pathname.includes('topic/')
  const isGroupDetailsPage = history.location.pathname.includes('group/')

  if (isTopicDetailsPage || isGroupDetailsPage) {
    // don't refresh after moving a group to a different topic due to long loading times, change back later
    if (action.meta?._noRefresh === true) {
      return
    }

    const [, , , , topicId] = history.location.pathname.split('/')

    yield put(refreshGroup({ groupId: action.payload.id }))
    yield put(refreshTopic({ topicId }))
  }
}

const onCreateGroupsSuccess = function* onCreateGroupsSuccess(action) {
  yield put(clearSpeedContent({ topicId: action.meta.payload.topicId }))
  yield put(refreshTopic({ topicId: action.meta.payload.topicId }))
}

const onEditPromptSuccess = function* onEditPromptSuccess(action) {
  message.success(
    `Prompt saved successfully for ${
      ARTIFICIAL_TELL_TARGET_GENDER[action.meta.payload.gender]
    }`
  )
}

const syncGroupMode = function* syncGroupMode(action) {
  updateLocationSearch({ groupMode: action.payload })
}

const syncHeaderMode = function* syncHeaderMode(action) {
  updateLocationSearch({ headerMode: action.payload })
}

const handleChangelog = function* handleChangelog(action) {
  const [, , language] = history.location.pathname.split('/')

  queryClient.refetchQueries({
    queryKey: artellv2.performanceCheckIns({
      groupId: action.payload.groupId,
      topicId: action.payload.topicId,
      language,
    }),
  })
}

export const actionHandler = {
  [t.CREATE_GROUP_SUCCESS]: [safeEffects.takeLatest, handleRefreshTopic],
  [t.SET_DATA_MODE]: [safeEffects.takeLatest, syncUrlDataModeWithState],
  [t.SET_LANGUAGE]: [safeEffects.takeLatest, syncUrlLanguageWithState],
  [t.EDIT_GROUP_SUCCESS]: [safeEffects.takeLatest, onEditGroupSuccess],
  [t.EDIT_TOPIC_SUCCESS]: [safeEffects.takeLatest, handleRefreshTopic],
  [t.EDIT_PROMPT_SUCCESS]: [safeEffects.takeLatest, onEditPromptSuccess],
  [t.CREATE_GROUPS_SUCCESS]: [safeEffects.takeLatest, onCreateGroupsSuccess],
  [t.CREATE_TOPIC_SUCCESS]: [safeEffects.takeLatest, onCreateTopicSuccess],
  [t.CREATE_VARIANCE_SUCCESS]: [safeEffects.takeLatest, handleRefreshGroup],
  [t.EDIT_VARIANCE_SUCCESS]: [
    safeEffects.takeLatest,
    handleRefreshGroup,
    handleChangelog,
  ],
  [t.CREATE_TODO_SUCCESS]: [
    safeEffects.takeLatest,
    handleRefreshTopic,
    handleRefreshGroup,
  ],
  [t.COMPLETE_TODO_SUCCESS]: [
    safeEffects.takeLatest,
    handleRefreshTopic,
    handleRefreshGroup,
  ],
  [t.SET_GROUP_MODE]: [safeEffects.takeLatest, syncGroupMode],
  [t.SET_HEADER_MODE]: [safeEffects.takeLatest, syncHeaderMode],
}
