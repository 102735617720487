import { ExportOutlined } from '@ant-design/icons'
import { faInstagram, faTwitter } from '@fortawesome/free-brands-svg-icons'
import { faEnvelope, faPhone } from '@fortawesome/free-solid-svg-icons'
import { colors } from '@tellonym/core/common/colorSystem'
import { shortenNumber } from '@tellonym/core/helpers'
import { Button, Card, Grid, Modal } from 'antd'
import { Style } from 'radium'
import React from 'react'
import * as ReactRedux from 'react-redux'
import { config } from '../../../config'
import {
  Alert,
  Avatar,
  Box,
  Icon,
  Link,
  Text,
  TextTranslatable,
  TouchableOpacity,
  View,
  moment,
  styleSheets,
  theme,
} from '../../common'
import { AvatarGroup } from '../../common/components/AvatarGroup'
import { IconVerified } from '../../common/components/IconVerified'
import * as hooks from '../../common/hooks'
import { createQuick7DayBan } from '../actions'
import {
  useQuickBanSexualMutation,
  useQuickBanSexualPermaMutation,
} from '../queries'
import { AccountBansByDevice, PastBans } from './BanStats'
import { ModalFollowers } from './ModalFollowers'
import { ModalFollowings } from './ModalFollowings'
import { ShadowBanButtons } from './ShadowBanButtons'
import { UserTypeBadge } from './UserTypeBadge'

const ProfileStatsItem = ({ name, value }) => (
  <View
    style={{
      flex: 1,
      flexDirection: 'row',
      justifyContent: 'space-between',
      marginBottom: 4,
    }}>
    <Text>{name}</Text>
    <Text>{shortenNumber(value)}</Text>
  </View>
)

const ProfileStats = ({ profile }) => (
  <View style={{ width: '100%', marginTop: 24 }}>
    <Link onClick={() => ModalFollowers.show({ userId: profile.id })}>
      <ProfileStatsItem name="Follower" value={profile.follower} />
    </Link>
    <Link onClick={() => ModalFollowings.show({ userId: profile.id })}>
      <ProfileStatsItem name="Following" value={profile.followingCount} />
    </Link>
    <ProfileStatsItem name="Tells" value={profile.tellCount} />
    <ProfileStatsItem name="Answers" value={profile.answerCount} />
    <ProfileStatsItem name="Sent Tells" value={profile.sentTellCount} />
  </View>
)

const ConnectedAccountsItem = ({
  color = undefined,
  icon,
  onPress = undefined,
  to = undefined,
  style = {},
}) => (
  <View
    style={[
      {
        borderRadius: 25,
        marginRight: 8,
        ...style,
      },
      color && { backgroundColor: color },
      style,
    ]}>
    {to ? (
      <Link to={to} style={{ padding: 8 }}>
        <Icon icon={icon} style={{ color: theme.colors.white }} />
      </Link>
    ) : onPress ? (
      <TouchableOpacity onPress={onPress} style={{ padding: 8 }}>
        <Icon icon={icon} style={{ color: theme.colors.white }} />
      </TouchableOpacity>
    ) : (
      <Icon icon={icon} style={{ color: theme.colors.white, padding: 8 }} />
    )}
  </View>
)

const ConnectedAccounts = ({ profile }) => (
  <View style={{ marginTop: 24, flexDirection: 'row', alignSelf: 'center' }}>
    {profile.email && (
      <ConnectedAccountsItem
        icon={faEnvelope}
        color={theme.colors.niceYellow}
        onPress={() => Alert.alert('email', profile.email)}
      />
    )}
    {profile.phone && (
      <ConnectedAccountsItem
        icon={faPhone}
        color={theme.colors.niceGreen}
        onPress={() =>
          Alert.alert('phone', `${profile.phonePrefix} ${profile.phoneNumber}`)
        }
      />
    )}
    {profile.twitterUsername && (
      <ConnectedAccountsItem
        icon={faTwitter}
        color={theme.colors.twitter}
        to={`https://twitter.com/${profile.twitterUsername}`}
      />
    )}
    {profile.instagramUsername && (
      <ConnectedAccountsItem
        icon={faInstagram}
        color={theme.colors.instagramUsername}
        to={`https://instagram.com/${profile.instagramUsername}`}
        style={{
          background:
            'radial-gradient(circle at 30% 107%, #fdf497 0%, #fdf497 5%, #fd5949 45%,#d6249f 60%,#285AEB 90%)',
        }}
      />
    )}
  </View>
)

const QuickActionButtons = ({ profile }) => {
  const dispatch = ReactRedux.useDispatch()

  const { mutate: triggerQuickBanSexualMutation, isLoading } =
    useQuickBanSexualMutation()

  const quickBanSexual = () => {
    triggerQuickBanSexualMutation({ userId: profile.id })
  }

  const quick7DayBan = () => {
    dispatch(createQuick7DayBan({ userId: profile.id }))
  }

  const {
    mutate: triggerQuickBanSexualPermaMutation,
    isLoading: isLoadingPerma,
  } = useQuickBanSexualPermaMutation()

  const quickBanSexualPerma = () => {
    triggerQuickBanSexualPermaMutation({ userId: profile.id })
  }

  return (
    <>
      <Button
        shape="round"
        loading={isLoading}
        onClick={quickBanSexual}
        style={styleSheets.margin.top[12]}>
        Quick Ban Sexual
      </Button>
      <Button
        shape="round"
        onClick={quick7DayBan}
        style={styleSheets.margin.top[12]}>
        Quick 7d Ban
      </Button>
      <Button
        shape="round"
        loading={isLoadingPerma}
        onClick={quickBanSexualPerma}
        style={styleSheets.margin.top[12]}>
        Quick Perma Ban Sexual
      </Button>

      <Box marginTop={12} maxWidth="100%">
        <ShadowBanButtons profile={profile} />
      </Box>
    </>
  )
}

const modalAvatarCreator = (avatarFileName) => () =>
  Modal.info({
    width: '50%',
    content: (
      <View style={{ flex: 1, alignItems: 'center' }}>
        <Avatar
          rectangle
          borderColor={theme.colors.black}
          user={{ avatarFileName }}
          size={380}
        />
      </View>
    ),
  })

const antdOverrides = {
  '.ant-card-cover': {
    marginLeft: '0 !important',
    marginRight: '0 !important',
  },
}

export const GeneralUserInfos = ({ profile }) => {
  const [isModalOpen, setIsModalOpen] = React.useState(false)
  const [shouldTranslate, setShouldTranslate] = React.useState(false)

  const screens = Grid.useBreakpoint()

  const getThemedColor = hooks.useThemedColor()

  const toggleShouldTranslate = () => {
    setShouldTranslate(!shouldTranslate)
  }

  const onPressOpenTelloProfile = () => {
    window.open(`${config.webUrl}/${profile.username}`, '_blank')
  }

  const onPressShowModal = () => {
    setIsModalOpen(true)
  }

  const onPressClose = () => {
    setIsModalOpen(false)
  }

  return (
    <>
      <Style rules={antdOverrides} />
      <Card
        size="small"
        cover={
          <Box paddingHorizontal={2}>
            <AvatarGroup
              creatorOnPressAvatar={modalAvatarCreator}
              user={profile}
              size={90}
            />
          </Box>
        }
        extra={
          <ExportOutlined
            onClick={onPressOpenTelloProfile}
            onAuxClick={onPressOpenTelloProfile}
          />
        }
        style={{ width: 290 }}>
        <Box alignItems="center" justifyContent="center">
          <Box flexDirection="row" alignItems="center">
            <Text type="h3" center bold>
              {profile.displayName}
            </Text>
            {profile.isVerified && (
              <IconVerified style={styleSheets.margin.left[4]} />
            )}
          </Box>

          <UserTypeBadge type={profile.type} />

          <Text
            center
            color={getThemedColor(colors.grey[6], colors.grey[1])}
            style={styleSheets.margin.top[4]}>{`@${profile.username}`}</Text>

          <TextTranslatable.Pure
            shouldTranslate={shouldTranslate}
            style={{ marginTop: 8, textAlign: 'center' }}>
            {profile.aboutMe}
          </TextTranslatable.Pure>

          {profile.aboutMe !== '' && (
            <Button
              type="dashed"
              size="small"
              shape="round"
              onClick={toggleShouldTranslate}
              style={styleSheets.margin.top[8]}>
              {shouldTranslate ? 'original' : 'translate'}
            </Button>
          )}

          <Text
            type="small"
            color={theme.colors.placeholder}
            style={{ marginTop: 12, marginBottom: 12 }}>
            {`joined ${moment(profile.registered).fromNow()}`}
          </Text>

          {screens.lg ? (
            <QuickActionButtons profile={profile} />
          ) : (
            <>
              <Modal
                title="Quick actions"
                open={isModalOpen}
                onCancel={onPressClose}>
                <QuickActionButtons profile={profile} />
              </Modal>
              <Button onClick={onPressShowModal}>Quick actions</Button>
            </>
          )}
        </Box>

        <Box marginTop={12}>
          <PastBans profile={profile} />
          <AccountBansByDevice profile={profile} />
        </Box>

        <ProfileStats profile={profile} />

        <ConnectedAccounts profile={profile} />
      </Card>
    </>
  )
}
