import React from 'react'
import { View } from './View'

const LinearGradient = ({ style = {}, ...props }) => (
  <View
    style={[
      style.color
        ? { backgroundColor: style.color }
        : {
            background: 'linear-gradient(135deg, #FF3CA7, #FF0075)',
          },
      style,
    ]}
    {...props}
  />
)

export { LinearGradient }
