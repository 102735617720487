import {
  CaretDownOutlined,
  CaretUpOutlined,
  ExportOutlined,
  InfoCircleOutlined,
  LineChartOutlined,
} from '@ant-design/icons'
import { ProCard, StatisticCard } from '@ant-design/pro-components'
import { colors } from '@tellonym/core/common/colorSystem'
import { CountryPhoneCodes } from '@tellonym/core/constants/CountryPhoneCodes'
import { capitalize, formatNumber } from '@tellonym/core/helpers'
import { copyToClipboard } from '@tellonym/core/share/actions'
import {
  DETECTABLE_LANGUAGE,
  langDetectObjectsByType1,
} from '@tellonym/enums/lib/Language'
import {
  ARTIFICIAL_TELL_GROUP_TYPES,
  ARTIFICIAL_TELL_USER_GROUP_TYPE,
} from '@tellonym/enums/lib/Tell'
import {
  Badge,
  Grid,
  Progress,
  Space,
  Table,
  Tabs,
  Tooltip,
  Typography,
} from 'antd'
import dayjs from 'dayjs'
import qs from 'qs'
import { Style } from 'radium'
import * as R from 'ramda'
import React from 'react'
import * as ReactRedux from 'react-redux'
import { Box, hooks, ScrollView, styleSheets, Text } from '../../common'
import { valueToPercentage } from '../../statistics/services'
import { setLanguage } from '../actionsV2'
import { useAvailableLanguages } from '../hooks/useAvailableLanguages'
import { useDashboardQuery } from '../queries'
import { getLanguage } from '../selectorsV2'

const { Statistic } = StatisticCard

const userGroupTypeShortnameMap = {
  [ARTIFICIAL_TELL_USER_GROUP_TYPE.LOW]: 'LOW',
  [ARTIFICIAL_TELL_USER_GROUP_TYPE.HIGH]: 'HIGH',
  [ARTIFICIAL_TELL_USER_GROUP_TYPE.REAL]: 'ALL',
  LURKER: 'LURKER',
  ALL: 'ALL',
}

const userGroupTypeLabelMap = {
  [ARTIFICIAL_TELL_USER_GROUP_TYPE.LOW]:
    ARTIFICIAL_TELL_USER_GROUP_TYPE[
      ARTIFICIAL_TELL_USER_GROUP_TYPE.LOW
    ].toLowerCase(),
  [ARTIFICIAL_TELL_USER_GROUP_TYPE.HIGH]:
    ARTIFICIAL_TELL_USER_GROUP_TYPE[
      ARTIFICIAL_TELL_USER_GROUP_TYPE.HIGH
    ].toLowerCase(),
  [ARTIFICIAL_TELL_USER_GROUP_TYPE.REAL]:
    ARTIFICIAL_TELL_USER_GROUP_TYPE[
      ARTIFICIAL_TELL_USER_GROUP_TYPE.REAL
    ].toLowerCase(),
  LURKER: 'lurker',
  ALL: 'all',
}

const groupTypeColorMap = {
  ALL: 'geekblue',
  [ARTIFICIAL_TELL_GROUP_TYPES.OPEN]: 'blue',
  [ARTIFICIAL_TELL_GROUP_TYPES.SIMPLE]: 'volcano',
  [ARTIFICIAL_TELL_GROUP_TYPES.ORGANIC]: 'green',
  [ARTIFICIAL_TELL_GROUP_TYPES.STATEMENT]: 'gold',
  [ARTIFICIAL_TELL_GROUP_TYPES.SHARE]: 'purple',
}

const CopyableTitle = ({ title, id }) => {
  const dispatch = ReactRedux.useDispatch()

  return (
    <span
      onClick={() => dispatch(copyToClipboard(id))}
      style={{ cursor: 'pointer' }}>
      {title}
    </span>
  )
}
const InfoTooltip = ({ data, aggregationType, description }) => {
  const getThemedColor = hooks.useThemedColor()

  return (
    <Tooltip
      title={
        <Space direction="vertical">
          {Boolean(description) && <span>{description}</span>}
          {Boolean(data) && (
            <span>
              {`Data shows the ${
                aggregationType ? `${aggregationType} of the` : ''
              } last ${data?.config?.timeframeInDays} days, compared
              to the ${data?.config?.comparisonTimeFrameInDays} days before.`}
            </span>
          )}
        </Space>
      }>
      <InfoCircleOutlined
        style={{ color: getThemedColor(undefined, colors.white[1]) }}
      />
    </Tooltip>
  )
}

const StatGroupTypeTitle = ({ groupType, text }) => {
  const screens = Grid.useBreakpoint()

  const [title, color, style] = (() => {
    switch (groupType) {
      case ARTIFICIAL_TELL_GROUP_TYPES.SIMPLE:
        return ['Simple', groupTypeColorMap[ARTIFICIAL_TELL_GROUP_TYPES.SIMPLE]]

      case ARTIFICIAL_TELL_GROUP_TYPES.ORGANIC:
        return [
          'Organic',
          groupTypeColorMap[ARTIFICIAL_TELL_GROUP_TYPES.ORGANIC],
        ]

      case ARTIFICIAL_TELL_GROUP_TYPES.STATEMENT:
        return [
          'Statement',
          groupTypeColorMap[ARTIFICIAL_TELL_GROUP_TYPES.STATEMENT],
        ]

      case ARTIFICIAL_TELL_GROUP_TYPES.OPEN:
        return ['Open', groupTypeColorMap[ARTIFICIAL_TELL_GROUP_TYPES.OPEN]]

      case ARTIFICIAL_TELL_GROUP_TYPES.SHARE:
        return ['Share', groupTypeColorMap[ARTIFICIAL_TELL_GROUP_TYPES.SHARE]]

      case 'ContentTags':
        return [
          'Content Tags',
          groupTypeColorMap.ALL,
          screens.xxl ? { fontSize: 13 } : undefined,
        ]

      case 'ALL':
        return [
          'No Limitations',
          groupTypeColorMap.ALL,
          screens.xxl ? { fontSize: 11 } : undefined,
        ]

      default:
        return ['Unknown', 'grey']
    }
  })()

  return (
    <Space>
      <Badge color={color} />
      <span style={style}>{text ?? title}</span>
    </Space>
  )
}

const dummyData = {
  value: '-',
  change: { percentage: '-' },
}

export const getShortnameValue = (shortnameData, precision) => {
  if (!shortnameData) {
    return dummyData.value
  }

  switch (shortnameData.valueType) {
    case 'percentage':
      return valueToPercentage(shortnameData.value.current)
    case 'number': {
      if (precision) {
        return (
          Math.round(shortnameData.value.current * 10 ** precision) /
          10 ** precision
        )
      }

      return Math.round(shortnameData.value.current)
    }
    default:
      return shortnameData.value.current
  }
}

const getTrendProps = (shortnameData, style) => {
  // Return early if no data or no change
  if (!shortnameData?.change?.percentage) {
    return {}
  }

  const isRoundedChangeNearZero = (() => {
    switch (shortnameData?.valueType) {
      case 'number':
        return (
          shortnameData?.change.absolute < 0.5 &&
          shortnameData?.change.absolute > -0.5
        )
      case 'percentage':
        return (
          shortnameData?.change.absolute < 0.0005 &&
          shortnameData?.change.absolute > -0.0005
        )
      default:
        return false
    }
  })()

  if (isRoundedChangeNearZero) {
    return {}
  }

  const { isHigherBetter, change } = shortnameData
  const isPositiveChange = change.percentage > 0

  const positiveColor = colors.green[8]
  const negativeColor = colors.red[1]

  // Determine if trend should be shown as positive or negative
  const showAsPositive = isHigherBetter ? isPositiveChange : !isPositiveChange

  // Use up arrow for positive trend, down arrow for negative
  const Icon = isPositiveChange ? CaretUpOutlined : CaretDownOutlined
  const color = showAsPositive ? positiveColor : negativeColor

  return {
    prefix: <Icon style={{ color, ...style }} />,
  }
}

export const getChangePercentage = (shortnameData) => {
  if (typeof shortnameData?.change?.percentage === 'undefined') {
    return dummyData.change.percentage
  }

  if (shortnameData.valueType === 'percentage') {
    return `${(shortnameData.change.absolute * 100).toFixed(1)}%`
  }

  return `${(shortnameData.change.percentage * 100).toFixed(1)}%`
}

export const getLangFlag = (lang) => {
  switch (lang) {
    case 'ar':
      return CountryPhoneCodes.find((c) => c.code === 'SA') ?? {}

    case 'en':
      return CountryPhoneCodes.find((c) => c.code === 'US') ?? {}

    case 'hi':
      return CountryPhoneCodes.find((c) => c.code === 'IN') ?? {}

    default:
      return CountryPhoneCodes.find((c) => c.code === lang.toUpperCase()) ?? {}
  }
}

/**
 * Ant design pro components seem to have issues when using custom components with ProCard and StatisticCard.Group as children.
 * Rendering in a row does not work when the custom components are children, so we use this function to reuse prop generation instead.
 */
const getStatsCardProps = ({ config, title, data, statistic }) => {
  return {
    ...(typeof title === 'string'
      ? { title: <CopyableTitle title={title} id={data?.id} /> }
      : {}),
    statistic: {
      ...statistic,
      value: getShortnameValue(data),
      description: (
        <Statistic
          title={
            statistic?.description?.title === null
              ? undefined
              : statistic?.description?.title ??
                `${data?.aggregationType} last ${
                  config?.timeframeInDays ?? '-'
                }d`
          }
          value={getChangePercentage(data)}
          {...getTrendProps(data)}
        />
      ),
    },
  }
}

const ChangePercentageCell = ({ item, fontType = 'micro' }) => {
  const { prefix } = getTrendProps(item)

  return (
    <Text type={fontType}>
      {prefix}
      <Text type={fontType} style={{ marginLeft: 2 }}>
        {typeof item.change.absolute === 'number'
          ? item.change.absolute.toFixed(0)
          : '-'}
      </Text>
    </Text>
  )
}

const DataCell = ({ item }) => {
  const screens = Grid.useBreakpoint()

  return (
    <Box
      transparent
      flexDirection={screens.sm ? 'row' : 'column'}
      alignItems="center">
      <Text semibold type="small" style={{ marginRight: 4 }}>
        {item.value.current ? formatNumber(item.value.current.toFixed(0)) : '-'}
      </Text>
      <ChangePercentageCell item={item} />
    </Box>
  )
}

const onHeaderCellCommon = (getThemedColor) => () => {
  return {
    style: {
      backgroundColor: getThemedColor('#FFF', '#141414'),
      fontSize: 12,
    },
  }
}

// Removes bottom border of the last item
const onCellCreator = (rowsLength) => (record, rowIndex) => ({
  style: {
    ...(rowIndex === rowsLength - 1 ? { borderBottomWidth: 0 } : {}),
    paddingTop: 8,
    paddingBottom: 8,
  },
})

// key supports array of keys to access nested value
const defaultSorter = (key, priority) => ({
  multiple: priority,
  compare: (a, b) => {
    const aValue = R.path(Array.isArray(key) ? key : [key], a)
    const bValue = R.path(Array.isArray(key) ? key : [key], b)

    return aValue < bValue ? -1 : aValue > bValue ? 1 : 0
  },
})

const availableContentGroupTypes = [
  ARTIFICIAL_TELL_GROUP_TYPES.OPEN,
  ARTIFICIAL_TELL_GROUP_TYPES.SIMPLE,
  ARTIFICIAL_TELL_GROUP_TYPES.ORGANIC,
  ARTIFICIAL_TELL_GROUP_TYPES.STATEMENT,
  ARTIFICIAL_TELL_GROUP_TYPES.SHARE,
]

const AllDataTable = () => {
  const availableLanguages = useAvailableLanguages()

  const { data } = useDashboardQuery({ keepPreviousData: true })

  const getThemedColor = hooks.useThemedColor()

  const dataset = React.useMemo(() => {
    if (!data) return []

    return availableLanguages.reduce((acc, lang) => {
      availableContentGroupTypes.forEach((groupType) => {
        const languageLong = DETECTABLE_LANGUAGE[langDetectObjectsByType1[lang]]
        const groupData =
          data?.data[
            `CH-ArTells-ActiveGroups-${languageLong}-${ARTIFICIAL_TELL_GROUP_TYPES[groupType]}`
          ]
        const sendPerGroupData =
          data?.data[
            `CH-ArTells-SentPerActiveGroups-${languageLong}-${ARTIFICIAL_TELL_GROUP_TYPES[groupType]}`
          ]

        if (!groupData || !sendPerGroupData) {
          return
        }

        acc.push({
          id: groupData.id,
          language: lang,
          flag: getLangFlag(lang).flag,
          activeGroups: groupData,
          sendPerGroups: sendPerGroupData,
          groupType,
        })
      })

      return acc
    }, [])
  }, [availableLanguages, data])

  const columns = [
    {
      dataIndex: 'language',
      title: 'Language',
      width: 140,
      align: 'center',
      filters: availableLanguages.map((lang) => ({
        text: lang.toUpperCase(),
        value: lang,
      })),
      filterMultiple: true,
      onFilter: (value, record) => value === record.language,
      render: (text, record) => (
        <span>{`${record.flag} ${text.toUpperCase()}`}</span>
      ),
      sorter: defaultSorter('language', 4),
    },
    {
      title: 'Group Type',
      dataIndex: 'groupType',
      width: 140,
      render: (text) => <StatGroupTypeTitle groupType={text} />,
      sorter: defaultSorter('groupType', 3),
    },
    {
      title: 'Amount Groups',
      dataIndex: 'activeGroups',
      width: 180,
      render: (item) => <span>{formatNumber(item.value.current)}</span>,
      sorter: defaultSorter(['activeGroups', 'value', 'current'], 1),
    },
    {
      title: 'Change groups',
      dataIndex: 'activeGroups',
      key: 'id',
      width: 180,
      render: (item) => <ChangePercentageCell item={item} fontType="small" />,
    },
    {
      title: 'Sent per group / day',
      dataIndex: 'sendPerGroups',
      width: 180,
      render: (item) => <span>{formatNumber(item.value.current)}</span>,
      sorter: defaultSorter(['sendPerGroups', 'value', 'current'], 2),
      defaultSortOrder: 'descend',
    },
    {
      title: 'Change sent',
      dataIndex: 'sendPerGroups',
      key: 'id',
      width: 180,
      render: (item) => <ChangePercentageCell item={item} fontType="small" />,
    },
    // Spacer for larger screens to have data better grouped together
    { key: 'id' },
  ].map((column) => ({
    ...column,
    onHeaderCell: onHeaderCellCommon(getThemedColor),
    onCell: onCellCreator(dataset.length),
  }))

  return (
    <Table
      dataSource={dataset}
      columns={columns}
      bordered={false}
      pagination={false}
      rowKey="id"
      scroll={{ y: 300 }}
    />
  )
}

const GroupTypeByLangTable = () => {
  const dispatch = ReactRedux.useDispatch()
  const availableLanguages = useAvailableLanguages()

  const { data } = useDashboardQuery({ keepPreviousData: true })

  const getThemedColor = hooks.useThemedColor()

  const dataset = React.useMemo(() => {
    if (!data) return []

    return availableLanguages.reduce((acc, lang) => {
      const item = {
        id: lang,
        language: lang,
        flag: getLangFlag(lang).flag,
      }

      availableContentGroupTypes.forEach((groupType) => {
        const languageLong = DETECTABLE_LANGUAGE[langDetectObjectsByType1[lang]]
        const groupData =
          data?.data[
            `CH-ArTells-ActiveGroups-${languageLong}-${ARTIFICIAL_TELL_GROUP_TYPES[groupType]}`
          ]

        item[groupType] = groupData?.value?.current ?? '-'
      })

      acc.push(item)

      return acc
    }, [])
  }, [availableLanguages, data])

  const onRow = (record) => ({
    onClick: () => {
      dispatch(setLanguage(record.language))
    },
  })

  const onHeaderCell = () => {
    return R.mergeDeepRight(onHeaderCellCommon(getThemedColor), {
      style: {
        paddingLeft: 8,
        paddingRight: 8,
      },
    })
  }

  const columns = [
    {
      dataIndex: 'language',
      align: 'center',
      title: <Box style={{ width: 55, height: 4 }} />,
      width: 80,
      render: (text, record) => (
        <span>{`${record.flag} ${text.toUpperCase()}`}</span>
      ),
    },
    ...availableContentGroupTypes.map((groupType) => ({
      dataIndex: groupType,
      align: 'right',
      title: <StatGroupTypeTitle groupType={groupType} />,
      width: 100,
      render: (text) => <span>{formatNumber(text.toFixed(0))}</span>,
    })),
    // Spacer for larger screens to have data better grouped together
    { key: 'id', responsive: ['xxl'] },
  ].map((column) => ({
    ...column,
    onHeaderCell,
    onCell: onCellCreator(dataset.length),
  }))

  return (
    <Table
      dataSource={dataset}
      columns={columns}
      bordered={false}
      pagination={false}
      rowKey="language"
      onRow={onRow}
      scroll={{ x: true }}
    />
  )
}

const GlobalSection = () => {
  const [tab, setTab] = React.useState(ARTIFICIAL_TELL_GROUP_TYPES.OPEN)

  const dispatch = ReactRedux.useDispatch()
  const availableLanguages = useAvailableLanguages()
  const { data, isLoading } = useDashboardQuery({ keepPreviousData: true })

  const getThemedColor = hooks.useThemedColor()

  const datasetByGroupType = React.useMemo(() => {
    if (!data) return []

    return availableLanguages.reduce((acc, lang) => {
      const languageLong = DETECTABLE_LANGUAGE[langDetectObjectsByType1[lang]]
      const groupData =
        data?.data[
          `CH-ArTells-ActiveGroups-${languageLong}-${ARTIFICIAL_TELL_GROUP_TYPES[tab]}`
        ]
      const sendPerGroupData =
        data?.data[
          `CH-ArTells-SentPerActiveGroups-${languageLong}-${ARTIFICIAL_TELL_GROUP_TYPES[tab]}`
        ]

      if (!groupData || !sendPerGroupData) {
        return acc
      }

      acc.push({
        id: groupData.id,
        language: lang,
        flag: getLangFlag(lang).flag,
        activeGroups: groupData,
        sendPerGroups: sendPerGroupData,
      })

      return acc
    }, [])
  }, [availableLanguages, data, tab])

  const maxAmountGroups = Math.max(
    ...datasetByGroupType.map((item) => item.activeGroups.value.current)
  )

  const onRow = (record) => ({
    onClick: () => {
      dispatch(setLanguage(record.language))
    },
  })

  const onHeaderCell = () => {
    return R.mergeDeepRight(onHeaderCellCommon(getThemedColor), {
      style: {
        paddingLeft: 8,
        paddingRight: 8,
      },
    })
  }

  const columns = [
    {
      dataIndex: 'language',
      key: 'id',
      width: 120,
      align: 'center',
      render: (text, record) => (
        <span>{`${record.flag} ${text.toUpperCase()}`}</span>
      ),
    },
    {
      dataIndex: 'activeGroups',
      key: 'id',
      width: 280,
      responsive: ['lg'],
      render: (item) => (
        <Progress
          type="line"
          status="active"
          showInfo={false}
          percent={(item.value.current * 100) / maxAmountGroups}
        />
      ),
    },
    {
      title: 'Amount Groups',
      key: 'id',
      dataIndex: 'activeGroups',
      width: 160,
      render: (item) => <DataCell item={item} />,
    },
    {
      title: 'Sent per group / day',
      key: 'id',
      dataIndex: 'sendPerGroups',
      width: 160,
      render: (item) => <DataCell item={item} />,
    },
  ]
    .filter(Boolean)
    .map((column) => ({
      ...column,
      onHeaderCell,
      onCell: onCellCreator(datasetByGroupType.length),
    }))

  return (
    <ProCard ghost gutter={[12, 12]} wrap style={{ marginBottom: 24 }}>
      <ProCard
        bodyStyle={!isLoading ? { padding: 0 } : {}}
        bordered
        colSpan={{ lg: 24, xl: 11, xxl: 8 }}
        title="Active Intro Groups"
        headerBordered
        loading={isLoading}
        extra={
          <InfoTooltip description="Includes all active intro groups of a market, excluding those that are time limited. Group Amount numbers are updated once per day and represents the counts from yesterday." />
        }>
        <GroupTypeByLangTable />
      </ProCard>

      <ProCard
        title="Active Intro Groups: By Group Type"
        headerBordered
        bordered
        colSpan={{ lg: 24, xl: 13, xxl: 14 }}
        extra={
          <InfoTooltip description="Includes all active intro groups of a market, excluding those that are time limited. Group Amount numbers are updated once per day and represents the counts from yesterday. Sent per group / day represents an average of the last seven days of sends, divided by the amount of groups from yesterday. The comparison is towards the week prior. The number gives an estimate on how often an average group is sent every day. The higher the more often users receive it per day. It gives us an indication for market needs, highlighting high engagement markets with low group amounts." />
        }
        tabs={{
          tabPosition: 'left',
          activeKey: tab,
          cardProps: {
            loading: isLoading,
            ghost: !isLoading, // To maintain padding for the loading state of the card
          },
          items: availableContentGroupTypes.map((groupType) => ({
            label: <StatGroupTypeTitle groupType={groupType} />,
            key: groupType,
            children: (
              <ProCard ghost>
                <Table
                  dataSource={datasetByGroupType}
                  columns={columns}
                  bordered={false}
                  pagination={false}
                  rowKey="language"
                  onRow={onRow}
                />
              </ProCard>
            ),
          })),
          onChange: (key) => {
            setTab(key)
          },
        }}
      />

      <ProCard
        bodyStyle={!isLoading ? { padding: 0 } : {}}
        bordered
        collapsible
        colSpan={24}
        defaultCollapsed
        headerBordered
        loading={isLoading}
        size="small"
        title="All countries group type data"
        extra={
          <InfoTooltip
            data={data}
            description="Shows the raw data of amount groups and sent per group per day for each language and each type. Use this for more fine grained investigations. For an easier overview see the cards below. All use the same underlaying data."
          />
        }>
        <AllDataTable />
      </ProCard>
    </ProCard>
  )
}

const dataFilters = ['top', 'p80', 'p50', 'worst']

const dataFiltersLabel = {
  top: 'Top AR',
  p80: 'P80 AR',
  p50: 'P50 AR',
  worst: 'Worst AR',
}

const CardAvailableContent = ({ userGroupType }) => {
  const { data } = useDashboardQuery()

  const userGroupTypeString = ARTIFICIAL_TELL_USER_GROUP_TYPE[userGroupType]

  return (
    <ProCard ghost wrap>
      {availableContentGroupTypes.map((groupType, index) => {
        const groupTypeString = ARTIFICIAL_TELL_GROUP_TYPES[groupType]

        const shortnameData =
          data?.groupAnswerRateData[userGroupTypeString]?.[groupTypeString]

        if (!shortnameData) {
          return null
        }

        return (
          <StatisticCard
            key={`${shortnameData?.id ?? index}-${groupType}`}
            colSpan={{ md: 24, lg: 8, xl: '20%' }}
            title={<StatGroupTypeTitle groupType={groupType} />}
            footer={dataFilters.map((filter, index) => (
              <Box
                key={filter}
                transparent
                flexDirection="row"
                justifyContent="space-between"
                marginTop={index > 0 ? 4 : 0}>
                <Text type="small">{dataFiltersLabel[filter]}</Text>
                <Text type="small">
                  {valueToPercentage(shortnameData[filter])}
                </Text>
              </Box>
            ))}
            bodyStyle={{ paddingTop: 0 }}
          />
        )
      })}
    </ProCard>
  )
}

const twoStatsCardColSpan = { xl: 12, xxl: 8 }
const twoStatsColSpan = { xs: 24, sm: 12 }

const userGroupTypes = [
  ARTIFICIAL_TELL_USER_GROUP_TYPE.LOW,
  ARTIFICIAL_TELL_USER_GROUP_TYPE.HIGH,
  ARTIFICIAL_TELL_USER_GROUP_TYPE.REAL,
]

const noContentGroupTypes = [
  'ALL',
  ARTIFICIAL_TELL_GROUP_TYPES.OPEN,
  ARTIFICIAL_TELL_GROUP_TYPES.SIMPLE,
  ARTIFICIAL_TELL_GROUP_TYPES.ORGANIC,
  ARTIFICIAL_TELL_GROUP_TYPES.STATEMENT,
  'ContentTags',
]

const SectionAvailableContent = () => {
  const [tab, setTab] = React.useState(ARTIFICIAL_TELL_USER_GROUP_TYPE.HIGH)

  const { data, isLoading } = useDashboardQuery()

  const getThemedColor = hooks.useThemedColor()

  const onPressOpenChart = (groupType) => () => {
    const groupTypeString = ARTIFICIAL_TELL_GROUP_TYPES[groupType] ?? groupType

    const defaultParams = {
      timeInterval: 'day',
      entries: [
        ['event_time', '>', dayjs().subtract(14, 'day').unix()],
        ['event_time', '<', dayjs().unix()],
      ],
      selectedTable: 'backend_tell_artificial_no_content',
    }

    let params

    switch (groupType) {
      case 'ALL': {
        params = defaultParams
        break
      }

      case 'ContentTags': {
        params = {
          ...defaultParams,
          entries: [...defaultParams.entries, ['content_tags', '!=', 'NONE']],
          groupBy: 'content_tags',
        }
        break
      }

      default: {
        params = {
          ...defaultParams,
          entries: [
            ...defaultParams.entries,
            ['group_type', '==', groupTypeString],
          ],
        }
        break
      }
    }

    window.open(`/stats/chevents?${qs.stringify(params)}`, '_blank')
  }

  return (
    <ProCard ghost gutter={[12, 12]} wrap>
      <ProCard
        bordered
        colSpan={{ xl: 24, xxl: 24 }}
        extra={
          <InfoTooltip
            data={data}
            description="Includes all active intro groups of a market, excluding those that are time limited. Group Amount numbers are updated once per day and represents the counts from yesterday.
Top AR is the best answer rate of all our groups. P80 means that 20% of our active intro groups have a better answer rate, 80% a worse one.
Median is the same but for 50% better and 50% worse.
Worst represents the lowest answer rate of all groups.
Median Change compares to the week prior."
          />
        }
        headerBordered
        title="Active Intro Groups: Group Answer Rate Overview"
        tabs={{
          tabPosition: 'left',
          activeKey: tab,
          cardProps: {
            loading: isLoading,
          },
          items: userGroupTypes.map((userGroupType) => ({
            label: capitalize(userGroupTypeLabelMap[userGroupType]),
            key: userGroupType,
            children: <CardAvailableContent userGroupType={userGroupType} />,
          })),
          onChange: (key) => {
            setTab(key)
          },
        }}
      />
      <ProCard
        bordered
        colSpan={{ xl: 12, xxl: 16 }}
        extra={
          <InfoTooltip
            data={data}
            description={
              'The amount represents total amount of events in the last 7 days, where we tried sending a Tell with certain specification (i.e. SIMPLE only) and failed to find an available group that matched those specifications. In most cases, the user still receives a Tell in such situations, but not of the type we desired. "No Limitations" means that those users really did not receive any artificial Tell, because there is no content available that they have not received yet. Content_Tag sends are excluded here.'
            }
          />
        }
        headerBordered
        loading={isLoading}
        title="Failed Tell Sending">
        <ProCard.Group wrap>
          {noContentGroupTypes.map((groupType, index) => {
            const groupTypeString =
              ARTIFICIAL_TELL_GROUP_TYPES[groupType] ?? groupType
            const shNoContent = `CH-ArTells-Sent-NoContent${
              groupType === 'ContentTags' ? '' : '-ALL'
            }-${groupTypeString}`

            const shortnameData = data?.data[shNoContent]

            return (
              <StatisticCard
                colSpan={{ xs: 12, sm: 8, xxl: 4 }}
                key={shortnameData?.id ?? index}
                title={<StatGroupTypeTitle groupType={groupType} />}
                statistic={{ value: getShortnameValue(shortnameData) }}
                extra={
                  <LineChartOutlined
                    onClick={onPressOpenChart(groupType)}
                    style={{
                      color: getThemedColor(undefined, colors.white[1]),
                    }}
                  />
                }
              />
            )
          })}
        </ProCard.Group>
      </ProCard>

      <ProCard
        bordered
        colSpan={twoStatsCardColSpan}
        extra={
          <InfoTooltip
            data={data}
            description="This number represents the percentage of tell sends with HIGH_AR mode, where the group answer rate of the sent group was worse than the p50 group or worse than the P20 Group Answer Rate. It essentially means that HIGH_AR did not really work, as the user received all good groups already and they received a question that is in the bottom half of our available content."
          />
        }
        headerBordered
        loading={isLoading}
        title="HIGH_AR Sends with Bad Answer Rates">
        <ProCard.Group wrap>
          <StatisticCard
            colSpan={twoStatsColSpan}
            key={data?.data['CH-ArTells-Sent-HIGH_AR-Low-AR'].id}
            title={
              <CopyableTitle
                title="<= P50 Group Answer Rate"
                id={data?.data['CH-ArTells-Sent-HIGH_AR-Low-AR'].id}
              />
            }
            {...getStatsCardProps({
              config: data?.config,
              data: data?.data['CH-ArTells-Sent-HIGH_AR-Low-AR'],
            })}
          />
          <StatisticCard
            colSpan={twoStatsColSpan}
            key={data?.data['CH-ArTells-Sent-HIGH_AR-Very-Low-AR'].id}
            title={
              <CopyableTitle
                title="<= P20 Group Answer Rate"
                id={data?.data['CH-ArTells-Sent-HIGH_AR-Very-Low-AR'].id}
              />
            }
            {...getStatsCardProps({
              config: data?.config,
              data: data?.data['CH-ArTells-Sent-HIGH_AR-Very-Low-AR'],
            })}
          />
        </ProCard.Group>
      </ProCard>
    </ProCard>
  )
}

const SectionUserData = () => {
  const { data, isLoading } = useDashboardQuery()

  return (
    <ProCard ghost gutter={[12, 12]} wrap>
      <ProCard
        bordered
        wrap
        colSpan={twoStatsCardColSpan}
        extra={
          <InfoTooltip
            data={data}
            aggregationType={
              data?.data['CH-Answer-Create-Weekly']?.aggregationType
            }
            description="Users that were active / answered in the last seven days."
          />
        }
        headerBordered
        loading={isLoading}
        title="Active Users">
        <StatisticCard
          colSpan={twoStatsColSpan}
          {...getStatsCardProps({
            title: 'Active Answerer / week',
            config: data?.config,
            data: data?.data['CH-Answer-Create-Weekly'],
          })}
        />
        <StatisticCard
          colSpan={twoStatsColSpan}
          {...getStatsCardProps({
            title: 'Total WAUs',
            config: data?.config,
            data: data?.data['CH-Active-WAUs'],
          })}
        />
      </ProCard>

      <ProCard
        bordered
        wrap
        colSpan={twoStatsCardColSpan}
        extra={
          <InfoTooltip
            data={data}
            aggregationType={data?.data['CH-Tell-Answer-DAUs']?.aggregationType}
            description="Percentage of active users that answered on a given day / within the 7 days before."
          />
        }
        headerBordered
        loading={isLoading}
        title="Active Users Answering">
        <StatisticCard
          colSpan={twoStatsColSpan}
          {...getStatsCardProps({
            title: '% DAUs',
            config: data?.config,
            data: data?.data['CH-Tell-Answer-DAUs'],
          })}
        />
        <StatisticCard
          colSpan={twoStatsColSpan}
          {...getStatsCardProps({
            title: '% WAUs',
            config: data?.config,
            data: data?.data['CH-Tell-Answer-WAUs'],
          })}
        />
      </ProCard>

      <ProCard
        bordered
        wrap
        colSpan={twoStatsCardColSpan}
        extra={
          <InfoTooltip
            data={data}
            aggregationType={
              data?.data['CH-ShareCards-Share-Open']?.aggregationType
            }
            description="Percentage of active users that shared their profile / answer on a given day."
          />
        }
        headerBordered
        loading={isLoading}
        title="Active Users Sharing">
        <StatisticCard
          colSpan={twoStatsColSpan}
          {...getStatsCardProps({
            title: 'Profile',
            config: data?.config,
            data: data?.data['CH-ShareCards-Share-Open'],
          })}
        />
        <StatisticCard
          colSpan={twoStatsColSpan}
          {...getStatsCardProps({
            title: 'Answers',
            config: data?.config,
            data: data?.data['CH-Share-Answer-Open'],
          })}
        />
      </ProCard>
    </ProCard>
  )
}

const userGroupStatsColSpan = { xs: 24, md: 24, lg: 8, xxl: 12 }

const UserGroupStats = ({ userGroupType }) => {
  const { data } = useDashboardQuery()

  const userGroupTypeString = userGroupTypeShortnameMap[userGroupType]

  return (
    <StatisticCard.Group wrap>
      <StatisticCard
        colSpan={userGroupStatsColSpan}
        {...getStatsCardProps({
          title: 'Total Tells Sent',
          config: data?.config,
          data: data?.data[`CH-ArTells-Sent-${userGroupTypeString}`],
        })}
      />
      <StatisticCard
        colSpan={userGroupStatsColSpan}
        {...getStatsCardProps({
          title: 'P50 Group Answer Rate',
          config: data?.config,
          data: data?.data[
            `CH-ArTells-GroupRate-Median-${userGroupTypeString}-ALL`
          ],
        })}
      />
      <StatisticCard
        colSpan={userGroupStatsColSpan}
        {...getStatsCardProps({
          title: 'P80 Group Answer Rate',
          config: data?.config,
          data: data?.data[
            `CH-ArTells-GroupRate-P80-${userGroupTypeString}-ALL`
          ],
        })}
      />
      <StatisticCard
        colSpan={userGroupStatsColSpan}
        {...getStatsCardProps({
          title: 'Answer Rate',
          config: data?.config,
          data: data?.data[
            `CH-ArTells-AnswerRate-Total-${userGroupTypeString}`
          ],
        })}
      />
    </StatisticCard.Group>
  )
}

const scheduleStatsColSpan = { xs: 24, md: 24, lg: 8, xxl: 12 }

const ScheduleStats = ({ schedule }) => {
  const { data } = useDashboardQuery()

  const parsedSchedule = schedule.replace(' ', '-')

  return (
    <ProCard ghost wrap>
      <StatisticCard
        colSpan={scheduleStatsColSpan}
        {...getStatsCardProps({
          title: 'Total Tells Sent',
          config: data?.config,
          data: data?.data[`CH-ArTells-Sent-Schedule-${parsedSchedule}`],
        })}
      />
      <StatisticCard
        colSpan={scheduleStatsColSpan}
        {...getStatsCardProps({
          title: 'P50 Group Answer Rate',
          config: data?.config,
          data: data?.data[
            `CH-ArTells-AnswerRate-Median-Schedule-${parsedSchedule}`
          ],
        })}
      />
      <StatisticCard
        colSpan={scheduleStatsColSpan}
        {...getStatsCardProps({
          title: 'P80 Group Answer Rate',
          config: data?.config,
          data: data?.data[
            `CH-ArTells-AnswerRate-P80-Schedule-${parsedSchedule}`
          ],
        })}
      />
      <StatisticCard
        colSpan={scheduleStatsColSpan}
        {...getStatsCardProps({
          title: 'Answer Rate',
          config: data?.config,
          data: data?.data[
            `CH-ArTells-AnswerRate-Total-Schedule-${parsedSchedule}`
          ],
        })}
      />
    </ProCard>
  )
}

const userGroups = [
  'LURKER',
  ARTIFICIAL_TELL_USER_GROUP_TYPE.LOW,
  ARTIFICIAL_TELL_USER_GROUP_TYPE.HIGH,
  'ALL',
]

const userGroupAndScheduleCardColSpan = { xl: 24, xxl: 12 }

const SectionUserGroupAndScheduleData = () => {
  const screens = Grid.useBreakpoint()
  const [tabUserGroups, setTabUserGroups] = React.useState('ALL')
  const [tabSchedules, setTabSchedules] = React.useState()

  const { data, isLoading } = useDashboardQuery()

  React.useEffect(() => {
    setTabSchedules(data?.config?.schedules?.[0])
  }, [data?.config?.schedules])

  return (
    <ProCard ghost gutter={[12, 12]} wrap>
      <ProCard
        bordered
        colSpan={userGroupAndScheduleCardColSpan}
        extra={
          <InfoTooltip
            data={data}
            aggregationType={data?.data['CH-ArTells-Sent-ALL']?.aggregationType}
            description="Shows the total amount of artificial tell sending that happened in the given language on a given day. P50 represents the group answer rate of the p50 send event. 50% of all sends on a day are sent with groups that have a better group answer rate than that. Answer Rate represents the actually answered questions of those Tells Sent within 24-48 hours."
          />
        }
        headerBordered
        title="Artificial Tell Sending by User Group"
        tabs={{
          tabPosition: 'left',
          activeKey: tabUserGroups,
          cardProps: {
            loading: isLoading,
          },
          items: userGroups.map((userGroupType) => ({
            label: capitalize(userGroupTypeLabelMap[userGroupType]),
            key: userGroupType,
            children: <UserGroupStats userGroupType={userGroupType} />,
          })),
          onChange: (key) => {
            setTabUserGroups(key)
          },
        }}
      />
      <ProCard
        bordered
        colSpan={userGroupAndScheduleCardColSpan}
        extra={
          <InfoTooltip
            data={data}
            aggregationType={
              data?.data['CH-ArTells-Sent-Schedule-SCHEDULE-LURKER']
                ?.aggregationType
            }
            description="Shows the artificial tell sending that happened in the given language on a given day in each schedule. You can find an overview of schedules in the Miro Schedule Docs (see link on top of this page). P50 represents the group answer rate of the p50 send event. 50% of all sends on a day are sent with groups that have a better group answer rate than that. Answer Rate represents the actually answered questions of those Tells Sent within 24-48 hours."
          />
        }
        headerBordered
        title="Artificial Tell Sending by Schedule"
        tabs={{
          style: { height: screens.lg ? 360 : 563 }, // 563 is manually measured with page inspector, size of all ScheduleStats cards being rendered vertically
          tabPosition: 'left',
          activeKey: tabSchedules,
          cardProps: {
            loading: isLoading,
          },
          items: (data?.config?.schedules ?? []).map((schedule) => ({
            label: capitalize(schedule.toLowerCase()),
            key: schedule,
            children: <ScheduleStats schedule={schedule} />,
          })),
          onChange: (key) => {
            setTabSchedules(key)
          },
        }}
      />
    </ProCard>
  )
}

const StatsTab = () => {
  return (
    <ProCard ghost>
      <SectionAvailableContent />
      <SectionUserData />
      <SectionUserGroupAndScheduleData />
    </ProCard>
  )
}

export const Header = ({ title }) => {
  return (
    <Box flex={1} paddingRight={32}>
      <Typography.Title>{title}</Typography.Title>
      <Box flexDirection="row">
        <Typography.Link
          href="https://miro.com/app/board/uXjVL5SMElk=/"
          target="_blank">
          <ExportOutlined /> Control Center
        </Typography.Link>
        <Typography.Link
          href="https://miro.com/app/board/uXjVNVgHtuA=/?moveToWidget=3458764568727385660&cot=14"
          target="_blank"
          style={styleSheets.margin.left[24]}>
          <ExportOutlined /> ModCP system docs
        </Typography.Link>
        <Typography.Link
          href="https://miro.com/app/board/uXjVMx5a11s=/"
          target="_blank"
          style={styleSheets.margin.left[24]}>
          <ExportOutlined /> Schedule docs
        </Typography.Link>
      </Box>
    </Box>
  )
}

const styleOverride = {
  '.ant-tabs-nav-operations': {
    // removes the extra element for the scrollable tabs in Schedule Data
    display: 'none !important',
  },
}

export const PageArtificialTellsDashboard = () => {
  const dispatch = ReactRedux.useDispatch()
  const screens = Grid.useBreakpoint()
  const containerStyle = hooks.usePageContainerStyle()
  const activeLanguage = ReactRedux.useSelector(getLanguage)
  const availableLanguages = useAvailableLanguages()

  const getThemedColor = hooks.useThemedColor()

  const changeActiveLanguage = (lang) => {
    dispatch(setLanguage(lang))
  }

  return (
    <>
      <Style rules={styleOverride} />
      <ScrollView
        style={containerStyle}
        contentContainerStyle={{
          flexGrow: 1,
          backgroundColor: getThemedColor(colors.grey[3], colors.black[5]),
          paddingBottom: 240,
        }}>
        <Box
          paddingTop={32}
          paddingHorizontal={32}
          paddingBottom={16}
          flexDirection={screens.xxl ? 'row' : 'column'}
          borderBottomWidth={1}
          borderBottomStyle="solid"
          borderColor={getThemedColor(colors.grey[4], colors.black[5])}
          marginBottom={24}>
          <Header title="Dashboard" />
        </Box>

        <Box
          transparent
          paddingHorizontal={screens.md ? 32 : 4}
          maxWidth={2150}>
          <GlobalSection />

          <Box
            backgroundColor={getThemedColor(colors.grey[3], colors.black[5])}
            position="sticky"
            top={0}
            zIndex={10}>
            <Tabs
              activeKey={activeLanguage}
              onChange={changeActiveLanguage}
              size="large"
              items={availableLanguages.map((lang) => {
                const { flag } = getLangFlag(lang)

                return {
                  label: `${flag} ${lang.toUpperCase()}`,
                  key: lang,
                }
              })}
              style={{ position: 'sticky', top: 0, zIndex: 10 }}
            />
          </Box>

          <StatsTab />
        </Box>
      </ScrollView>
    </>
  )
}
