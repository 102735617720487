import { colors } from '@tellonym/core/common/colorSystem'
import { Button, Grid, Input, Typography } from 'antd'
import dayjs from 'dayjs'
import React from 'react'
import { Box, hooks } from '../../common'
import {
  useGptGroupAnalysisResult,
  useGptGroupAnalysisStartMutation,
} from '../queries'

/**
 * Either topic or group is defined
 */
export const GptAnalysisBox = ({ topic, group, language }) => {
  const interval = React.useRef(null)
  const [isPollingForResult, setIsPollingForResult] = React.useState(false)

  const screens = Grid.useBreakpoint()

  const getThemedColor = hooks.useThemedColor()

  const { isLoading: isLoadingStart, mutate } =
    useGptGroupAnalysisStartMutation({
      groupId: group?.id,
      topicId: topic?.id,
      language,
      onSuccess: () => {
        setIsPollingForResult(true)
      },
    })

  const {
    data,
    isLoading: isLoadingResult,
    refetch,
  } = useGptGroupAnalysisResult({
    groupId: group?.id,
    topicId: topic?.id,
    language,
  })

  const gptAnalysisString = React.useMemo(() => {
    if (typeof data?.summary !== 'string' && data?.summary !== '') {
      return ''
    }

    return `${data.summary}\n\nIncluded answers: ${data.includedAnswers}`
  }, [data])

  React.useEffect(() => {
    if (isPollingForResult && !interval.current) {
      interval.current = setInterval(() => {
        if (data?.summary !== 'string') {
          refetch()
        } else {
          // safeguard but this does not seem to be executed, he uses the cleanup function bc data?.summary changes
          setIsPollingForResult(false)

          clearInterval(interval.current)
        }
      }, 5000)

      return () => {
        setIsPollingForResult(false)
        clearInterval(interval.current)
      }
    } else if (
      isPollingForResult &&
      interval.current &&
      data?.summary === 'string'
    ) {
      setIsPollingForResult(false)
      clearInterval(interval.current)
    }
  }, [data?.summary, isPollingForResult, refetch])

  return (
    <Box transparent height={screens.lg ? '100%' : 260}>
      <Input.TextArea
        style={{
          flex: 1,
          resize: 'none',
          borderColor: getThemedColor(colors.grey[4], colors.black[4]),
        }}
        value={gptAnalysisString}
        placeholder="Click 'GPT analysis' to let ChatGPT analyze this groups performance based on answers that users created..."
      />

      <Box
        transparent
        alignItems="center"
        justifyContent="flex-end"
        flexDirection="row"
        marginTop={8}>
        {data?.lastUpdatedAt && (
          <Typography.Text type="secondary" style={{ marginRight: 12 }}>
            generated {dayjs(data?.lastUpdatedAt).fromNow()}
          </Typography.Text>
        )}
        <Button
          size="small"
          onClick={mutate}
          loading={isLoadingStart || isLoadingResult || isPollingForResult}>
          GPT analysis
        </Button>
      </Box>
    </Box>
  )
}
