import { langDetectObjectsByType1 } from '@tellonym/enums/lib/Language'
import * as ReactRedux from 'react-redux'
import { getPermissions } from '../../app/selectors'
import * as helpers from '../../common/helpers'
import { supportedLanguages } from '../constants'

export const useAvailableLanguages = (props) => {
  const { languages = supportedLanguages } = props ?? {}

  const permissions = ReactRedux.useSelector(getPermissions)

  const supportedLanguagesWithPermission = languages.filter(
    (lang) =>
      lang === 'en' ||
      helpers.checkPermission(
        `artificialtells.edit.lang.${langDetectObjectsByType1[lang]}`,
        permissions
      )
  )

  return supportedLanguagesWithPermission
}
