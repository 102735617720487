import { CloseOutlined, RightOutlined, SearchOutlined } from '@ant-design/icons'
import { colors } from '@tellonym/core/common/colorSystem'
import { debounce } from '@tellonym/core/helpers'
import { copyToClipboard } from '@tellonym/core/share/actions'
import { Checkbox, Input, Spin, Tooltip } from 'antd'
import { drop } from 'ramda'
import React from 'react'
import * as ReactRedux from 'react-redux'
import { Box, Text, TouchableOpacity, View, styleSheets } from '../../common'
import * as hooks from '../../common/hooks'
import { refreshChShortnames } from '../actions'
import { useShortnamesMainGroupsQuery, useShortnamesQuery } from '../queries'

const maxShortNamesCount = 500

const SelectedGroupItem = ({ groupName, groupId, onPressRemove }) => {
  const getThemedColor = hooks.useThemedColor()

  return (
    <Box flexDirection="row" padding={8}>
      <TouchableOpacity
        onPress={() => onPressRemove(groupId)}
        style={{ marginRight: 6, marginBottom: 2, justifyContent: 'center' }}>
        <CloseOutlined
          style={{
            color: getThemedColor(colors.grey[6], colors.grey[7]),
            fontSize: 10,
          }}
        />
      </TouchableOpacity>
      <Text type="note" color={getThemedColor(colors.grey[6], colors.grey[7])}>
        {`Group: ${groupName}`}
      </Text>
    </Box>
  )
}

const SelectedShortNameItem = ({
  shortName,
  onDragStart,
  onDragEnd,
  onDragEnter,
  onPressRemove,
}) => {
  const dispatch = ReactRedux.useDispatch()
  const getThemedColor = hooks.useThemedColor()
  const [isDragging, setIsDragging] = React.useState(false)

  const handleDragStart = () => {
    setIsDragging(true)
    onDragStart(shortName)
  }

  const handleDragEnd = () => {
    setIsDragging(false)
    onDragEnd()
  }

  const handleDragEnter = (e) => {
    e.preventDefault()

    if (!isDragging) {
      onDragEnter(shortName)
    }
  }

  const handleDragOver = (e) => {
    e.preventDefault()
  }

  const onPressText = () => {
    dispatch(copyToClipboard(shortName))
  }

  return (
    <Box
      draggable
      onDragStart={handleDragStart}
      onDragEnd={handleDragEnd}
      onDragEnter={handleDragEnter}
      onDragOver={handleDragOver}
      flexDirection="row"
      padding={8}>
      <TouchableOpacity
        onPress={() => onPressRemove(shortName)}
        style={{ marginRight: 6, marginBottom: 2, justifyContent: 'center' }}>
        <CloseOutlined
          style={{
            color: getThemedColor(colors.grey[6], colors.grey[7]),
            fontSize: 10,
          }}
        />
      </TouchableOpacity>
      <Text
        onPress={onPressText}
        type="note"
        color={getThemedColor(colors.grey[6], colors.grey[7])}>
        {shortName}
      </Text>
    </Box>
  )
}

let currentlyDragging

const SelectedShortNames = React.memo(
  ({
    setSelectedShortnames,
    selectedShortnames,
    selectedGroupIds,
    setSelectedGroupIds,
    groups,
  }) => {
    const onPressRemove = (shortName) => {
      setSelectedShortnames((sn) => sn.filter((i) => i !== shortName))
    }

    const onPressRemoveGroup = (groupId) => {
      setSelectedGroupIds((ids) => ids.filter((id) => id !== groupId))
    }

    const onDragEnd = () => {
      currentlyDragging = undefined
    }

    const onDragStart = (shortName) => {
      currentlyDragging = shortName
    }

    const onDragEnter = (shortName) => {
      if (currentlyDragging && shortName !== currentlyDragging) {
        const dropItemIndex = selectedShortnames.findIndex(
          (i) => i === shortName
        )
        const currentlyDraggingItemIndex = selectedShortnames.findIndex(
          (i) => i === currentlyDragging
        )

        const _orderedList = selectedShortnames.reduce((acc, curr, index) => {
          if (index === dropItemIndex) {
            if (currentlyDraggingItemIndex > dropItemIndex) {
              return [...acc, currentlyDragging, shortName]
            }

            return [...acc, shortName, currentlyDragging]
          }

          if (curr === currentlyDragging || curr === shortName) {
            return acc
          }

          return [...acc, curr]
        }, [])

        setSelectedShortnames(_orderedList)
      }
    }

    return (
      <>
        {selectedGroupIds.map((groupId) => (
          <SelectedGroupItem
            key={groupId}
            groupId={groupId}
            groupName={groups?.data[groupId]?.groupName ?? 'loading...'}
            onPressRemove={onPressRemoveGroup}
          />
        ))}
        {selectedShortnames.map((shortName) => (
          <SelectedShortNameItem
            key={shortName}
            shortName={shortName}
            onPressRemove={onPressRemove}
            onDragEnter={onDragEnter}
            onDragEnd={onDragEnd}
            onDragStart={onDragStart}
          />
        ))}
      </>
    )
  }
)

const getAllShortNamesFromGroups = (groups) => {
  const shortNamesData = groups.ids.reduce((acc, id) => {
    const group = groups.data[id].shortNames
    return {
      ...acc,
      ...group.reduce((acc, curr) => ({ ...acc, [curr.shortName]: curr }), {}),
    }
  }, {})

  return Object.values(shortNamesData)
}

const SearchInput = ({ onChangeText }) => {
  const inputRef = React.useRef(null)
  const [isFocused, setIsFocused] = React.useState(false)
  const [searchValue, setSearchValue] = React.useState('')

  const updateParent = debounce((searchValue) => {
    if (typeof onChangeText === 'function') {
      onChangeText(searchValue)
    }
  }, 600)

  const onChangeSearch = React.useCallback(
    (e) => {
      const searchValue = e.target.value
      updateParent(searchValue)
      setSearchValue(searchValue)
    },
    [updateParent]
  )

  const onBlur = React.useCallback(() => {
    setIsFocused(false)
  }, [])

  const onFocus = React.useCallback(() => {
    setIsFocused(true)
  }, [])

  const onUnfocusedKeyDown = React.useCallback(
    (e) => {
      const fKeyCode = 70
      const isCmdPressed = e.metaKey || e.ctrlKey
      const isShiftPressed = e.shiftKey

      const isKeyCombiPressed =
        e.keyCode === fKeyCode && isCmdPressed && !isShiftPressed
      const shouldFocus = !isFocused && isKeyCombiPressed && inputRef.current

      if (shouldFocus) {
        e.preventDefault()
        inputRef.current.focus()
      }
    },
    [isFocused]
  )

  const onKeyDown = React.useCallback(
    (e) => {
      const isEscPress = e.keyCode === 27

      if (isEscPress) {
        onChangeSearch({ target: { value: '' } })
        inputRef.current.blur()
      }
    },
    [onChangeSearch]
  )

  React.useEffect(() => {
    const ref = inputRef.current

    if (inputRef.current) {
      inputRef.current.input.addEventListener('keydown', onKeyDown)
    }

    window.addEventListener('keydown', onUnfocusedKeyDown)

    return () => {
      ref?.input.removeEventListener('keydown', onKeyDown)

      window.removeEventListener('keydown', onUnfocusedKeyDown)
    }
  }, [onKeyDown, onUnfocusedKeyDown])

  return (
    <Tooltip
      placement="left"
      title="You can use multiple search values separated by spaces. If either short name or name include all the strings you write, it is considered a result. Use !mystring to exclude strings. If either short name or name includes this string it won't be shown.">
      <Input
        ref={inputRef}
        bordered={false}
        onBlur={onBlur}
        onChange={onChangeSearch}
        onFocus={onFocus}
        placeholder="Search..."
        prefix={<SearchOutlined />}
        value={searchValue}
        style={styleSheets.flex[1]}
      />
    </Tooltip>
  )
}

const Column = ({ children, flex = 1 }) => {
  const getThemedColor = hooks.useThemedColor()

  return (
    <Box
      flex={flex}
      borderColor={getThemedColor(colors.grey[4], colors.black[4])}
      borderRightWidth={0}
      borderTopWidth={0}
      borderBottomWidth={0}
      borderLeftWidth={1}
      borderStyle="solid"
      overflowY="auto"
      whiteSpace="nowrap"
      maxHeight={225}>
      {children}
    </Box>
  )
}

const TextItem = ({ children, onPress, isSelected }) => {
  const [isHovering, setIsHovering] = React.useState(false)

  const getThemedColor = hooks.useThemedColor()

  const toggleIsHovering = () =>
    setIsHovering((currentIsHovering) => !currentIsHovering)

  return (
    <Box
      onPress={onPress}
      justifyContent="space-between"
      alignItems="center"
      flex={1}
      flexDirection="row"
      paddingHorizontal={8}
      paddingVertical={4}
      onMouseEnter={toggleIsHovering}
      onMouseLeave={toggleIsHovering}
      backgroundColor={
        isSelected
          ? getThemedColor(colors.grey[1], colors.black[3])
          : isHovering
          ? getThemedColor(colors.grey[1], colors.black[3])
          : undefined
      }
      style={{
        transition: 'all .5s ease',
        userSelect: 'none',
      }}>
      <Text type="small">{children}</Text>
      <RightOutlined
        style={{
          color: getThemedColor(colors.grey[6], colors.grey[7]),
          fontSize: 10,
        }}
      />
    </Box>
  )
}

const ShortnameItem = ({ item, onChange, isSelected }) => {
  const onChangeShortname = () => {
    onChange(item.shortName)
  }

  return (
    <Box paddingHorizontal={6} paddingVertical={6}>
      <Checkbox
        checked={isSelected}
        onChange={onChangeShortname}
        style={{ marginTop: 1 }}>
        <Tooltip
          title={`${item.shortName ? `${item.shortName} - ` : ''}${
            item.description
          }`}
          placement="right"
          mouseEnterDelay={1}>
          <Text type="small" style={{ userSelect: 'none' }}>
            {item.name}
          </Text>
        </Tooltip>
      </Checkbox>
    </Box>
  )
}

const getSearchParts = (searchValue) => {
  const searchEntries = searchValue
    .split(' ')
    .map((v) => v.trim().toLowerCase())

  const getIsExcludeValue = (entry) => entry.indexOf('!') === 0

  const stringPartsToInclude = searchEntries.filter(
    (entry) => !getIsExcludeValue(entry)
  )
  const stringPartsToExclude = searchEntries
    .filter((entry) => getIsExcludeValue(entry))
    .map((string) => string.slice(1))

  return {
    stringPartsToInclude,
    stringPartsToExclude,
  }
}

const getFilteredGroupIds = ({ groups, searchTerm }) => {
  if (searchTerm === '') {
    return groups.ids
  }

  const { stringPartsToInclude } = getSearchParts(searchTerm)

  const filteredGroupIds = groups.ids.filter((id) =>
    stringPartsToInclude.every((string) =>
      groups.data[id]?.groupName?.toLowerCase().includes(string)
    )
  )

  return filteredGroupIds
}

const getFilteredShortNames = ({ allShortNames, searchTerm }) => {
  if (searchTerm === '') {
    return allShortNames
  }

  const { stringPartsToExclude, stringPartsToInclude } =
    getSearchParts(searchTerm)

  const filteredShortNames = allShortNames.filter(
    ({ name: _name, shortName: _shortName }) => {
      const name = _name.toLowerCase()
      const shortName = _shortName.toLowerCase()

      const getAppliesToSearchConditions = (_stringToTest) => {
        const stringToTest = _stringToTest.toLowerCase()
        const matchesInclude = stringPartsToInclude.every((string) =>
          stringToTest.includes(string)
        )
        const matchesExclude = stringPartsToExclude.some((string) =>
          stringToTest.includes(string)
        )

        return {
          hasExcludeValue: matchesExclude,
          hasAllIncludeValues: matchesInclude,
        }
      }

      const matchName = getAppliesToSearchConditions(name)
      const matchShortName = getAppliesToSearchConditions(shortName)

      if (matchName.hasExcludeValue || matchShortName.hasExcludeValue) {
        return false
      }

      return matchName.hasAllIncludeValues || matchShortName.hasAllIncludeValues
    }
  )

  return filteredShortNames
}

const ShortnameList = React.memo(
  ({ shortnames, selectedShortnames, onChange }) =>
    shortnames.map((item) => (
      <ShortnameItem
        key={item.shortName}
        isSelected={selectedShortnames.includes(item.shortName)}
        item={item}
        onChange={onChange}
      />
    ))
)

const GroupsList = React.memo(
  ({ groupIds, groupData, onPressGroupCreator, selectedGroupId }) =>
    groupIds.map((id) => (
      <TextItem
        key={id}
        onPress={onPressGroupCreator(id)}
        isSelected={selectedGroupId === id}>
        {groupData[id].groupName}
      </TextItem>
    ))
)

export const ShortNamesSelector = ({ isDisabled }) => {
  const dispatch = ReactRedux.useDispatch()

  const hasSetAnyShortname = React.useRef(false)

  const getThemedColor = hooks.useThemedColor()
  const [queryParams, setQueryParams] = hooks.useQueryParams()

  const [selectedShortnames, setSelectedShortnames] = React.useState([])
  const [selectedGroupIds, setSelectedGroupIds] = React.useState([])

  const [searchTerm, setSearchTerm] = React.useState('')
  const [selectedMainGroupId, setSelectedMainGroupId] =
    React.useState(undefined)
  const [selectedGroupId, setSelectedGroupId] = React.useState(undefined)

  /**
   * Be aware that group ids in group are strings and in mainGroups are numbers. This is a legacy mismatch
   * that we need to refactor.
   */
  const { data: groups, isLoading } = useShortnamesQuery()
  const { data: mainGroups } = useShortnamesMainGroupsQuery()

  const allShortNames = React.useMemo(() => {
    return getAllShortNamesFromGroups(groups)
  }, [groups])

  const mainGroupIds = React.useMemo(() => {
    return getFilteredGroupIds({ groups: mainGroups, searchTerm })
  }, [mainGroups, searchTerm])

  const groupIds = React.useMemo(() => {
    if (selectedMainGroupId && searchTerm === '') {
      return mainGroups.data[selectedMainGroupId].groups
    }

    return getFilteredGroupIds({ groups, searchTerm })
  }, [groups, mainGroups.data, searchTerm, selectedMainGroupId])

  const shortnames = React.useMemo(() => {
    if (selectedGroupId && searchTerm === '') {
      return groups.data[selectedGroupId].shortNames
    }

    return getFilteredShortNames({ allShortNames, searchTerm })
  }, [selectedGroupId, allShortNames, searchTerm, groups.data])

  const isWholeGroupSelected = React.useMemo(() => {
    if (typeof selectedGroupId === 'undefined') {
      return false
    }

    return selectedGroupIds.includes(selectedGroupId)
  }, [selectedGroupId, selectedGroupIds])

  const onPressReset = React.useCallback(() => {
    setSelectedShortnames([])
    setSelectedGroupIds([])
    hasSetAnyShortname.current = true
  }, [])

  const onChangeSelectedShortnames = React.useCallback(
    (shortname) => {
      const isSelected = selectedShortnames.includes(shortname)

      const missingShortnamesFromSelectedGroup =
        groups.data[selectedGroupId]?.shortNames.filter(
          ({ shortName: sn }) => !selectedShortnames.includes(sn)
        ) ?? []

      const willHaveWholeGroupSelected =
        missingShortnamesFromSelectedGroup.length === 1 &&
        missingShortnamesFromSelectedGroup[0].shortName === shortname

      if (isWholeGroupSelected) {
        /**
         * The user unselected one shortname while the whole group was selected. Since we store the group separately
         * we now need to add all shortnames from the group to the selectedShortnames except the one that was unselected.
         */
        setSelectedShortnames((sn) => {
          const allShortnames = groups.data[selectedGroupId].shortNames

          const shortnamesToAdd = allShortnames.filter(
            ({ shortName: sn }) => shortname !== sn
          )

          return [...sn, ...shortnamesToAdd.map(({ id }) => id)]
        })
        setSelectedGroupIds((ids) => ids.filter((id) => id !== selectedGroupId))
      } else if (willHaveWholeGroupSelected) {
        // remove all shortnames that are in the selected group
        setSelectedShortnames((sn) =>
          sn.filter(
            (i) =>
              !groups.data[selectedGroupId].shortNames.some(
                ({ id }) => id === i
              )
          )
        )
        setSelectedGroupIds((ids) => [...ids, selectedGroupId])
      } else if (isSelected) {
        setSelectedShortnames((sn) => sn.filter((i) => i !== shortname))
      } else if (
        maxShortNamesCount &&
        selectedShortnames.length >= maxShortNamesCount
      ) {
        const dropItemsCount =
          selectedShortnames.length - maxShortNamesCount || 1

        const shortenedParams = drop(dropItemsCount, selectedShortnames)

        setSelectedShortnames(shortenedParams)
      } else {
        setSelectedShortnames((sn) => [...sn, shortname])
      }

      hasSetAnyShortname.current = true
    },
    [groups.data, isWholeGroupSelected, selectedGroupId, selectedShortnames]
  )

  const onChangeSearchTerm = React.useCallback((value) => {
    setSearchTerm(value)
  }, [])

  const onPressGroupCreator = (id) => () => {
    if (selectedGroupId === id) {
      setSelectedGroupId(undefined)
    } else {
      setSelectedGroupId(id)
    }

    setSearchTerm('')
  }

  const onPressMainGroupCreator = (id) => () => {
    if (selectedMainGroupId === id) {
      setSelectedMainGroupId(undefined)
    } else {
      setSelectedMainGroupId(id)
    }

    setSearchTerm('')
  }

  const onChangeSelectAll = React.useCallback(() => {
    setSelectedShortnames((sn) =>
      sn.filter(
        (i) =>
          !groups.data[selectedGroupId].shortNames.some(({ id }) => id === i)
      )
    )
    setSelectedGroupIds((groups) => {
      if (groups.includes(selectedGroupId)) {
        return groups.filter((id) => id !== selectedGroupId)
      }

      return [...groups, selectedGroupId]
    })

    hasSetAnyShortname.current = true
  }, [groups.data, selectedGroupId])

  React.useEffect(() => {
    // We need to check if any shortname was changed to not reset on initial render when query params are set
    if (hasSetAnyShortname.current) {
      setQueryParams({
        shortNames: selectedShortnames,
        groups: selectedGroupIds,
      })
    }
  }, [selectedShortnames, selectedGroupIds, setQueryParams])

  React.useEffect(() => {
    if (typeof queryParams.shortNames !== 'undefined') {
      setSelectedShortnames(queryParams.shortNames || [])
    }

    if (typeof queryParams.groups !== 'undefined') {
      setSelectedGroupIds(
        (queryParams.groups || []).map((group) => Number(group))
      )
    }

    dispatch(refreshChShortnames())
  }, [])

  return (
    <Spin spinning={isDisabled || isLoading}>
      <Box flexDirection="row" alignItems="center">
        <Text
          type="small"
          color={getThemedColor(colors.grey[6], colors.black[3])}
          style={{ height: 20, flex: 1 }}>
          {'Selected'}
          {(selectedShortnames.length > 0 || selectedGroupIds.length > 0) && (
            <>
              {' · '}
              <Text type="small" color={colors.blue[2]} onPress={onPressReset}>
                Reset
              </Text>
            </>
          )}
        </Text>
        <Box flex={3.5}>
          <SearchInput onChangeText={onChangeSearchTerm} />
        </Box>
      </Box>
      <View
        style={{
          flex: 1,
          flexDirection: 'row',
          borderWidth: 1,
          borderLeftWidth: 0,
          borderStyle: 'solid',
          borderColor: getThemedColor(colors.grey[4], colors.black[4]),
        }}>
        <Column>
          <SelectedShortNames
            groups={groups}
            setSelectedGroupIds={setSelectedGroupIds}
            setSelectedShortnames={setSelectedShortnames}
            selectedGroupIds={selectedGroupIds}
            selectedShortnames={selectedShortnames}
          />
        </Column>
        <Column>
          <GroupsList
            groupIds={mainGroupIds}
            groupData={mainGroups.data}
            onPressGroupCreator={onPressMainGroupCreator}
            selectedGroupId={selectedMainGroupId}
          />
        </Column>
        <Column>
          <GroupsList
            groupIds={groupIds}
            groupData={groups.data}
            onPressGroupCreator={onPressGroupCreator}
            selectedGroupId={selectedGroupId}
          />
        </Column>
        <Column flex={1.5}>
          {typeof selectedGroupId !== 'undefined' && (
            <ShortnameItem
              isSelected={isWholeGroupSelected}
              item={{
                name: 'All',
                description: 'Select all shortnames in the group.',
              }}
              onChange={onChangeSelectAll}
            />
          )}
          <ShortnameList
            shortnames={shortnames}
            selectedShortnames={
              isWholeGroupSelected
                ? groups.data[selectedGroupId].shortNames.map(({ id }) => id)
                : selectedShortnames
            }
            onChange={onChangeSelectedShortnames}
          />
        </Column>
      </View>
    </Spin>
  )
}
