import { countriesToTrack } from '@tellonym/enums/lib/Stats'
import { Button, Checkbox, Collapse, Tooltip, Typography } from 'antd'
import React from 'react'
import Helmet from 'react-helmet'
import * as Redux from 'react-redux'
import { Box, ScrollView, Text, View, styleSheets } from '../../common'
import { convertArrayToOptions } from '../../common/helpers'
import * as hooks from '../../common/hooks'
import { useDebugQuery } from '../../common/queries'
import { refreshChShortnames } from '../actions'
import { dateTypes, statsTypes } from '../constants'

import { useSelectedShortnames } from '../hooks'
import { useCompareMutation } from '../queries'
import { getChShortnamesState } from '../selectors'
import { InputValidation } from './InputValidation'
import { ShortNamesSelector } from './ShortNamesSelector'
import { TimeFramePicker } from './TimeFramePicker'

const styles = {
  checkboxText: { marginLeft: 8 },
  compareSelector: { width: 130, marginLeft: 12 },
  headerContainer: {
    flexDirection: 'row',
    paddingHorizontal: 16,
    marginTop: 24,
    marginBottom: 12,
  },
  italic: { fontStyle: 'italic' },
  smallTitle: { marginTop: 24 },
}

const stringifyObject = (obj) =>
  Object.keys(obj)
    .reduce((acc, key) => {
      acc.push(`${key}: ${JSON.stringify(obj[key])}`)

      return acc
    }, [])
    .join('\n')

const createExpDataToRender = (remoteExperimentConfig) => {
  if (!remoteExperimentConfig) {
    return []
  }

  const { experimentsData, experimentsCountryMapping } = remoteExperimentConfig

  const countryKeys = Object.keys(experimentsCountryMapping)

  const { base, countriesBase, ...expData } = experimentsData

  const mappedExperiments = Object.keys(expData)
    .sort()
    .reverse()
    .reduce((acc, key) => {
      if (['base', 'countriesBase'].includes(key)) {
        return acc
      }

      const countries = countryKeys.reduce((acc, curr) => {
        if (experimentsCountryMapping[curr][key] >= 0) {
          acc.push(curr)

          return acc
        }

        return acc
      }, [])

      const countriesString = countries
        .map(
          (c) =>
            `${c.toLocaleUpperCase()} (${
              experimentsCountryMapping[c][key] * 100
            }%)`
        )
        .join(', ')
        .replace('all', 'global')
        .toLocaleUpperCase()

      const exp = experimentsData[key]

      const header = `${key} - ${exp.name} - ${
        countriesString !== '' ? countriesString : 'nowhere'
      }`

      const triggers = exp.triggers?.join?.(', ')

      const configString = stringifyObject(exp.config)

      acc.push({
        id: key,
        header,
        triggers,
        shouldApplyOnRestart: exp.shouldApplyOnRestart,
        config: configString,
      })

      return acc
    }, [])

  return mappedExperiments
    .concat([
      {
        id: 'base',
        header: 'BASE',
        config: stringifyObject(base),
      },
    ])
    .concat(
      Object.keys(countriesBase)
        .sort()
        .map((key) => ({
          id: key,
          header: key.toLocaleUpperCase(),
          config: stringifyObject(countriesBase[key]),
        }))
    )
}

const ExpConfigViewer = () => {
  const [shouldShowRaw, setShouldShowRaw] = React.useState(false)
  const { data } = useDebugQuery()

  const exps = React.useMemo(
    () => createExpDataToRender(data?.remoteExperimentConfig),
    [data?.remoteExperimentConfig]
  )

  if (typeof data?.remoteExperimentConfig === 'undefined') {
    return null
  }

  const onChangeCheckbox = (e) => {
    setShouldShowRaw(e.target.checked)
  }

  return (
    <>
      <Box flexDirection="row" marginBottom={12} alignItems="center">
        <Checkbox onChange={onChangeCheckbox} />
        <Text style={styles.checkboxText}>show raw</Text>
      </Box>
      <ScrollView style={{ height: 600 }}>
        {shouldShowRaw ? (
          <pre
            style={{
              whiteSpace: 'pre-wrap',
              wordWrap: 'break-word',
              overflowWrap: 'break-word',
              overflow: 'auto',
            }}>
            {JSON.stringify(data.remoteExperimentConfig, null, 2)}
          </pre>
        ) : (
          <Box>
            <Collapse ghost>
              {exps.map((exp) => (
                <Collapse.Panel header={exp.header} key={exp.id}>
                  <Box marginLeft={64}>
                    <Text>{`Trigger: ${exp.triggers}`}</Text>
                    {exp.shouldApplyOnRestart && (
                      <Text>Applies on restart</Text>
                    )}
                    <Typography.Title level={4} style={styles.smallTitle}>
                      Config
                    </Typography.Title>
                    <Text>{exp.config}</Text>
                  </Box>
                </Collapse.Panel>
              ))}
            </Collapse>
          </Box>
        )}
      </ScrollView>
    </>
  )
}

const useValueSelectorFactory = (payload) => {
  const [values, setValues] = React.useState({
    dateType: payload?.dateType ?? dateTypes.LAST_21_DAYS,
    country: 'DE',
  })

  const onChangeCreator = (key) => (value) => {
    setValues({ ...values, [key]: value })
  }

  const onChangeCountry = ([country]) => {
    setValues({ ...values, country })
  }

  const onChangeDates = (dates, dateType) => {
    setValues({ ...values, dates, dateType })
  }

  const setterCollection = {
    onChangeCohort: onChangeCreator('cohort'),
    onChangeCountry,
    onChangeDates,
    onChangeExperimentId: onChangeCreator('experimentId'),
    onChangeDateType: onChangeCreator('dateType'),
  }

  return { ...values, setter: setterCollection }
}

const PageChCompare = () => {
  const dispatch = Redux.useDispatch()

  const containerStyle = hooks.usePageContainerStyle()
  const control = useValueSelectorFactory()
  const experiment = useValueSelectorFactory({ dateType: dateTypes.SYNCED })
  const [shouldSummarize, setShouldSummarize] = React.useState(false)
  const [expPercentage, setExpPercentage] = React.useState(0.5)
  const [statsType, setStatsType] = React.useState()

  const { data, isLoading, isSuccess, mutate } = useCompareMutation()

  const { data: shortnamesData, isRefreshing: isRefreshingShortnames } =
    Redux.useSelector(getChShortnamesState)

  React.useEffect(() => {
    dispatch(refreshChShortnames())
  }, [])

  const selectedShortnames = useSelectedShortnames()

  const onPressRefresh = () => {
    mutate({
      control,
      experiment,
      expPercentage,
      shortNames: selectedShortnames,
      shouldSummarize,
      statsType,
    })
  }

  const onPressOpenSheet = () => {
    if (isSuccess && typeof data?.sheetUrl === 'string') {
      window.open(data.sheetUrl, '_blank')
    }
  }

  const onChangeShouldSummarize = (e) => {
    setShouldSummarize(e.target.checked)
  }

  const onChangeExpPercentage = (value) => {
    setExpPercentage(value)
  }

  const onChangeStatsType = ([value]) => {
    setStatsType(value)
  }

  return (
    <View style={containerStyle}>
      <Helmet title="Compare - Tellonym Modcp" />
      <Box padding={16}>
        <Typography.Title level={3}>Statistic Compare</Typography.Title>
        <Box flexDirection="row" gap={8}>
          <Box flex={3} gap={8}>
            <Typography.Title level={4}>Settings</Typography.Title>

            <Box flexDirection="row" alignItems="center">
              <Typography.Text strong style={{ width: 120, marginRight: 12 }}>
                Experiment IDs
              </Typography.Text>

              <Box flexDirection="row" gap={4}>
                <InputValidation
                  isOptional
                  isDisabled={isLoading}
                  onChangeText={control.setter.onChangeExperimentId}
                  validateValue={(value) => Number.isInteger(Number(value))}
                  label="Control"
                  width={80}
                />
                <InputValidation
                  isOptional
                  isDisabled={isLoading}
                  onChangeText={experiment.setter.onChangeExperimentId}
                  validateValue={(value) => Number.isInteger(Number(value))}
                  label="Exp"
                  width={80}
                />
                <InputValidation
                  isOptional
                  isDisabled={isLoading}
                  onChangeText={onChangeExpPercentage}
                  validateValue={(value) => Number.isInteger(Number(value))}
                  label="Exp PCT"
                  placeholder="0.5"
                  width={80}
                />
              </Box>
            </Box>

            <InputValidation
              defaultValue="DE"
              options={countriesToTrack.map((v) => ({ label: v, value: v }))}
              isDisabled={isLoading}
              onChangeText={control.setter.onChangeCountry}
              text="Country"
            />

            <Box flexDirection="row" alignItems="center" gap={4}>
              <InputValidation
                isOptional
                options={convertArrayToOptions(Object.keys(statsTypes))}
                isDisabled={isLoading}
                onChangeText={onChangeStatsType}
                text="Stats Type"
                onPressText={() =>
                  window.open(
                    'https://www.notion.so/tellonym/ModCP-Analytics-de38be8628df4cadb01c389043b1407e',
                    '_blank'
                  )
                }
              />

              <Tooltip title="By default the average is used, check to sum them up instead.">
                <Box flexDirection="row" alignItems="center" gap={6}>
                  <Checkbox
                    defaultValue="Default"
                    onChange={onChangeShouldSummarize}
                  />
                  <Typography.Text style={{ fontSize: 10 }}>
                    Summarize values
                  </Typography.Text>
                </Box>
              </Tooltip>
            </Box>

            <Box flexDirection="row" alignItems="center">
              <Typography.Text strong style={{ width: 120, marginRight: 12 }}>
                Cohort
              </Typography.Text>

              <Box flexDirection="row" gap={4}>
                <InputValidation
                  isOptional
                  isDisabled={isLoading}
                  onChangeText={control.setter.onChangeCohort}
                  label="Control"
                  width={80}
                />
                <InputValidation
                  isOptional
                  isDisabled={isLoading}
                  onChangeText={experiment.setter.onChangeCohort}
                  label="Exp"
                  width={80}
                />
              </Box>
            </Box>

            <Typography.Text strong>Control Time</Typography.Text>
            <TimeFramePicker
              defaults={{
                dateType: dateTypes.LAST_21_DAYS,
                startDate: undefined,
                endDate: undefined,
              }}
              isIntervalTypeVisible={false}
              {...control.setter}
            />
            <Typography.Text strong>EXP Time</Typography.Text>
            <TimeFramePicker
              defaults={{
                dateType: dateTypes.SYNCED,
                startDate: undefined,
                endDate: undefined,
              }}
              isIntervalTypeVisible={false}
              {...control.setter}
            />
          </Box>
          <Box flex={5}>
            <Typography.Title level={4}>Shortnames</Typography.Title>
            <ShortNamesSelector
              groups={shortnamesData}
              isRefreshing={isRefreshingShortnames}
            />
          </Box>
          <Box flex={3}>
            <Typography.Title level={4}>Experiment Config</Typography.Title>
            <ExpConfigViewer />
          </Box>
        </Box>

        <Box flex={1} marginTop={48} alignItems="center">
          <Box marginTop={48} flexDirection="row">
            <Button
              type="primary"
              size="large"
              shape="round"
              loading={isLoading}
              onClick={onPressRefresh}>
              Generate Sheet
            </Button>
            <Button
              disabled={!isSuccess}
              size="large"
              shape="round"
              onClick={onPressOpenSheet}
              style={styleSheets.margin.left[12]}>
              Open Sheet
            </Button>
          </Box>
        </Box>
      </Box>
    </View>
  )
}

export { PageChCompare }
