import { RingProgress } from '@ant-design/plots'
import { StatisticCard } from '@ant-design/pro-components'
import { colors } from '@tellonym/core/common/colorSystem'
import { timestampToElapsedTime } from '@tellonym/core/helpers'
import { langDetectObjectsByType1 } from '@tellonym/enums/lib/Language'
import { ARTIFICIAL_TELL_STATUS } from '@tellonym/enums/lib/Tell'
import { Spin, Tooltip, Typography } from 'antd'
import { Style } from 'radium'
import React from 'react'
import * as ReactRedux from 'react-redux'
import { _, Box, hooks, TableEndlessScroll, Text, View } from '../../common'
import { TagBox } from '../../common/components/TagBox'
import * as actionsV2 from '../actionsV2'
import { useAvailableLanguages } from '../hooks/useAvailableLanguages'
import { useValidationQuery } from '../queries'
import * as selectorsV2 from '../selectorsV2'
import { getLanguage } from '../selectorsV2'
import { SelectorsLanguage } from './SelectorsLanguage'
import { SharedColumns, useColumnSearchProps } from './SharedColumns'

const antdTableOverrides = {
  '.ant-pro-statistic-card-footer': {
    paddingBlockStart: 0,
  },
}

const CapacityStatCard = ({ lang, validationData }) => {
  const dispatch = ReactRedux.useDispatch()
  const activeLanguage = ReactRedux.useSelector(getLanguage)

  const { activeGroups, capacity, scheduledValidation } =
    validationData?.[langDetectObjectsByType1[lang]] ?? {}

  const isActiveLang = activeLanguage === lang
  const langString = lang.toUpperCase()
  const validationSum =
    (activeGroups?.ids?.length ?? 0) + (scheduledValidation?.ids?.length ?? 0)

  const onPress = (e) => {
    const isActionKeyPressed = e.metaKey || e.ctrlKey

    if (isActionKeyPressed) {
      window.open(`/artificialtells_v2/${lang}/validation`, '_blank')
    } else {
      dispatch(actionsV2.setLanguage(lang))
    }
  }

  /**
   * We reuse timestampToElapsedTime function which is used for calulating to the past.
   * Therefore we use subtraction here to create a date in the past even though it will
   * actually be in the future.
   */
  const remainingDelay = scheduledValidation?.remainingDelayInMS
    ? timestampToElapsedTime(
        new Date(Date.now() - scheduledValidation?.remainingDelayInMS)
      )
    : undefined

  if (typeof capacity === 'undefined' || capacity === 0) {
    return null
  }

  return (
    <StatisticCard
      onClick={onPress}
      onAuxClick={() => {
        window.open(`/artificialtells_v2/${lang}/validation`, '_blank')
      }}
      statistic={{
        title: langString,
        value: `${validationSum} / ${capacity ?? 0}`,
      }}
      chart={
        <Box transparent width={60} height={60}>
          <RingProgress
            autoFit
            innerRadius={0.7}
            percent={
              validationSum && capacity
                ? Number((validationSum / (capacity ?? 0)).toFixed(2))
                : 0
            }
            color={[
              colors.blue[3],
              isActiveLang ? colors.grey[5] : colors.grey[3],
            ]}
            statistic={false}
          />
        </Box>
      }
      chartPlacement="left"
      footer={remainingDelay ? `next batch in ${remainingDelay}` : undefined}
      style={{
        backgroundColor: isActiveLang ? colors.blue[14] : undefined,
        marginRight: 6,
        cursor: 'pointer',
        width: 220,
      }}
    />
  )
}

/**
 * This is manually measured (with chrome debug element inspection) and necessary to prevent the page from
 * being scrollable. We don't want that because the table is already scrollable.
 */
const HEADER_HEIGHT = 80

const Header = ({ validationData }) => {
  const language = ReactRedux.useSelector(selectorsV2.getLanguage)
  const availableLanguages = useAvailableLanguages()

  // We abuse our languageStatusList in the lang selector to indicate which langs have active validation groups
  const languageStatusList = validationData
    ? Object.keys(validationData).map((lang) => ({
        language: lang,
        status:
          validationData[lang]?.activeGroups?.ids?.length > 0 ||
          validationData[lang]?.scheduledValidation?.ids?.length > 0
            ? ARTIFICIAL_TELL_STATUS.VALIDATION
            : undefined,
      }))
    : []

  return (
    <Box padding={24}>
      <Box width="100%" flexDirection="row">
        <Box flex={1} marginRight={8}>
          <Typography.Title level={3}>Groups Validation</Typography.Title>
          <Text type="small">
            The page shows all groups actively in validation (Val.) as well as
            groups waiting to be validated. Groups are in waiting state since we
            send them out in batches every 30 minutes but only between 12pm and
            9pm. For details checkout{' '}
            <a
              href="https://miro.com/app/board/uXjVMx5a11s=/?moveToWidget=3458764609623344872&cot=14"
              target="_blank"
              rel="noreferrer">
              Miro
            </a>
            .
          </Text>
        </Box>
        <Box flex={0.5} />
        <Box alignItems="flex-end">
          <SelectorsLanguage
            languageStatusList={languageStatusList}
            value={language}
          />
        </Box>
      </Box>

      <Box marginTop={24}>
        <Typography.Title level={5}>Capacity</Typography.Title>
        <Text type="small">
          We can only run a certain amount of validations at the same time. This
          section shows how many groups of the total capacity are in validation.
          This way you can see where new validations can be started.
        </Text>
        <Box
          flexDirection="row"
          marginTop={24}
          marginBottom={12}
          flexWrap="wrap">
          {availableLanguages.map((lang) => (
            <CapacityStatCard
              key={lang}
              lang={lang}
              validationData={validationData}
            />
          ))}
        </Box>
      </Box>
    </Box>
  )
}

const ArtificialTellValidationGroupsTable = (props) => {
  const searchProps = useColumnSearchProps('name')

  const columns = React.useMemo(
    () => [
      {
        ...SharedColumns.id,
        sorter: { multiple: 1, compare: SharedColumns.id.sorter },
      },
      SharedColumns.groupType,
      {
        title: 'Question',
        dataIndex: 'id',
        key: 'name',
        width: '80%',
        render: (id, group) => <View>{group.name}</View>,
        ...searchProps,
        sorter: (a, b) => {
          const textA = a.name?.toLowerCase()
          const textB = b.name?.toLowerCase()

          if (textA < textB) {
            return -1
          }

          if (textA > textB) {
            return 1
          }

          return 0
        },
      },
      {
        ...SharedColumns.groupTotalSent,
        render: (id, group) => {
          if (group.total_sent === 0) {
            return (
              <Tooltip title="Waiting for next batch to be send out">
                <TagBox backgroundColor={colors.yellow[1]} text="Waiting" />
              </Tooltip>
            )
          }

          return SharedColumns.groupTotalSent.render(id, group)
        },
      },
      SharedColumns.lastUpdatedAt,
    ],
    [props.columns]
  )

  const onRow = React.useCallback(
    (group) => {
      const routeProps = _.openRouteProps(
        `/artificialtells_v2/${props.language ?? 'en'}/group/${group.id}`
      )

      return { onClick: routeProps.onPress, onAuxClick: routeProps.onAuxClick }
    },
    [props.language]
  )

  return (
    <TableEndlessScroll
      {...props}
      hasZebraRows
      hasSmallRows
      columns={columns}
      onRow={onRow}
    />
  )
}

export const PageArtificialTellsValidation = () => {
  const language = ReactRedux.useSelector(getLanguage)

  const table = hooks.useTableState()

  const { data: validationData, isLoading } = useValidationQuery()

  const currentLanguageData =
    validationData?.[langDetectObjectsByType1[language]]

  const dataSource = [
    ...(currentLanguageData?.activeGroups?.data ?? []),
    ...(currentLanguageData?.scheduledValidation?.data ?? []),
  ]

  return (
    <Box flex={1} flexGrow={1}>
      <Style rules={antdTableOverrides} />
      <Header validationData={validationData} language={language} />

      <Spin spinning={isLoading}>
        <Box flex={1} flexGrow={1} alignItems="center">
          <Box maxWidth={2100}>
            <ArtificialTellValidationGroupsTable
              {...table}
              dataSource={dataSource}
              language={language}
              offset={HEADER_HEIGHT}
            />
          </Box>
        </Box>
      </Spin>
    </Box>
  )
}
