import React from 'react'
import RNText from 'react-native-web/dist/exports/Text'
import { Box } from './Box'

export const TagBox = ({
  backgroundColor,
  color = 'white',
  text,
  isLabel,
  note,
  width,
}) => {
  return (
    <Box transparent alignItems={isLabel ? undefined : 'center'}>
      <Box
        alignItems="center"
        backgroundColor={backgroundColor}
        borderRadius={2}
        height={note ? 18 : 22}
        justifyContent="center"
        paddingHorizontal={4}
        width={width}>
        <RNText
          numberOfLines={1}
          style={{
            color,
            fontSize: note ? 10 : 12,
          }}>
          {text}
        </RNText>
      </Box>
    </Box>
  )
}
