import { DownOutlined, UndoOutlined, UpOutlined } from '@ant-design/icons'
import { Pie } from '@ant-design/plots'
import { ProCard, StatisticCard } from '@ant-design/pro-components'
import { colors } from '@tellonym/core/common/colorSystem'
import {
  ARTIFICIAL_TELL_STATUS,
  ARTIFICIAL_TELL_USER_GROUP_TYPE,
} from '@tellonym/enums/lib/Tell'
import {
  BackTop,
  Breadcrumb,
  Col,
  Drawer,
  Grid,
  Row,
  Tag,
  Tooltip,
  Typography,
} from 'antd'
import React from 'react'
import * as ReactRedux from 'react-redux'
import {
  _,
  Box,
  BoxSecondary,
  history,
  hooks,
  ScrollView,
  styleSheets,
  TableEndlessScroll,
  Text,
  theme,
} from '../../common'
import { TagBox } from '../../common/components/TagBox'
import { valueToPercentage } from '../../statistics/services'
import { useProfileSharingDashboardQuery, useTopicsQuery } from '../queries'
import { getLanguage } from '../selectorsV2'
import { ArtificialTellStatusTag } from './ArtificialTellStatusTag'
import { ButtonBack } from './ButtonBack'
import { getStatsCardProps } from './PageArtificialTellsDashboard'
import { SelectorsLanguage } from './SelectorsLanguage'
import { SharedColumns, statusOrder } from './SharedColumns'

const MAX_CHART_ITEMS = 10

const PieChart = ({ data, onPressTagOther, isTagOtherSelected }) => {
  // To work, this expects the data to be ordered by amount descending
  const shortenedData = React.useMemo(() => {
    if (!data?.length) {
      return []
    }

    return data.reduce((acc, { tag, totalCount }, index) => {
      // When "Other" is selected, only show items after MAX_CHART_ITEMS
      if (isTagOtherSelected) {
        if (index >= MAX_CHART_ITEMS) {
          acc.push({ tag, totalCount })
        }

        return acc
      }

      // Show first MAX_CHART_ITEMS items normally
      if (index < MAX_CHART_ITEMS) {
        acc.push({ tag, totalCount })

        return acc
      }

      // Group remaining items into "Other"
      const otherIndex = MAX_CHART_ITEMS

      if (!acc[otherIndex]) {
        acc[otherIndex] = { tag: 'Other', totalCount }
      } else {
        acc[otherIndex].totalCount += totalCount
      }

      return acc
    }, [])
  }, [data, isTagOtherSelected])

  const config = {
    appendPadding: 10,
    data: shortenedData,
    angleField: 'totalCount',
    colorField: 'tag',
    radius: 1,
    innerRadius: 0.6,
    label: {
      type: 'inner',
      offset: '-50%',
      formatter: ({ tag, totalCount }) => {
        if (tag === 'Other') {
          return `${tag}\n${totalCount}`
        }

        return totalCount
      },
      style: {
        textAlign: 'center',
        fontSize: 12,
      },
    },
    statistic: { title: false },
    legend: {
      position: 'right',
      offsetX: -20,
      itemName: {
        formatter: (text) => {
          const item = shortenedData.find((t) => t.tag === text)

          return `${text}: ${item.totalCount}`
        },
        style: { fontSize: 10 },
      },
    },
    onEvent: (pie, event) => {
      const isLegendItemOtherClick =
        event.type === 'legend-item:click' &&
        event.view.filteredData.length === 1 &&
        event.view.filteredData[0].tag === 'Other'

      const isChartItemOtherClick =
        event.type === 'element:click' && event.data.data.tag === 'Other'

      if (isLegendItemOtherClick || isChartItemOtherClick) {
        onPressTagOther()
      }
    },
  }

  return <Pie {...config} />
}

const ConnectedTopicsTable = ({ categories, language }) => {
  const table = hooks.useTableState()

  const { data, isLoading } = useTopicsQuery({
    language,
    dataMode: ARTIFICIAL_TELL_USER_GROUP_TYPE.HIGH,
  })

  const datasource = React.useMemo(() => {
    if (categories === null || typeof data === 'undefined') {
      return []
    }

    return data?.topics.filter((topic) =>
      categories.includes(topic.topicCategory)
    )
  }, [data, categories])

  const columns = [
    SharedColumns.id,
    {
      title: 'Topic',
      dataIndex: 'name',
      render: (name) => {
        return <Text type="small">{name}</Text>
      },
    },
    {
      title: 'Category',
      dataIndex: 'topicCategory',
      width: 240,
      render: (category, item) => {
        return (
          <TagBox
            isLabel
            backgroundColor={
              item.isCategoryExclusive ? colors.blue[15] : 'transparent'
            }
            color={item.isCategoryExclusive ? 'white' : 'black'}
            text={category}
          />
        )
      },
    },
  ]

  const onRow = React.useCallback(
    (item) => {
      return _.openRoutePropsAnt(
        `/artificialtells_v2/${language}/topic/${item.id}`
      )
    },
    [language]
  )

  return (
    <TableEndlessScroll
      {...table}
      hasSmallRows
      hasZebraRows
      rowKey="id"
      loading={isLoading}
      dataSource={datasource}
      columns={columns}
      onRow={onRow}
    />
  )
}

const StickyHeader = () => {
  const screens = Grid.useBreakpoint()

  return (
    <BoxSecondary
      paddingBottom={8}
      paddingHorizontal={24}
      paddingVertical={8}
      position="sticky"
      top={0}
      zIndex={1}
      transparent>
      <Row gutter={[12, 12]}>
        <Col xs={24} md={8}>
          <Box transparent flexDirection="row" alignItems="center">
            <ButtonBack
              onPress={() => {
                history.goBack()
              }}
              style={{ marginRight: 12, width: 30, height: 30 }}
            />

            <Breadcrumb>
              <Breadcrumb.Item key="Profile Share">
                Profile Share
              </Breadcrumb.Item>
            </Breadcrumb>
          </Box>
        </Col>

        <Col xs={24} md={16}>
          <Box transparent alignItems={screens.md ? 'flex-end' : 'center'}>
            <SelectorsLanguage />
          </Box>
        </Col>
      </Row>
    </BoxSecondary>
  )
}

const NoContentExtra = ({
  isNoContentExtended,
  isTagOtherSelected,
  resetTagOther,
  onPressNoContentExtended,
}) => {
  const getThemedColor = hooks.useThemedColor()

  return isNoContentExtended ? (
    <>
      {isTagOtherSelected && (
        <>
          <Tag color="gold" onClick={resetTagOther} style={styleSheets.pointer}>
            Data is filtered
          </Tag>
          <UndoOutlined onClick={resetTagOther} style={{ marginRight: 12 }} />
        </>
      )}
      <UpOutlined
        onClick={onPressNoContentExtended}
        style={{ color: getThemedColor(undefined, colors.white[1]) }}
      />
    </>
  ) : (
    <DownOutlined
      onClick={onPressNoContentExtended}
      style={{ color: getThemedColor(undefined, colors.white[1]) }}
    />
  )
}

const Header = ({ data, isLoading }) => {
  const [isNoContentExtended, setIsNoContentExtended] = React.useState(false)
  const [isTagOtherSelected, setIsTagOtherSelected] = React.useState(false)

  const onPressNoContentExtended = () => {
    setIsNoContentExtended(!isNoContentExtended)
  }

  const resetTagOther = () => {
    setIsTagOtherSelected(false)
  }

  return (
    <BoxSecondary paddingHorizontal={24} paddingTop={8} paddingBottom={8}>
      <ProCard ghost wrap gutter={[16, 16]}>
        <ProCard
          title="Screen visits"
          loading={isLoading}
          bordered
          headerBordered
          colSpan={{ xs: 24, md: 8, xl: 4 }}
          bodyStyle={{ padding: 0 }}>
          <StatisticCard
            {...getStatsCardProps({
              config: data?.config,
              data: data?.data['CH-ShareCards-View-WAUs'],
            })}
          />
        </ProCard>
        <ProCard
          title="Shares"
          colSpan={{ xs: 24, md: 16, xl: 8 }}
          loading={isLoading}
          bordered
          headerBordered
          wrap
          bodyStyle={{ padding: 0 }}>
          <StatisticCard
            colSpan={{ xs: 24, sm: 12 }}
            title="DAUs: % users"
            {...getStatsCardProps({
              config: data?.config,
              data: data?.data['CH-ShareCards-Share-Open'],
            })}
          />
          <StatisticCard
            colSpan={{ xs: 24, sm: 12 }}
            title="DAUs: Actions per unique"
            {...getStatsCardProps({
              config: data?.config,
              data: data?.data['CH-ShareCards-Share-Open-ActionsPerUnique'],
              precision: 2,
            })}
          />
          <StatisticCard
            colSpan={{ xs: 24, sm: 12 }}
            title="WAUs: % users"
            {...getStatsCardProps({
              config: data?.config,
              data: data?.data['CH-ShareCards-Share-Open-Weekly'],
            })}
          />
          <StatisticCard
            colSpan={{ xs: 24, sm: 12 }}
            title="WAUs: Actions per unique"
            {...getStatsCardProps({
              config: data?.config,
              data: data?.data[
                'CH-ShareCards-Share-Open-Weekly-ActionsPerUnique'
              ],
              precision: 2,
            })}
          />
        </ProCard>
        <ProCard
          title="Interactions"
          colSpan={{ xs: 24, lg: 12, xl: isNoContentExtended ? 4 : 8 }}
          loading={isLoading}
          bordered
          headerBordered
          wrap
          bodyStyle={{ padding: 0 }}>
          <StatisticCard
            colSpan={{ xs: 24, md: 12, lg: isNoContentExtended ? 24 : 12 }}
            title="% users receiving organic tell"
            {...getStatsCardProps({
              config: data?.config,
              data: data?.data['CH-ShareCards-ShareToOrganicTell'],
            })}
          />
          <StatisticCard
            colSpan={{ xs: 24, md: 12, lg: isNoContentExtended ? 24 : 12 }}
            title="Art. Tell Answer rate"
            {...getStatsCardProps({
              config: data?.config,
              data: data?.data['CH-ShareCards-ArTell-AnswerRate'],
            })}
          />
        </ProCard>
        <ProCard
          title="No Content"
          colSpan={{ xs: 24, lg: 12, xl: isNoContentExtended ? 8 : 4 }}
          loading={isLoading}
          bordered
          headerBordered
          bodyStyle={{ padding: 0 }}
          extra={
            <NoContentExtra
              isNoContentExtended={isNoContentExtended}
              isTagOtherSelected={isTagOtherSelected}
              resetTagOther={resetTagOther}
              onPressNoContentExtended={onPressNoContentExtended}
            />
          }>
          {isNoContentExtended ? (
            <Box width="100%" height={280}>
              <PieChart
                data={data?.noContentData}
                isTagOtherSelected={isTagOtherSelected}
                onPressTagOther={() => {
                  setIsTagOtherSelected(true)
                }}
              />
            </Box>
          ) : (
            <StatisticCard
              statistic={{
                value: data?.noContentData?.reduce(
                  (acc, curr) => acc + curr.totalCount,
                  0
                ),
                description: `last ${data?.config?.timeframeInDays ?? '-'}d`,
                style: { alignItems: 'center' },
              }}
            />
          )}
        </ProCard>
      </ProCard>

      <Typography.Text type="secondary" style={{ textAlign: 'right' }}>
        All data points represent the last 28d
      </Typography.Text>
    </BoxSecondary>
  )
}

const statusKeys = ['amountTotalShares']

const getComputedStatus = (record) => {
  return statusKeys.some((key) => record[key] > 0)
    ? ARTIFICIAL_TELL_STATUS.ACTIVE
    : ARTIFICIAL_TELL_STATUS.INACTIVE
}

export const PageProfileSharing = () => {
  const tableContainerRef = React.useRef(null)
  const [selectedCategories, setSelectedCategories] = React.useState(null)

  const screens = Grid.useBreakpoint()

  const getThemedColor = hooks.useThemedColor()

  const language = ReactRedux.useSelector(getLanguage)

  const { data, isLoading } = useProfileSharingDashboardQuery({
    language,
  })

  const datasource = data?.contentData

  const columns = React.useMemo(
    () => [
      SharedColumns.id,
      {
        title: 'Status',
        id: 'id',
        width: 90,
        defaultSortOrder: 'ascend',
        sorter: (a, b) => {
          const statusA = getComputedStatus(a)
          const statusB = getComputedStatus(b)

          const textA = statusOrder[statusA]
          const textB = statusOrder[statusB]

          if (textA < textB) {
            return -1
          }

          if (textA > textB) {
            return 1
          }

          return 0
        },
        render: (id, record) => {
          return <ArtificialTellStatusTag status={getComputedStatus(record)} />
        },
      },
      {
        title: 'Share card',
        dataIndex: 'contentEnglish',
        width: screens.xl ? '40%' : 230,
        render: (content, record) => (
          <Tooltip title={record.content} placement="topLeft">
            <span>{record.contentEnglish}</span>
          </Tooltip>
        ),
      },
      {
        title: 'Sub Page',
        dataIndex: 'subPage',
        width: 120,
      },
      {
        title: 'Categories',
        dataIndex: 'categoriesWithWeights',
        width: screens.xl ? '25%' : 180,
        render: (categoriesWithWeights) => {
          return (
            <Box transparent flexDirection="row" flexWrap="wrap">
              {categoriesWithWeights?.map((item) => (
                <TagBox
                  key={item.category}
                  isLabel
                  backgroundColor={'transparent'}
                  color={getThemedColor(colors.black[1], colors.white[1])}
                  text={`${item.category} (${item.weight})`}
                />
              ))}
            </Box>
          )
        },
      },
      {
        title: (
          <Tooltip title="This is not an actual amount but a calculated number that represents how often we can send a Tell in response to this share card that has at least one group from all desired categories. Calculation: It uses the amount of groups from the group with the least groups by weight (total amount groups / category weight). Then we divide the category weight by the total weight and then divide the total amount of groups by that result (total amount groups / (category weight / total weight)).">
            <span>Avail. Follow ups</span>
          </Tooltip>
        ),
        dataIndex: 'amountAvailableFollowups',
        width: 100,
      },
      {
        title: 'Total Shares',
        dataIndex: 'amountTotalShares',
        width: 80,
        sorter: (a, b) => a.amountTotalShares - b.amountTotalShares,
      },
      {
        title: '% users receiving organic tell',
        dataIndex: 'percentageUsersReceivingOrganicTells',
        width: 120,
        onHeaderCell: () => ({
          style: {
            fontSize: 12,
          },
        }),
        render: (value) => valueToPercentage(value),
      },
      {
        title: 'Total AR Tells sent',
        dataIndex: 'amountTotalArTellsSent',
        width: 120,
      },
      {
        title: 'Answer rate',
        dataIndex: 'percentageArTellAnswerRate',
        width: 80,
        render: (value) => valueToPercentage(value),
      },
    ],
    [getThemedColor, screens.xl]
  )

  const onRow = React.useCallback((item) => {
    const openDrawer = () => {
      setSelectedCategories(
        item.categoriesWithWeights.map((item) => item.category)
      )
    }

    return { onClick: openDrawer, onAuxClick: openDrawer }
  }, [])

  const onClose = () => {
    setSelectedCategories(null)
  }

  return (
    <Box>
      <Drawer
        title="Connected Groups"
        placement="right"
        onClose={onClose}
        open={Array.isArray(selectedCategories)}
        size="large"
        bodyStyle={{ padding: 0 }}>
        <ConnectedTopicsTable
          categories={selectedCategories}
          language={language}
        />
      </Drawer>
      <ScrollView
        ref={tableContainerRef}
        contentContainerStyle={{
          paddingTop: 8,
          backgroundColor: getThemedColor(
            theme.colors.antdBackgroundElevated,
            colors.black[5]
          ),
        }}>
        <StickyHeader />

        <Header data={data} isLoading={isLoading} />

        <TableEndlessScroll
          size="small"
          hasZebraRows
          isEndless
          sticky={{ offsetHeader: screens.md ? 48 : screens.sm ? 90 : 122 }} // Height of StickyHeader, manually measured
          rowKey="id"
          loading={isLoading}
          scroll={{ y: false, x: screens.xl ? false : 0 }}
          dataSource={datasource}
          language={language}
          columns={columns}
          onRow={onRow}
        />
      </ScrollView>
      <BackTop target={() => tableContainerRef.current} />
    </Box>
  )
}
