import { capitalize } from '@tellonym/core/helpers'
import {
  DETECTABLE_LANGUAGE,
  langDetectObjectsByType1,
} from '@tellonym/enums/lib/Language'
import { Radio } from 'antd'
import { Style } from 'radium'
import React from 'react'
import * as ReactRedux from 'react-redux'
import { colors } from '../../common'
import { languageEnumAsString } from '../../common/helpers'
import * as actionsV2 from '../actionsV2'
import { supportedLanguages } from '../constants'
import { useAvailableLanguages } from '../hooks/useAvailableLanguages'
import * as selectorsV2 from '../selectorsV2'

export const SelectorsLanguage = ({
  languageStatusList,
  languages = supportedLanguages,
  size = 'default',
  value, // optional, if not provided, the default language is selected
}) => {
  const dispatch = ReactRedux.useDispatch()
  const availableLanguages = useAvailableLanguages({ languages })
  const language = ReactRedux.useSelector(selectorsV2.getLanguage)

  const hasGroupLanguages = languageStatusList?.length > 0

  const currentIndex = languages.findIndex((lang) => lang === language)

  const onChange = React.useCallback(
    (e) => {
      dispatch(actionsV2.setLanguage(e.target.value))
    },
    [dispatch]
  )

  const languageStatusMap = React.useMemo(
    () =>
      languageStatusList?.reduce?.(
        (acc, { language, status }) => ({
          ...acc,
          [languageEnumAsString[language]]: status,
        }),
        {}
      ),
    [languageStatusList]
  )

  return (
    <Radio.Group
      {...(value ? { value } : {})}
      onChange={onChange}
      defaultValue={supportedLanguages[currentIndex]}
      buttonStyle="solid"
      size={size}>
      {availableLanguages.map((lang) => {
        const isActive = language === lang

        const text =
          availableLanguages.length > 5
            ? lang.toUpperCase()
            : capitalize(
                DETECTABLE_LANGUAGE[
                  langDetectObjectsByType1[lang]
                ]?.toLowerCase()
              )

        const color = colors.artTellStatus[languageStatusMap?.[lang]]

        const style = hasGroupLanguages
          ? {
              ...(isActive && {
                backgroundColor: color,
                borderColor: color,
              }),
              borderBottomStyle: 'solid',
              borderBottomWidth: 3,
              borderBottomColor: color,
            }
          : undefined

        return (
          <React.Fragment key={`${lang}-${isActive}`}>
            {/* This sets the color for the pseudo element from antd as well and avoids that it looks like a corder color has the default blue color */}
            {isActive && (
              <Style
                rules={{
                  '.ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled)::before':
                    {
                      backgroundColor: color,
                    },
                }}
              />
            )}
            <Radio.Button value={lang} style={style}>
              <span>{text}</span>
            </Radio.Button>
          </React.Fragment>
        )
      })}
    </Radio.Group>
  )
}
