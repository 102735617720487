import { ProCard } from '@ant-design/pro-components'
import { colors } from '@tellonym/core/common/colorSystem'
import { DatePicker, Grid, Spin, Tag } from 'antd'
import dayjs from 'dayjs'
import React from 'react'
import {
  Avatar,
  Box,
  hooks,
  ScrollView,
  styleSheets,
  TableEndlessScroll,
  Text,
} from '../../common'
import { languageEnumAsString } from '../../common/helpers'
import { useProfilesMutation } from '../../profile/queries'
import { useWorkProgressQuery } from '../queries'
import { getLangFlag, Header } from './PageArtificialTellsDashboard'

const User = ({ user, isLoading }) => {
  return (
    <Spin spinning={isLoading} size="small">
      <Box transparent flexDirection="row" alignItems="center">
        <Avatar user={user} size={16} />
        <Text type="note" style={{ marginLeft: 8 }}>
          {user?.displayName}
        </Text>
      </Box>
    </Spin>
  )
}

const Issuer = ({ children }) => {
  return <Text type="small">{children}</Text>
}

const quickSelectionRanges = {
  'This Week': [dayjs().startOf('isoWeek'), dayjs().endOf('isoWeek')],
  'Last Week': [
    dayjs().startOf('isoWeek').subtract(1, 'week'),
    dayjs().endOf('isoWeek').subtract(1, 'week'),
  ],
  'This Month': [dayjs().startOf('month'), dayjs().endOf('month')],
  'Last Month': [
    dayjs().startOf('month').subtract(1, 'month'),
    dayjs().endOf('month').subtract(1, 'month'),
  ],
}

const SectionWorkData = () => {
  const [dates, setDates] = React.useState(quickSelectionRanges['This Week'])

  const { data, isLoading } = useWorkProgressQuery({
    startDate: dayjs(dates[0]).format('YYYY-MM-DD'),
    endDate: dayjs(dates[1]).format('YYYY-MM-DD'),
  })

  const {
    data: profiles,
    mutate: profilesMutation,
    isLoading: isProfilesLoading,
  } = useProfilesMutation()

  const dataset = React.useMemo(() => {
    if (!data) return []

    const dataResult = []

    dataResult.push({ issuer: 'All', ...data?.all })
    dataResult.push({ issuer: 'Team', ...data?.team })

    Object.keys(data.languages).forEach((language) => {
      const lang = languageEnumAsString[language]

      dataResult.push({
        issuer: `${getLangFlag(lang).flag} ${lang.toUpperCase()}`,
        ...data.languages[language],
      })
    })

    Object.keys(data.users).forEach((userId) => {
      dataResult.push({
        issuer: Number(userId),
        isLoading: isProfilesLoading,
        profile: profiles?.users?.find((user) => user.id === Number(userId)),
        ...data.users[userId],
      })
    })

    return dataResult
  }, [data, isProfilesLoading, profiles])

  const columns = React.useMemo(
    () =>
      [
        {
          title: '',
          dataIndex: 'issuer',
          width: 160,
          render: (text, record) =>
            typeof text === 'number' ? (
              <User user={record.profile} isLoading={record.isLoading} />
            ) : (
              <Issuer>{text}</Issuer>
            ),
        },
        { title: 'Group Activations', dataIndex: 'groupActivation' },
        { title: 'Variance Activations', dataIndex: 'varianceActivation' },
        { title: 'Variance Created', dataIndex: 'varianceCreate' },
        { title: 'Changes', dataIndex: 'changes' },
        { title: 'Validations', dataIndex: 'validations' },
        { title: '' },
      ].map((column) => ({
        ...column,
        onHeaderCell: () => ({
          style: {
            fontSize: 12,
            paddingTop: 8,
            paddingBottom: 8,
          },
        }),
      })),
    []
  )

  const onPressQuickTimeCreator = (range) => () => {
    setDates(quickSelectionRanges[range])
  }

  React.useEffect(() => {
    if (data && Object.keys(data?.users).length > 0) {
      profilesMutation({ userIds: Object.keys(data.users) })
    }
  }, [data, profilesMutation])

  return (
    <ProCard
      bordered
      headerBordered
      loading={isLoading}
      colSpan={{ xs: 24 }}
      style={{ marginTop: 12 }}
      title="Work Progress"
      bodyStyle={{ padding: 0 }}
      extra={
        <Box transparent flexDirection="row" alignItems="center">
          {Object.keys(quickSelectionRanges).map((range) => (
            <Tag
              color="blue"
              onClick={onPressQuickTimeCreator(range)}
              style={styleSheets.pointer}>
              {range}
            </Tag>
          ))}
          <DatePicker.RangePicker
            value={dates}
            onChange={(newDates) => {
              setDates(newDates)
            }}
          />
        </Box>
      }>
      <TableEndlessScroll
        isEndless
        hasZebraRows
        hasSmallRows
        rowKey="issuer"
        dataSource={dataset}
        columns={columns}
      />
    </ProCard>
  )
}

export const PageArtificialTellsWorkProgress = () => {
  const screens = Grid.useBreakpoint()
  const containerStyle = hooks.usePageContainerStyle()

  const getThemedColor = hooks.useThemedColor()

  return (
    <ScrollView
      style={containerStyle}
      contentContainerStyle={{
        flexGrow: 1,
        backgroundColor: getThemedColor(colors.grey[3], colors.black[5]),
        paddingBottom: 240,
      }}>
      <Box
        paddingTop={32}
        paddingHorizontal={32}
        paddingBottom={16}
        flexDirection={screens.xxl ? 'row' : 'column'}
        borderBottomWidth={1}
        borderBottomStyle="solid"
        borderColor={getThemedColor(colors.grey[4], colors.black[5])}
        marginBottom={24}>
        <Header title="Work Progress" />
      </Box>

      <Box transparent paddingHorizontal={screens.md ? 32 : 4} maxWidth={2150}>
        <SectionWorkData />
      </Box>
    </ScrollView>
  )
}
